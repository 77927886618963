<template>
  <div class="main">
    <yiyu-navbar tab-title="估苗"> </yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <div class="header">
        <div class="title">估苗数据记录</div>
        <div class="main-c" @click="toPage('add')">+新增估苗</div>
      </div>
      <div class="data-wrap">
        <div class="data-item" v-for="(item, index) in list" :key="index">
          <div class="data-item-top">
            <div class="date">估苗{{ index + 1 }}</div>
            <div class="main-c" @click="toPage('edit', item)">编辑</div>
          </div>
          <yiyu-detail :formOpts="getFormOpts(item)"></yiyu-detail>
          <div class="single-ele-table" style="position: relative">
            <!-- :min-width="columnWidth(value.prop)" -->
            <el-table
              :header-cell-class-name="headerCellClassName"
              :cell-class-name="cellClassName"
              :row-class-name="rowClassName"
              :cell-style="{ color: '#666666' }"
              :data="item.lp_list"
              size="small"
              header-row-class-name="tableHead"
              v-if="item.lp_list && item.lp_list.length"
            >
              <el-table-column
                v-for="(value, index) in poolHeader"
                :key="index"
                :prop="value.prop"
                :label="value.label"
                align="center"
                :fixed="value.prop == 'first_cell_name'"
              >
                <template slot-scope="scope">
                  <template v-if="value.prop == 'first_cell_name'">
                    {{ scope.row['ws_name'] }}_{{ scope.row['p_name'] }}
                  </template>
                  <template v-if="value.prop == 'h_date'">
                    {{
                      scope.row['h_date'] && scope.row['h_date'].slice(2, 10)
                    }}
                  </template>
                  <template v-else>{{ scope.row[value.prop] }}</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <yiyu-empty v-if="list.length == 0">暂无估苗数据</yiyu-empty>
    </div>
  </div>
</template>

<script>
import yiyuDetail from '@/components/detail'
import { table } from '@/utils/mixins'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
export default {
  name: 'estimate',
  components: {
    yiyuNavbar,
    yiyuEmpty,
    yiyuDetail,
  },
  mixins: [table],
  data() {
    return {
      lm_id: undefined,
      isLoading: false,
      larvaKey: undefined, // 跳回去要用到
      list: [],
      poolHeader: [
        {
          label: '',
          prop: 'first_cell_name',
        },
        {
          label: '估苗量(万)',
          prop: 'count',
        },
        {
          label: '预估出苗日期',
          prop: 'h_date',
        },
      ],
    }
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.lm_id = query.lm_id
      if (this.lm_id) {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/estimate/getEsList', {
        lm_id: this.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data.list
        }
      })
    },
    getDataObj(type, item) {
      let tmp
      switch (type) {
        case 'estimate':
          return [
            [
              {
                data: `${item.count || 0}万（${item.survival_rate || 0}%）`,
                descText: '估苗量(%)',
              },
              {
                data: item.type == 1 ? '分池' : '不分池',
                descText: '类型',
              },
              {
                data: item.opt_user,
                descText: '操作人',
              },
            ],
          ]
        case 'esti-pool':
          tmp = [[]]
          item.forEach((item1) => {
            tmp[0].push({
              data: item1.count,
              unitText: '万',
              descText: item1.p_name,
            })
          })
          return tmp
        case 'date-pool':
          tmp = [[]]
          item.forEach((item1) => {
            tmp[0].push({
              data: item1.h_date && item1.h_date.slice(2, 10),
              descText: item1.p_name,
            })
          })
          return tmp
      }
    },
    // 将预估出苗日期转换成需要所要的数据结构
    getDateObj(dateList) {
      let obj = {}
      // obj: {
      //   date: {
      //     ws_key: {
      //       ws_name,
      //       child: [p_name]
      //     }
      //   }
      // }

      if (Array.isArray(dateList)) {
        dateList.forEach((item) => {
          item.list &&
            item.list.forEach((item1) => {
              let date = item1.h_date && item1.h_date.slice(0, 10)
              if (obj[date]) {
                if (obj[date][item1.ws_key]) {
                  obj[date][item1.ws_key].child.push(item1.p_name)
                } else {
                  obj[date][item1.ws_key] = {
                    ws_name: item1.ws_name,
                    child: [item1.p_name],
                  }
                }
              } else {
                obj[date] = {
                  [item1.ws_key]: {
                    ws_name: item1.ws_name,
                    child: [item1.p_name],
                  },
                }
              }
            })
        })
      }

      return obj
    },
    getFormOpts(item) {
      return [
        {
          title: '',
          child: [
            {
              label: '估苗日期',
              val: item.e_date && item.e_date.slice(0, 10),
            },
            {
              label: '估苗量(%)',
              val: (item.count || 0) + '(' + (item.survival_rate || 0) + '%)',
            },
            {
              label: '类型',
              val: item.type == 1 ? '分池估苗' : '整批次估苗',
            },
            {
              label: '操作人',
              val: item.opt_user,
            },
            {
              label: '备注',
              val: item.remark,
            },
          ],
        },
      ]
    },
    toPage(type, item) {
      switch (type) {
        case 'add':
          this.$push(this, {
            name: 'estimateEdit',
            query: {
              lm_id: this.lm_id,
              status: 'add',
            },
          })
          break
        case 'edit':
          this.$push(this, {
            name: 'estimateEdit',
            query: {
              lmce_key: item.lmce_key,
              lm_id: this.lm_id,
              status: 'edit',
            },
          })
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  padding: 1rem;

  .row-between {
    display: flex;
    justify-content: space-between;
  }

  .main-content {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: @titleColor;
      }
    }

    .data-wrap {
      .data-item {
        margin-bottom: 1rem;
        .data-item-top {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          border-radius: 8px;
          background: rgba(@mainRgb, 0.1);
        }
      }
    }
  }

  .date-list-wrap {
    padding: 0.5rem 0;

    .date-list-item {
      padding: 0.5rem;
      border-bottom: 1px solid @lineColor;

      .date {
        color: @textColor;
        font-weight: 600;
      }

      .ws-item {
        padding: 0.5rem 0;
        .ws {
          color: @textColor;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
