<template>
  <div class="larva-product">
    <yiyu-navbar :tabTitle="tabTitle">
      <template #right>
        <handle-popver :actions="actions1"></handle-popver>
      </template>
    </yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <van-tabs v-model="tab" :ellipsis="false">
        <van-tab
          v-for="(item, index) in tabData"
          :title="item.title"
          :key="index + 'tab'"
          :name="item.name"
        >
          <template #title v-if="tabData.length == 1">
            <div class="row-between">
              <div>{{ item.title }}</div>
              <div></div>
            </div>
          </template>
          <template #default v-if="item.name == 'data'">
            <div class="single-ele-table">
              <el-table
                :header-cell-class-name="headerCellClassName"
                :cell-class-name="cellClassName"
                :row-class-name="rowClassName"
                :cell-style="{ color: '#666666' }"
                :data="tableData"
                size="small"
                header-row-class-name="tableHead"
                :border="true"
              >
                <el-table-column
                  v-for="(value, index) in tableHeader"
                  :key="index"
                  :prop="value.prop"
                  :label="value.label"
                  align="center"
                  :fixed="value.prop == 'first-cell'"
                >
                  <template slot-scope="scope">
                    <template>{{
                      scope.row[value.prop] || undefined
                    }}</template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
          <template #default v-else>
            <div class="handles">
              <div class="handle-btn-wrap">
                <template v-for="(item1, index1) in getHandles(item)">
                  <div
                    class="handle-btn"
                    :key="index1 + 'btn'"
                    @click="cbFn(item1.callback, item)"
                    v-if="item1.show != false"
                  >
                    <add-btn
                      :add="item1.add == true"
                      :src="item1.src"
                      :bgColor="item1.bgColor"
                    ></add-btn>
                    <div class="txt">{{ item1.text }}</div>
                  </div>
                </template>
                <van-popover
                  placement="left"
                  v-model="item.show"
                  theme="dark"
                  trigger="click"
                  :actions="actions"
                  @select="moreSelect($event, item)"
                  v-if="larva_info.build_type == 1"
                >
                  <template #reference>
                    <!-- 在移动端才能实现完美交互 -->
                    <div class="handle-btn">
                      <add-btn
                        :add="false"
                        :src="require('@/assets/icons/phase.png')"
                      ></add-btn>
                      <div class="txt">{{ '更多' }}</div>
                    </div>
                  </template>
                </van-popover>
              </div>

              <div class="data-item-center">
                <data-field
                  :dataObj="getDataObj('launch', curFarmData)"
                  type="grid-box"
                ></data-field>
              </div>
            </div>
            <div class="datas">
              <div class="data-item">
                <div class="data-item-top">
                  <div class="title">
                    关联&出苗({{
                      (curFarmData['order_list'] &&
                        curFarmData['order_list'].length) ||
                      0
                    }})
                  </div>
                  <div
                    class="text-c7 main-c"
                    @click="outOpt(curFarmData)"
                    v-if="IS_HANDLE('LARVA_BATCH_OUT_ADD')"
                  >
                    +新增出苗
                  </div>
                </div>
                <div
                  class="data-item"
                  v-for="(item, index) in curFarmData['order_list']"
                  :key="index + 'o'"
                >
                  <div class="row-between">
                    <div
                      class="main-c"
                      @click="toPage('order', item)"
                      v-if="item.or_id != 0"
                    >
                      订单{{ item.or_id }}
                    </div>
                    <div @click="toPage('order', item)" v-else>未关联订单</div>
                    <div class="row">
                      <div
                        class="main-c handle"
                        v-for="(item1, index1) in getOrderHandles(item)"
                        :key="index1"
                        @click="item1.toPage"
                      >
                        {{ item1.name }}
                      </div>
                    </div>
                  </div>
                  <div class="data-item-center" @click="showOrderDetail(item)">
                    <data-field
                      :dataObj="getDataObj('order', item)"
                      type="grid-box"
                    ></data-field>
                    <div class="handle-icon">
                      <van-icon name="arrow" />
                    </div>
                  </div>
                </div>
                <van-divider
                  v-if="
                    curFarmData['order_list'] &&
                    curFarmData['order_list'].length == 0
                  "
                  >暂无出苗信息</van-divider
                >
              </div>
              <template v-for="(item, index) in operation">
                <div class="data-item" v-if="item.show != false" :key="index">
                  <div
                    class="data-item-top"
                    @click="
                      typeof item.toPage == 'function'
                        ? item.toPage()
                        : () => {}
                    "
                  >
                    <div class="title">{{ item.title }}</div>
                    <div class="text-c7 main-c" v-if="!isCulEmpty(item)">
                      全部
                    </div>
                  </div>
                  <van-divider v-if="isCulEmpty(item)"
                    >今日暂未进行投喂,<span class="main-c f14" @click="culOpt"
                      >去投喂</span
                    ></van-divider
                  >

                  <template v-else>
                    <div
                      class="data-item"
                      v-if="typeof item.data == 'function'"
                    >
                      <data-field
                        v-for="(item1, index1) in item.arr"
                        :dataObj="[item.data(item1)]"
                        type="grid-box"
                        :key="index1"
                      ></data-field>
                    </div>
                    <data-field
                      :dataObj="[item.data]"
                      type="grid-box"
                      v-else
                    ></data-field>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>

    <van-dialog
      v-model="showEndDialog"
      title="完结提醒"
      :show-cancel-button="false"
      :show-confirm-button="false"
      class="end-dialog"
    >
      <template v-if="curFarmData['p_count']">
        <div class="msg">
          完结需要进行出苗/排苗/处理，将所有投放的育苗池完结后批次才可以进入完结状态。
        </div>
      </template>
      <template v-else>
        <div class="msg">
          因未投放到育苗池，因此填写完结日期后，批次为已完结。
        </div>
        <div class="msg">完结后也可以继续记录批次的各种数据。</div>
        <van-field
          label="完结日期"
          v-model="end_date"
          type="select"
          placeholder="请选择完结日期"
          @click="showDatePicker = true"
          :readonly="true"
          clickable
        ></van-field>
      </template>

      <div class="row-between">
        <div class="left btn" @click="showEndDialog = false">取消</div>
        <div class="right" v-if="curFarmData['p_count']">
          <span class="btn" @click="endHandle('remove')">排苗</span>
          <span class="btn" @click="endHandle('out')">出苗</span>
          <span class="btn" @click="endHandle('deal')">处理</span>
        </div>
        <div class="right btn" @click="end('1')" v-else>确认</div>
      </div>
    </van-dialog>
    <datetime-popup
      :date.sync="end_date"
      :show.sync="showDatePicker"
      title="选择完结日期"
      @confirm="showDatePicker = false"
      @cancel="showDatePicker = false"
      type="date"
    >
    </datetime-popup>
    <van-popup v-model="orderDetail.show" position="bottom" closeable>
      <section>
        <div class="title">{{ curFarmData.number }}客户需求</div>
        <data-field
          :dataObj="getDataObj('orderDemand', orderDetail.data)"
          type="grid-box"
        ></data-field>
      </section>
      <section>
        <div class="title">运输相关</div>
        <data-field
          :dataObj="getDataObj('orderTrans', orderDetail.data)"
          type="grid-box"
        ></data-field>
      </section>
    </van-popup>
  </div>
</template>

<script>
import handlePopver from '@/components/feedback/handlePopver.vue'
import { isOverDay } from '@/utils/date'
import datetimePopup from '@/components/popup/datetime.vue'
import addBtn from '@/components/button/addBtn'
import yiyuNavbar from '@/components/navbar'
import dataField from '@/components/layout/dataField.vue'
import { getKeyArr, getKeyObj, sectionToChinese } from '@/utils/utils'
// import { getDiffDay } from "@/utils/date";
import { Dialog } from 'vant'

import produceMixin from '@/views/produce/produceMixin'
import mcMixin from '@/mixin/mcMixin'
export default {
  name: 'larvaProduct',
  mixins: [produceMixin, mcMixin],
  components: {
    yiyuNavbar,
    addBtn,
    dataField,
    datetimePopup,
    handlePopver,
  },
  data() {
    return {
      postLoading: false,
      btnLoading: false,
      poolLoading: false,
      larvaKey: undefined,
      isLoading: true,
      larva_info: {}, // 育苗信息
      mc_list: [], // 投放基地
      actions: [
        // 常用操作-更多
        // { text: "育苗" },
        { text: '搬苗' },
        { text: '排苗' },
        { text: '处理' },
        { text: '完结' },
      ],
      tab: undefined, // 当前选中的tab
      tabData: undefined,
      tableProps: {
        launch: '投放量',
        launch_date: '投放日期',
        p_count: '池数',
        type_name: '育苗类别',
        sup_name: '育苗来源',
        s_trip_cost: '运费',
        e_count: '估苗数量(%)',
        e_date: '估苗日期',
        h_count: '出苗数量（%）',
        re_count: '已关联数量',
        recent_h_date: '最新出苗日期',
        c_count: '排苗量',
        d_count: '处理量',
        or_count: '订单数量',
      },
      tableData: [],
      curFarmData: {}, // 当前的基地数据
      poolData: [], // 育苗池详情
      allPoolData: [], // 当前基地所有育苗池(不分池)
      end_date: undefined,
      showEndDialog: false,
      showDatePicker: false,
      handleShow1: false,
      operation: [],
      orderDetail: {
        show: false,
        data: {},
      },
    }
  },
  activated() {
    if (this.$route.query) {
      let query = this.$route.query

      if (query.larvaKey) this.larvaKey = query.larvaKey

      if (query.farmKey) {
        this.tab = query.farmKey
      } else {
        this.tab = undefined
        this.tabData = []
      }
      if (this.larvaKey) {
        this.getData()
      }
    }
  },
  computed: {
    tabTitle() {
      let title = this.larva_info['batch']
      if (this.larva_info.build_type == 2) {
        title += '(外)'
      }
      return title || '生产详情'
    },
    tableHeader() {
      let tmp = [
        {
          label: '',
          prop: 'first-cell',
        },
      ]

      if (this.mc_list.length > 1) {
        tmp.push({
          label: '整批次',
          prop: 'all',
        })
      }

      this.mc_list.forEach((item) => {
        tmp.push({
          label: item.mc_name,
          prop: item.mc_key,
        })
      })
      return tmp
    },
    actions1() {
      let action = this.FILTER_HANDLE([
        {
          text: this.isOverDay(this.curFarmData['end_date'])
            ? '未完结'
            : '完结',
          handle: 'LARVA_BATCH_OVER',
          show: this.larva_info.build_type == 1,
          fn: () => {
            this.toEnd()
          },
        },
        {
          text: '删除',
          handle: 'LARVA_BATCH_DEL',
          fn: () => {
            this.del()
          },
        },
        {
          text: '编辑',
          handle: 'LARVA_BATCH_EDIT',
          fn: () => {
            this.toPage('editLarva')
          },
        },
      ])

      return action.filter((item) => item.show != false)
    },
  },
  watch: {
    tab(val) {
      if (val != 'data' && val) {
        this.curFarmData = this.getFarmData(val)
        this.operation = this.getOperations()
      }
      sessionStorage['curTab'] = val
    },
  },
  methods: {
    // 获取常用操作
    getHandles() {
      return [
        {
          src: require('@/assets/icons/dect.png'),
          text: '质检申请',
          add: true,
          bgColor: '#1296db',
          callback: 'dectOpt',
          show: this.IS_HANDLE('DECT_APPLY_ADD'),
        },
        {
          src: require('@/assets/icons/estimate.png'),
          text: '估苗',
          callback: 'estiOpt',
          add: true,
          bgColor: '#1296db',
          show: this.IS_HANDLE('LARVA_BATCH_ESTIMATE'),
        },
        {
          src: require('@/assets/icons/important.png'),
          text: '投喂',
          callback: 'culOpt',
          add: true,
          bgColor: '#1296db',
          show:
            this.IS_HANDLE('LARVA_BATCH_CUL') &&
            this.larva_info.build_type == 1,
        },
        {
          src: require('@/assets/icons/estimate.png'),
          text: '发送通知',
          callback: 'noticeOpt',
          add: true,
          bgColor: '#1296db',
          show: true,
        },
      ]
    },
    getData() {
      this.isLoading = true
      this.$get('/larva/getLarvaList', {
        l_key: this.larvaKey,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.larva_info = res.data.list[0] || {}
          this.mc_list = (res.data.list[0] && res.data.list[0]['mc_list']) || []

          this.tableData = []

          let addKeys = [
            'launch',
            'p_count',
            's_trip_cost',
            'e_count',
            're_count',
            'h_count',
            'c_count',
            'd_count',
            'or_count',
          ]

          for (let key in this.tableProps) {
            let tmp = {
              'first-cell': this.tableProps[key],
            }
            let allValue = 0
            let count = 0
            let launch = 0
            this.mc_list.forEach((item) => {
              if (key == 'e_count') {
                tmp[item['mc_key']] =
                  item[key] &&
                  (item[key] || 0) + '(' + (item['e_rate'] || 0) + '%)'
                count += Number(item[key] || 0)
                launch += Number(item['launch'])
                allValue =
                  count &&
                  count +
                    '(' +
                    (((count / launch) * 100).toFixed(2) * 100) / 100 +
                    '%)'
              } else if (key == 'h_count') {
                tmp[item['mc_key']] =
                  item[key] &&
                  (item[key] || 0) + '(' + (item['e_rate'] || 0) + '%)'
                count += Number(item[key] || 0)
                launch += Number(item['launch'])
                allValue =
                  count &&
                  count +
                    '(' +
                    (((count / launch) * 100).toFixed(2) * 100) / 100 +
                    '%)'
              } else if (
                key == 'launch_date' ||
                key == 'e_date' ||
                key == 'recent_h_date'
              ) {
                tmp[item['mc_key']] = this.$dayjs(item[key]).format(
                  'YYYY/MM/DD',
                )
              } else {
                tmp[item['mc_key']] = item[key]
                if (addKeys.includes(key)) {
                  allValue += Number(item[key] || 0)
                } else {
                  allValue = undefined
                }
              }
            })
            tmp['all'] = allValue
            this.tableData.push(tmp)
          }
          this.tabData = []

          this.mc_list.forEach((item) => {
            this.tabData.push({
              title: `${item.mc_name}(${
                isOverDay(item.end_date) ? '完结' : '未完结'
              })`,
              name: item.mc_key,
            })
          })

          if (this.mc_list.length > 1) {
            this.tabData.push({
              title: this.larva_info['batch'],
              name: 'data',
            })
          }

          this.tab = sessionStorage['curTab']

          //  要先改变tabData，后改变tab
          if (this.mc_list.length == 1) {
            this.tab = this.mc_list[0].mc_key
          }

          if (this.tab != 'data' && this.tab) {
            this.curFarmData = this.getFarmData(this.tab)
            this.operation = this.getOperations()
          }

          this.isLoading = false
        }
      })
    },
    getOrderHandles(order) {
      order.cached = true
      let _this = this
      let handle = [
        {
          name: '取消关联',
          toPage() {
            _this.cancelAlloct(order)
          },
          h_status: 0, // 待出苗
        },
        {
          name: '出苗',
          toPage() {
            _this.$toPage(_this, 'outEdit', order)
          },
          h_status: 0, // 待出苗
        },
        {
          name: '删除',
          toPage() {
            _this.cancelAlloct(order)
          },
          h_status: 1, // 出苗
        },
        {
          name: '编辑出苗',
          toPage() {
            _this.$toPage(_this, 'outEdit', order)
          },
          h_status: 1, // 出苗
        },
      ]
      return handle.filter((item) => item.h_status == order.h_status)
    },
    getOperations(item = this.curFarmData) {
      let _this = this
      item.cached = true
      return [
        {
          title: '运输',
          show: item.transport && item.transport.length != 0,
          arr: item.transport,
          data(item) {
            return [
              {
                data: _this
                  .$dayjs(item.s_starting_time)
                  .format('YY/MM/DD HH:mm'),
                descText: '发车',
              },
              {
                data: item.s_contacts,
                descText: '司机',
              },

              {
                data: item.license_plate,
                descText: '车牌',
              },
              {
                data: item.car_num
                  ? `第${sectionToChinese(item.car_num)}车`
                  : '暂无',
                descText: '车次',
              },
              {
                data: item.box,
                unitText: '箱',
                descText: '箱数',
              },
              {
                data: item.t_count,
                unitText: '万',
                descText: '数量',
              },
            ]
          },
          toPage() {
            _this.$toPage(_this, 'transport', {
              tab: '1',
              lm_id: _this.curFarmData.lm_id,
              cached: true,
            })
          },
        },
        {
          title: '估苗',
          toPage() {
            _this.toPage('estimate', item)
          },
          show: item.e_date != undefined,
          data: [
            {
              data: item.e_count,
              unitText: '万',
              descText: `估苗数量`,
            },
            {
              descText: '成活率',
              data: item.survival_rate,
              unitText: '%',
            },
            {
              data: this.$dayjs(item.e_date).format('YYYY/MM/DD'),
              descText: `估苗日期`,
            },
            {
              descText: '操作人',
              data: item.e_opt_user,
            },
          ],
        },
        {
          title: '质检',
          toPage() {
            _this.toPage('dect', item)
          },
          show: Boolean(
            item.t_apply_times || item.t_t_times || item.t_nt_times,
          ),
          data: [
            {
              data: item.t_apply_times,
              descText: `质检申请`,
            },
            {
              data: item.t_t_times,
              descText: `已质检`,
            },
            {
              descText: '待质检',
              data: item.t_nt_times,
            },
          ],
        },
        {
          title: '投喂',
          toPage() {
            _this.toPage('cul', item)
          },
          show: true,
          n_date: item.n_date,
          data: [
            {
              descText: '今日投喂',
              data: item.n_times,
            },
            {
              descText: '最新投喂',
              data: this.$dayjs(item.n_date).format('YYYY/MM/DD'),
            },
          ],
        },
        {
          title: '排苗',
          toPage() {
            _this.toPage('remove', item)
          },
          show: item.c_opt_user != undefined,
          data: [
            {
              data: item.c_count,
              unitText: '万',
              descText: `排苗量`,
            },
            {
              data: this.$store.state.basic.cleanType[item.c_type],
              descText: `排苗原因`,
            },
            {
              data: this.$dayjs(item.c_date).format('YYYY/MM/DD'),
              descText: '排苗日期',
            },
            {
              data: item.c_opt_user,
              descText: '操作人',
            },
          ],
        },
        {
          title: '处理',
          toPage() {
            _this.toPage('deal', item)
          },
          show: item.d_opt_user != undefined,
          data: [
            {
              data: item.d_count,
              unitText: '万',
              descText: `处理量`,
            },
            {
              data: item.d_unit_price,
              unitText: '元',
              descText: `单价`,
            },
            {
              data: item.d_sum_price,
              unitText: '元',
              descText: '金额',
            },
            {
              data: this.$dayjs(item.d_date).format('YYYY/MM/DD'),
              descText: '处理日期',
            },
            {
              data: item.d_opt_user,
              descText: '操作人',
            },
          ],
        },
        {
          title: '搬苗',
          toPage() {
            _this.toPage('move', item)
          },
          show: item.m_opt_user != undefined,
          data: [
            {
              data: item.mo_count,
              unitText: '万',
              descText: `搬苗量`,
            },
            {
              data: item.mi_count,
              unitText: '万',
              descText: `搬入量`,
            },
            {
              data: this.$dayjs(item.m_date).format('YYYY/MM/DD'),
              descText: '搬苗日期',
            },
            {
              data: item.m_opt_user,
              descText: '操作人',
            },
          ],
        },
        {
          title: '池数据',
          toPage() {
            _this.toPage('pool', item)
          },
          show: Boolean(item.ws_list && item.ws_list.length),
          data: [
            {
              data: this.getWsPNames(item.ws_list),
              descText: '车间',
            },
          ],
        },
      ]
    },
    getDataObj(type, item) {
      let _this = this
      switch (type) {
        case 'import':
          return [
            [
              {
                data: item.book,
                unitText: '万',
                descText: `苗数量`,
              },
              {
                data: item.unit_price,
                unitText: '元',
                descText: `单价`,
              },
              {
                data: item.sum_price,
                unitText: '元',
                descText: `金额`,
              },
            ],
          ]
        // 订单数据
        case 'order':
          return [
            [
              {
                data: item.re_count,
                unitText: '万',
                descText: '关联数量',
              },
              {
                descText: '出苗数量',
                unitText: '万',
                data: item.h_count,
                show: item.h_status == 1,
              },
              {
                data: this.$dayjs(item.scheduled_date).format('YYYY/MM/DD'),
                descText: '到货日期',
              },

              {
                descText: '出苗时间',
                data: this.$dayjs(item.h_date).format('YY/MM/DD HH:mm'),
                show: item.h_status == 1,
                isRow: true,
              },
            ],
          ]
        case 'orderDemand':
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: this.$dayjs(item.e_h_date).format('YY/MM/DD'),
                descText: '预定出苗',
              },
              {
                descText: '打包密度',
                data: item.density,
                unitText: '万/箱',
              },
              {
                descText: '印刷袋',
                data: item.bag_name,
              },
              {
                descText: '需求盐度',
                unitText: '格',
                data: item.salinity,
              },
              {
                descText: '需求温度',
                unitText: '℃',
                data: item.temperature,
              },
            ],
          ]
        case 'orderTrans':
          return [
            {
              data: _this.$dayjs(item.s_starting_time).format('YY/MM/DD HH:mm'),
              descText: '发车',
            },
            {
              data: getKeyArr(item.s_contacts, 'name').join('、'),
              descText: '司机',
            },
            {
              data: getKeyArr(item.car_list, 'license_plate').join('、'),
              descText: '车牌',
            },
            {
              data: item.car_num
                ? `第${sectionToChinese(item.car_num)}车`
                : '暂无',
              descText: '车次',
            },
          ]
        // 投放数据 育苗数据
        case 'launch':
          return [
            [
              {
                data: item.launch,
                unitText: '万',
                descText: `投放数量`,
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
              },
              {
                data: this.larva_info.breeding,
                unitText: '天',
                descText: '育苗天数',
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
              },
              {
                data: item.e_count,
                unitText: '万',
                descText: '估苗数量',
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
              },
              {
                data: item.p_count,
                unitText: '个',
                descText: '投放池数',
                show: [1].includes(Number(item.lm_status)),
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: '已关联数量',
                show: [2, 3].includes(Number(item.lm_status)),
              },
              {
                data: item.h_count,
                unitText: '万',
                descText: '出苗数量',
                show: [3, 4].includes(Number(item.lm_status)),
              },
              {
                data: this.$dayjs(item.e_h_date).format('YYYY/MM/DD'),
                descText: '预估出苗',
                show: [1, 2].includes(Number(item.lm_status)),
              },
              {
                descText: '品系',
                data: this.larva_info.strain,
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
              },
              {
                data: this.$dayjs(item.recent_h_date).format('YYYY/MM/DD'),
                descText: '最新出苗',
                show: [3, 4].includes(Number(item.lm_status)),
              },
              {
                descText: '来源基地',
                data:
                  getKeyArr(
                    this.larva_info.ss_ws_list,
                    'mc_name',
                    'ws_name',
                  ).join('、') ||
                  this.larva_info.ss_mc_name ||
                  '暂无',
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
              },
              {
                descText: '来源批次',
                data: this.larva_info.ss_batch,
                show: [1, 2, 3, 4].includes(Number(item.lm_status)),
                color: this.larva_info.ss_batch ? '#fd7739' : undefined,
                click: () => {
                  if (this.larva_info.ss_batch) {
                    this.$toPage('sBatchDetail', this.larva_info)
                  }
                },
              },
            ],
          ]
      }
    },
    // 获取育苗池详情
    getPoolData() {
      if (!this.curFarmData['lm_id']) return
      this.poolLoading = true
      this.$get('/larva/getLaunchDetails', {
        lm_id: this.curFarmData['lm_id'],
        by_type: 'ws',
      }).then((res) => {
        if (res.code == 10000) {
          this.poolLoading = false
          this.poolData = res.data.p
          this.allPoolData = []
          this.poolData.forEach((item) => {
            this.allPoolData.push(...item.list)
          })
        }
      })
    },
    seeMore(type) {
      switch (type) {
        case 'matter':
          this.matterObj.page++
          this.matterObj.curNum += 10
          // 只有没请求完的时候才继续请求
          // if (Number(this.matterObj.total) > this.matterObj.list.length) {
          //   this.getMatterData()
          // }
          break
      }
    },
    // 常用操作-更多
    moreSelect(action, item, type = 'handle') {
      switch (action.text) {
        case '搬苗':
          if (type == 'handle') {
            this.moveOpt(undefined)
          } else if (type == 'pool') {
            this.moveOpt(item.p_key)
          }
          break
        case '排苗':
          if (type == 'handle') {
            this.removeOpt(undefined)
          } else if (type == 'pool') {
            this.removeOpt(item.p_key, true)
          }
          break
        case '投喂':
          if (type == 'handle') {
            this.culOpt(undefined)
          } else if (type == 'pool') {
            this.culOpt(item)
          }
          break
        case '处理':
          this.dealOpt()
          break
        case '删除':
          this.del()
          break
        case '编辑':
          this.toPage('editLarva')
          break
        case '未完结':
        case '完结':
          this.toEnd()
          break
      }
    },
    showOrderDetail(item) {
      this.orderDetail.show = true
      this.orderDetail.data = item
    },
    headerCellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return 'all-table-header-bgc first-header-cell'
      } else {
        return 'all-table-header-bgc'
      }
    },

    cellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return `first-cell`
      } else {
        return 'last-cell'
      }
    },

    rowClassName({ rowIndex }) {
      if (rowIndex % 2) {
        return 'table-utils even-row'
      } else {
        return 'table-utils'
      }
    },
    cbFn(fn, value) {
      if (
        fn == 'moveOpt' ||
        fn == 'removeOpt' ||
        fn == 'dectOpt' ||
        fn == 'culOpt'
      )
        value = undefined
      this[fn](value)
    },
    isOverDay(val) {
      return isOverDay(val)
    },
    // 常用操作-质检
    dectOpt() {
      this.$toPage(this, 'dectApplyEdit', {
        mc_key: this.tab,
        // lm_id: this.curFarmData['lm_id'],
        l_key: this.larvaKey,
        test_type: '1',
        ws_type: '1',
        cached: true,
      })
    },
    // 常用操作-估苗
    estiOpt() {
      this.$toPage(this, 'estimateEdit', {
        lm_id: this.curFarmData['lm_id'],
        status: 'add',
        cached: true,
      })
    },
    // 操作-直接出苗
    outOpt() {
      this.$toPage(this, 'outEdit', {
        lm_id: this.curFarmData['lm_id'],
        cached: true,
      })
    },
    // 其他操作-育苗
    culOpt() {
      this.$toPage(this, 'techEdit', {
        mc_key: this.tab,
        lm_id: this.curFarmData['lm_id'],
        test_type: '2',
        l_key: this.larvaKey,
        ws_type: '1',
        cached: true,
      })
    },
    // 其他操作-处理
    dealOpt() {
      this.$toPage('dealEdit', {
        lm_id: this.curFarmData['lm_id'],
        status: 'add',
        cached: true,
      })
    },
    // 其他操作-搬苗
    moveOpt() {
      if (this.curFarmData['p_count']) {
        this.$toPage('moveEdit', {
          lm_id: this.curFarmData['lm_id'],
          mc_key: this.curFarmData['mc_key'],
          status: 'add',
          cached: true,
        })
      } else {
        this.$toast.fail('因未投放到池，所以无法使用搬苗功能哦')
      }
    },
    // 其他操作-排苗
    removeOpt() {
      this.$toPage('removeEdit', {
        lm_id: this.curFarmData['lm_id'],
        status: 'add',
        cached: true,
      })
    },
    // 育苗池操作-完结
    overOpt(item) {
      if (this.btnLoading) return
      this.btnLoading = true
      this.$post('/larva/endLaunch', {
        lp_id: [item['lp_id']],
      }).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('结束育苗成功')
          // this.getPoolData()
        }
      })
    },
    noticeOpt() {
      let p = '1'
      let title = ''
      let remark = [
        {
          label: '批次',
          value: this.larva_info.batch,
        },
        {
          label: '苗场',
          value: this.curFarmData.mc_name,
        },
      ]
      let re = [
        {
          type: 1,
          re_info: {
            l_key: this.curFarmData.l_key,
            mc_key: this.curFarmData.mc_key,
          },
        },
      ]
      this.toNotice({ p, title, remark, re })
    },
    toPage(type, item) {
      switch (type) {
        // 修改育苗
        case 'editLarva':
          if (this.larva_info.build_type == 2) {
            this.$toPage('larvaImport', {
              l_key: this.larvaKey,
              status: 'edit',
              cached: true,
            })
          } else {
            this.$toPage('larvaLaunch', {
              l_key: this.larvaKey,
              cached: true,
            })
          }
          break
        // 质检列表
        case 'dect':
          this.$toPage(this, 'dectApplyList', {
            mc_key: this.curFarmData['mc_key'],
            l_key: this.curFarmData['l_key'],
            test_type: '1',
            cached: true,
          })
          break
        // 育苗列表
        case 'cul':
          this.$toPage(this, 'techList', {
            lm_id: this.curFarmData['lm_id'],
            test_type: '2',
            cached: true,
          })
          break
        // 排苗详情
        case 'remove':
        case 'deal':
        case 'estimate':
          this.$toPage(type, {
            lm_id: this.curFarmData['lm_id'],
            cached: true,
          })
          break

        // 搬苗详情
        case 'move':
          if (this.curFarmData['p_count']) {
            this.$toPage('move', {
              lm_id: this.curFarmData['lm_id'],
              mc_key: this.curFarmData['mc_key'],
              cached: true,
            })
          } else {
            this.$toast.fail('因未投放到池，所以无法使用搬苗功能哦')
          }
          break
        // 育苗池详情
        case 'pool':
          this.$toPage(this, 'poolDetail', {
            lm_id: this.curFarmData['lm_id'],
            cached: true,
          })
          break
        case 'order':
          this.$toPage(this, 'salesDetail', item)
          break
      }
    },
    getFarmData(key) {
      return getKeyObj(key, this.mc_list, 'mc_key')
    },
    toEnd() {
      if (isOverDay(this.curFarmData['end_date'])) {
        Dialog.confirm({
          title: '修改提醒',
          message: '确认要将状态修改为完结吗？',
        })
          .then(() => {
            this.end('0')
          })
          .catch(() => {})
      } else {
        this.showEndDialog = true
      }
    },
    end(is_end) {
      if (this.postLoading) return
      this.postLoading = true
      this.$post('/larva/endLM', {
        lm_id: this.curFarmData['lm_id'],
        is_end,
        end_date: this.end_date,
      }).then((res) => {
        this.postLoading = false
        if (res.code == 10000) {
          if (is_end == 0) {
            this.$toast.success('设置未完结成功！')
          } else {
            this.$toast.success('设置完结成功')
          }
          this.showEndDialog = false
          this.getData()
        }
      })
    },
    del() {
      let msg =
        '删除批次后，批次的关联订单、出苗、估苗、质检、育苗、排苗、搬苗、处理数据都将会删除，是否确认删除？'

      if (this.larva_info.build_type == 2) {
        msg = '删除当前外调苗批次后, 关联的订单将会取消关联,是否确认删除?'
      }

      Dialog.confirm({
        title: '删除提醒',
        message: msg,
      })
        .then(() => {
          this.$post('/larva/delLarva', {
            l_key: this.larvaKey,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('删除批次成功')
              this.$go(-1, this, {
                name: 'FF_produce',
                params: {
                  tab: 'larva',
                },
              })
            }
          })
        })
        .catch(() => {})
    },
    endHandle(type) {
      this.showEndDialog = false
      switch (type) {
        case 'remove':
          this.removeOpt(undefined)
          break
        case 'out':
          this.outOpt()
          break
        case 'deal':
          this.dealOpt()
          break
      }
    },
    isCulEmpty(item) {
      return item.title == '投喂' && !item.n_date
    },
  },
}
</script>

<style lang="less" scoped>
.larva-product {
  margin: 0.5rem 1rem;
  background: #fff;

  .bold-title {
    color: @titleColor;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .row-between {
    display: flex;
    justify-content: space-between;
  }

  .handles {
    .title {
      display: flex;
      justify-content: space-between;
    }

    .handle-btn-wrap {
      display: flex;
      background: #fff;
      padding: 8px 16px;
      flex-wrap: wrap;
      justify-content: space-between;

      .handle-btn {
        // width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem 0;

        .txt {
          white-space: nowrap;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .datas {
    padding: 1rem 0;
    &-title {
      color: @titleColor;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .data-item {
    margin: 0.5rem 0;
    .data-item-top {
      display: flex;
      justify-content: space-between;
      .title {
        color: @titleColor;
        font-size: 1rem;
        font-weight: 700;
      }
    }
    .data-item-center {
      display: flex;
      padding: 0.5rem;

      .yiyu-data-field {
        flex-basis: 100%;
      }

      .handle-btn {
        flex-basis: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: @bgColor;
      }

      .handle-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .farm-detail {
    padding: 1rem;

    .name {
      display: flex;
      align-items: center;
    }

    .content {
      background: rgba(@bgColor, 1);
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
    }

    .farm-detail-select {
      margin: 0.5rem 0;
    }

    .title {
      color: @titleColor;
      font-weight: 600;
    }
  }

  .end-dialog {
    /deep/.van-dialog__header {
      padding-top: 0;
      color: @titleColor;
      font-weight: 600;
    }

    .msg {
      color: @textColor;
      margin: 0.5rem 0;
    }

    .left.btn,
    .right.btn {
      width: 40%;
      text-align: center;
    }

    .right {
      .btn {
        margin-left: 0.5rem;
      }
    }
  }

  .handle {
    margin-left: 0.5rem;
  }
}

/deep/.van-popup {
  padding: 1rem;
}
</style>
