<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <div
      class="main-content"
      v-myLoad="isLoading"
      v-if="form.lm_id || form.lmce_key"
    >
      <div class="main-content-header">
        <div class="title">{{ form.batch }}-{{ form.mc_name }}</div>
        <data-field :dataObj="getDataObj('batch', form)"></data-field>
      </div>
      <yiyu-form
        :_this="thisAlias"
        :formOpts="formOpts"
        @submit="onSubmit"
        ref="form"
      >
        <template #rate>
          <div class="rate" v-if="form.launch && form.count">
            ({{ getRate(form.count, form.launch) }}%)
          </div>
        </template>
        <template #set>
          <div class="estimate-set" v-if="form.type == 1">
            <div class="estimate-set-header">
              估苗量{{ form.count || 0 }}万
              <tip-popover
                >当前估苗量根据每个育苗池填写的估苗量相加</tip-popover
              >
            </div>
            <div class="estimate-set-content">
              <van-field
                v-model="item['count']"
                center
                clearable
                :label="item.ws_name + '_' + item.p_name"
                placeholder="请输入池估苗量"
                v-for="(item, index) in count_list"
                :key="index + 'esti'"
              >
                <template #extra>
                  ({{ getRate(item['count'], item['launch']) }}%)
                </template>
              </van-field>
              <van-divider v-if="count_list.length == 0"
                >暂无待估苗的育苗池</van-divider
              >
            </div>
          </div>
          <div class="harvest-set">
            <yiyu-collapse :show.sync="show">
              <template #left>
                <div class="harvest-set-header">
                  <div class="row-between">
                    <div class="title">
                      预估出苗日期设置<tip-popover>
                        <div>操作：</div>
                        <div>
                          直接点击车间可以对车间中的育苗池进行批量选择出苗时间；
                        </div>
                        <div>单独点击池时，可对单独池进行出苗时间的选择。</div>
                      </tip-popover>
                    </div>
                  </div>
                  <div class="date">{{ getBatchHDate() }}</div>
                </div>
              </template>
              <template #content>
                <!-- <van-divider v-if="date_list.length == 0"
                  >暂无待填写的育苗池</van-divider
                > -->
                <div v-if="date_list.length == 0">
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="form.harvest_date && form.harvest_date.slice(0, 10)"
                    label=""
                    placeholder="请选择预估出苗日期"
                    @click="choseHDate('noPool', form)"
                    class="harvest-set-ws-item-pool"
                    is-link
                    arrow-direction="down"
                  />
                </div>
                <div
                  class="harvest-set-ws-wrap"
                  v-for="(item, index) in date_list"
                  :key="index + 'hdate'"
                >
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="getWsHDate(item)"
                    :label="item.ws_name"
                    placeholder="可为所有池统一选择出苗日期"
                    @click="choseHDate('ws', item)"
                    class="harvest-set-ws-item-ws"
                    is-link
                    arrow-direction="down"
                  />
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="item1.h_date && item1.h_date.slice(0, 10)"
                    :label="item1.p_name"
                    placeholder="请选择预估出苗日期"
                    @click="choseHDate('pool', item1)"
                    class="harvest-set-ws-item-pool"
                    v-for="(item1, index1) in item['list']"
                    :key="index1 + 'pool'"
                    is-link
                    arrow-direction="down"
                  />
                </div>
              </template>
            </yiyu-collapse>
          </div>
        </template>
      </yiyu-form>
      <fixed-btn
        :btnTxt="status == 'add' ? '确认新增' : '保存'"
        @click="toSubmit"
        :loading="btnLoading"
        :disabled="btnLoading"
      ></fixed-btn>
    </div>
    <yiyu-empty v-else
      >暂无可估苗的批次，
      <span class="main-c" @click="addBatch">去添加</span>
    </yiyu-empty>

    <datetime-popup
      :date.sync="form.e_date"
      :show.sync="showEstimateDatePicker"
      title="选择估苗时间"
      @confirm="showEstimateDatePicker = false"
      @cancel="showEstimateDatePicker = false"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="curHDate"
      :show.sync="showHarvestDatePicker"
      title="选择预估出苗日期"
      type="date"
      @confirm="confirmPicker($event, 'harvestDate')"
      @cancel="cancelPicker('harvestDate')"
    >
    </datetime-popup>

    <farm-batch-picker
      ref="farmBatchPicker"
      v-if="form.mc_key"
      :farmKey="form.mc_key"
      @confirm="confirmPicker($event, 'batch')"
      @cancel="closeBatch"
    ></farm-batch-picker>

    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.mc_key"
        :curName.sync="form.mc_name"
        :single="true"
        @confirm="choseBatch"
        @cancel="closeFarm"
      ></farm-picker>
    </keep-alive>

    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            @click="toPage('notice')"
          >
            发布通知
          </van-button>
          <van-button
            class="success-popup-handle-btn"
            type="primary"
            @click="toPage('last')"
          >
            返回
          </van-button>
        </div>
      </template>
    </success-popup>
  </div>
</template>

<script>
import successPopup from '@/components/popup/success';

import farmPicker from '@/bComponents/farmPicker';
import fixedBtn from '@/components/button/fixedBtn';
import tipPopover from '@/components/feedback/tipPopover.vue';
import { formatTime, getFuture } from '@/utils/date';
import { deepCopy, numToStr } from '@/utils/utils';
import yiyuNavbar from '@/components/navbar';
import dataField from '@/components/layout/dataField';
import yiyuCollapse from '@/components/seeMore/collapse.vue';
import yiyuForm from '@/components/form.vue';
import datetimePopup from '@/components/popup/datetime.vue';
import farmBatchPicker from '@/bComponents/farmBatchPicker.vue';
import yiyuEmpty from '@/components/layout/empty.vue';
export default {
  name: 'estimateEdit',
  components: {
    dataField,
    yiyuNavbar,
    yiyuCollapse,
    yiyuForm,
    datetimePopup,
    tipPopover,
    fixedBtn,
    farmBatchPicker,
    farmPicker,
    yiyuEmpty,
    successPopup,
  },
  data() {
    return {
      status: 'add', // 'farm'表示从基地过来 'nofarm'表示未选择任何基地
      isLoading: false,
      thisAlias: this,
      form: {
        lmce_key: undefined,
        mc_key: undefined,
        mc_name: undefined,
        lm_id: undefined,
        count: undefined, //"估苗总量",
        e_date: undefined, //"估苗日期",
        remark: undefined, //"备注",
        type: undefined, // 1 分池估苗，2 不分池估苗
        harvest_date: undefined,
      },
      date_list: [], // 出苗时间预估
      count_list: [], // 出苗量预估
      showEstimateDatePicker: false,
      showHarvestDatePicker: false,
      curPoolIndex: undefined,
      curWsIndex: undefined,
      curHDateItem: undefined, // 可修改
      curHandleType: undefined, // 区分当前操作的是车间 还是 育苗池
      curHDate: undefined, // 当期选中的日期
      typeObj: {
        1: '分池估苗',
        2: '整批次估苗',
      },
      showPopover: false, // 提示展示
      show: false,
      btnLoading: false,
      addSuccessShow: false,
    };
  },
  computed: {
    tabTitle() {
      if (this.status == 'edit') return '编辑估苗';
      else return '新增估苗';
    },
    addHeader() {
      return {
        title: this.status == 'add' ? '新建成功' : '修改成功',
        left: {
          txt: '',
          fn: 'cancel',
        },
        right: {
          txt: '继续估苗',
          fn: 'confirm',
        },
      };
    },
    addContent() {
      return {
        title: this.status == 'add' ? '估苗成功' : '修改估苗成功',
        subTitle:
          '估苗率' + this.getRate(this.form.count, this.form.launch) + '%',
      };
    },
    formOpts() {
      let _this = this;
      return [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'radio',
              value: this.form.type,
              name: 'type',
              label: '估苗类型',
              opts: _this.typeObj,
              tipSlot:
                '分池估苗：分别记录每个池的估苗量；不分池估苗：只记录该批次的总估苗量，然后平均为各个池的估苗量',
            },
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.count,
              name: 'count',
              label: '估苗量',
              type: 'number',
              placeholder: '请输入估苗量',
              extra: '万',
              slot: 'rate',
              rules: [
                {
                  required: true,
                  message: '请输入估苗量',
                },
                {
                  validator: (val) =>
                    val > this.form.launch || val < 0 ? false : true,
                  message: '估苗量不能大于投放量',
                },
              ],
              show: this.form.type == 2,
            },
            {
              topSlot: 'set',
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.e_date,
              name: 'e_date',
              label: '估苗时间',
              placeholder: '请选择估苗时间',
              click() {
                _this.showEstimateDatePicker = true;
              },
              rules: [{ required: true, message: '请选择估苗时间' }],
            },
            {
              required: false,
              colon: true,
              formType: 'textarea',
              type: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '可输入估苗的文字描述等情况',
            },
          ],
        },
      ];
    },
  },
  watch: {
    count_list: {
      handler() {
        this.addCount();
      },
      deep: true,
    },
    'form.type'() {
      this.addCount();
    },
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query;
      this.form.lmce_key = query.lmce_key;
      this.form.lm_id = query.lm_id;
      this.status = query.status;

      this.form.mc_key = query.farmKey;
      if (this.form.mc_key && this.status == 'farm') {
        this.choseBatch();
      } else if (this.status == 'nofarm') {
        this.choseFarm();
      }
      if (this.form.lmce_key || this.form.lm_id) {
        this.getData();
      }
    }
  },
  methods: {
    // 入口1：批次详情页，“新增估苗”（此处进入不需要选择基地及批次）

    // 入口2：批次详情 - 估苗详情列表 - “新增估苗”（此处进入不需要选择基地及批次）

    // 入口3：基地顶部按钮 - “新增估苗”，此入口返回到基地列表页

    // 入口4：基地详情 - “估苗”，此入口返回到基地详情页
    closeFarm() {
      if (this.status == 'nofarm') {
        this.$go(-1, this, {
          name: 'FF_produce',
          params: {
            tab: 'farm',
          },
        });
      }
    },
    closeBatch() {
      if (this.status == 'farm') {
        this.$go(-1, this, {
          name: 'farmDetail',
          query: {
            mcKey: this.form.mc_key,
          },
        });
      } else if (this.status == 'nofarm') {
        this.choseFarm();
      }
    },
    // 选择批次
    addBatch() {
      if (this.form.mc_key) {
        this.choseBatch();
      } else {
        this.choseFarm();
      }
    },
    // 获取批次
    choseBatch(e) {
      if (this.form.mc_key || (e && e.key)) {
        this.$nextTick(() => {
          if (this.$refs['farmBatchPicker']) {
            this.$refs['farmBatchPicker'].show = true;
            this.$refs['farmBatchPicker'].getLarvaList();
          }
        });
      }
    },
    // 获取基地
    choseFarm() {
      this.$nextTick(() => {
        if (this.$refs['farmPicker']) {
          this.$refs['farmPicker'].show = true;
        }
      });
    },
    getData() {
      this.isLoading = true;
      return this.$get('/estimate/getEsDetails', {
        lmce_key: this.form.lmce_key,
        lm_id: this.form.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;

          let data = res.data;
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
          }

          if (data['type']) {
            data['type'] = String(data['type']);
          } else {
            data['type'] = '2';
          }

          data['last_count'] = data['count'];

          data['count'] = data['count'];

          this.form = data;
          this.count_list = data.count_list;

          data.date_list?.forEach((item) => {
            item.list?.forEach((item1) => {
              if (this.status == 'add') {
                // harvest_date 默认出苗日期
                item1.h_date =
                  item1.h_date?.slice(0, 10) ||
                  this.form.harvest_date?.slice(0, 10);
              } else {
                item1.h_date = item1.h_date?.slice(0, 10) || undefined;
              }
            });
          });

          this.date_list = deepCopy(data.date_list);

          if (this.status == 'add') {
            this.form.lmce_key = undefined;
            this.form.e_date = formatTime(new Date()).slice(0, 13) + ':00';
            this.form.e_Date = new Date();
          }
        }
      });
    },
    getDataObj(type, item) {
      switch (type) {
        case 'batch':
          return [
            [
              {
                data: item.launch,
                unitText: '万',
                descText: '投放量',
              },
              {
                data: item.pool_count,
                descText: '育苗池数',
              },
              {
                data: `${item.last_count || 0}万（${
                  item.survival_rate || 0
                }%）`,
                descText: '上次估苗(%)',
              },
            ],
          ];
      }
    },
    onSubmit() {
      this.addCount();
      let form = JSON.parse(JSON.stringify(this.form));
      if (form.type == 1) {
        //  分池估苗
        form.es_count_list = [];
        this.count_list.forEach((item) => {
          if (item.count) {
            form.es_count_list.push({
              lp_id: String(item.lp_id),
              count: String(item.count),
            });
          }
        });
      } else {
        //  不分池估苗
        let count =
          ((form.count / this.count_list.length).toFixed(2) * 100) / 100;
        form.es_count_list = [];
        this.count_list.forEach((item) => {
          form.es_count_list.push({
            lp_id: String(item.lp_id),
            count: String(count),
          });
        });
      }

      form.es_date_list = [];
      this.date_list.forEach((item) => {
        form.es_date_list.push(...numToStr(item['list']));
      });

      this.btnLoading = true;
      this.$post('/estimate/addEstimate', numToStr(form)).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          // this.$toast.success(this.tabTitle + '成功');
          this.form.lmce_key = res.data?.lmce_key;
          this.addSuccessShow = true;
        }
      });
    },
    confirmPicker(e, type) {
      switch (type) {
        // 预估出苗日期
        case 'harvestDate':
          if (this.curHandleType == 'ws') {
            if (Array.isArray(this.curHDateItem['list'])) {
              this.curHDateItem['list'].forEach((item) => {
                item['h_date'] = this.curHDate;
              });
            }
          } else if (this.curHandleType == 'noPool') {
            this.form.harvest_date = this.curHDate;
          } else {
            this.curHDateItem['h_date'] = this.curHDate;
          }
          this.showHarvestDatePicker = false;
          break;
        case 'batch':
          if (e) {
            // 继续估苗
            this.form.lm_id = e;
            this.status = 'add';
            this.getData().then(() => {
              this.form.lmce_key = undefined;
            });
          } else {
            // this.$toast.fail("暂无法估苗哦")
            // this.$go(-1, this, {
            //   name: 'FF_sales',
            //   query: {
            //     tab: 'farm'
            //   }
            // })
          }
          break;
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'harvestDate':
          this.showHarvestDatePicker = false;
          break;
      }
    },
    // 获取车间的出苗日期
    getWsHDate(item) {
      if (item.list && item.list.length) {
        let date = item.list[0]['h_date'];
        let flag = item.list.some((item1) => item1['h_date'] != date);
        if (flag) return undefined;
        else return date && date.slice(0, 10);
      }
    },
    // 获取批次的出苗日期
    getBatchHDate() {
      if (this.date_list && this.date_list.length) {
        let date = this.getWsHDate(this.date_list[0]);
        let flag = this.date_list.some((item) => this.getWsHDate(item) != date);
        if (flag) return undefined;
        else return date && date.slice(0, 10);
      }
    },
    // 选择出苗日期
    choseHDate(type, item) {
      this.curHandleType = type;
      this.curHDateItem = item;

      if (type == 'ws') {
        this.curHDate = this.getWsHDate(item);
      } else if (type == 'noPool') {
        this.curHDate = item['harvest_date'] || getFuture(new Date(), 15);
      } else {
        this.curHDate = item['h_date'];
      }
      this.showHarvestDatePicker = true;
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    // 成活率计算
    getRate(son, parent) {
      if (parent) {
        let num = (son / parent) * 100;
        return (num.toFixed(2) * 100) / 100;
      } else {
        return 0;
      }
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          this.$go(-1, this, {
            name: 'estimate',
            query: {
              lm_id: this.form.lm_id,
            },
          });
          break;
        case 'confirm':
          this.addSuccessShow = false;
          this.form.lmce_key = undefined;
          this.choseFarm();
          break;
      }
    },
    toPage(type) {
      switch (type) {
        case 'notice':
          {
            let p = '1';
            let title = '估苗';
            let remark = [
              {
                label: '批次',
                value: this.form.batch,
              },
              {
                label: '投放量',
                value: this.form.launch,
                extra: '万',
              },
              {
                label: '估苗量',
                value: this.form.count,
                extra: '万',
              },
              {
                label: '估苗率',
                value: this.getRate(this.form.count, this.form.launch),
                extra: '%',
              },
            ];
            this.toNotice({
              p,
              title,
              remark,
              status: this.status,
              re: [
                {
                  type: '22',
                  re_info: {
                    lmce_key: this.form.lmce_key,
                  },
                },
              ],
            });
          }
          break;
        case 'last':
          this.$go(-1, this, {
            name: 'estimate',
            query: {
              lm_id: this.form.lm_id,
            },
          });
          break;
      }
    },
    addCount() {
      if (this.form.type == '1') {
        let num = 0;
        this.count_list.forEach((item) => {
          num += Number(item['count']);
        });
        // this.form.count = num
        this.form.count = (num.toFixed(2) * 100) / 100;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 1rem;
  .estimate-set-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .harvest-set-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .harvest-set-ws-item-ws {
    border-top: 1px solid @lineColor;
    /deep/.van-cell__title.van-field__label {
      color: @titleColor;
      font-weight: 600;
    }
  }

  .harvest-set-header {
    display: flex;
    align-items: flex-start;
    height: 100%;
    line-height: 100%;

    .row-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
      }
    }
    .date {
      margin-left: auto;
    }
  }
}
</style>
