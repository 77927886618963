<template>
  <van-popup
    v-model="show"
    round
    position="bottom"
    class="popup"
    :close-on-click-overlay="false"
  >
    <div class="header">
      <div class="row-between">
        <div class="header-left" @click="cancel">取消</div>
        <div class="header-center">请选择批次</div>
        <div class="header-right" @click="confirm">确认</div>
      </div>
    </div>
    <div class="content">
      <van-radio-group
        v-model="lm_id"
        v-if="list.length || completedList.length"
      >
        <div v-for="(item, index) in list" :key="index" class="batch-item">
          <van-radio :name="item.lm_id">{{ item.batch }} </van-radio>
          <data-field :dataObj="getDataObj(item)"></data-field>
        </div>

        <van-divider
          v-if="completedList.length"
          @click="completedShow = !completedShow"
          ><span
            >完结批次，
            <span class="main-c"
              >点击{{ completedShow ? '收起' : '展开' }}</span
            >
          </span></van-divider
        >
        <template v-if="completedShow">
          <div
            v-for="(item, index) in completedList"
            :key="index + 'no'"
            class="batch-item"
          >
            <van-radio :name="item.lm_id">{{ item.batch }} </van-radio>
            <data-field :dataObj="getDataObj(item)"></data-field>
          </div>
        </template>
      </van-radio-group>
      <van-divider v-else>暂无数据</van-divider>
    </div>
  </van-popup>
</template>

<script>
import dataField from '@/components/layout/dataField'
export default {
  // 估苗、搬苗、排苗、处理前要选择的指定批次
  name: 'farmBatchPicker',
  props: {
    farmKey: {
      type: String,
      required: true,
    },
  },
  components: {
    dataField,
  },
  data() {
    return {
      list: [],
      completedList: [],
      mc_name: undefined,
      show: false,
      isLoading: false,
      lm_id: undefined,
      completedShow: false,
    }
  },
  methods: {
    // 获取基地投放的批次
    getLarvaList() {
      this.isLoading = true
      this.$get('/larva/getMCLarvaList', {
        mc_key: this.farmKey,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = []
          this.completedList = []
          res.data.list &&
            res.data.list.forEach((item) => {
              if (item.end == 1) {
                this.completedList.push(item)
              } else {
                this.list.push(item)
              }
            })
          this.mc_name = res.data.mc_name
        }
      })
    },
    confirm() {
      this.show = false
      this.$emit('confirm', this.lm_id)
    },
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    getDataObj(item) {
      return [
        [
          {
            data: (item.e_count || 0) + '(' + (item.e_rate || 0) + '%)',
            unitText: '',
            descText: `上次估苗量(%)`,
          },
          {
            data: item.breeding,
            unitText: '天',
            descText: `育苗天数`,
          },
          {
            data: (item.e_date && item.e_date.slice(2, 10)) || '暂无',
            descText: '上次估苗',
          },
        ],
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.popup {
  display: flex;
  flex-direction: column;
  .header {
    font-size: 1.1rem;
    padding: 1rem;
    padding-bottom: 0;
    text-align: center;

    .row-between {
      display: flex;
      justify-content: space-between;
    }

    &-left {
      color: @textColor;
    }

    &-right {
      color: @mainColor;
    }

    &-center {
      color: @titleColor;
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .content {
    max-height: 50vh;
    overflow-y: auto;
    flex-basis: 100%;
    padding: 1rem;
    position: relative;

    .batch-item {
      margin: 0.5rem 0;
    }
  }
}
</style>
