<template>
  <classify-tag-popup
    :show.sync="show"
    :title="title"
    :list="choseList"
    pLabel="typeName"
    pChild="children"
    cValue="name"
    cKey="mc_key"
    @confirm="confirmPicker($event)"
    @cancel="cancel"
    @closed="$emit('closed')"
    :cancelShow="cancelShow"
    :single="single"
    :pCurKey.sync="curKey"
    :loading="isLoading"
  >
    <!-- FIXME:可搜索抄这里 -->
    <template #top>
      <van-search
        @search="getData"
        v-model="keyword"
        placeholder="可搜索基地名称"
      ></van-search>
    </template>
    <template #bottom>
      <div class="classify-tag-popup-bottom">
        <span class="btn main-c" @click="add">去添加基地</span>
      </div>
    </template>
  </classify-tag-popup>
</template>

<script>
import classifyTagPopup from '@/components/popup/classifyTag.vue'
import { classifyData, getKeyValue } from '@/utils/utils'
export default {
  name: 'farmPicker',
  props: {
    farmUrl: {
      type: String,
      default: '/mc/getMCListV2',
    },
    value: {
      default: false,
    },
    curKey: {
      // sync
      // required: true,
      type: [String, Number, Array], // 多选的时候才是数组
    },
    curName: {
      // sync
      type: String,
    },
    single: {
      type: Boolean,
      default: false,
    },
    cancelShow: {
      type: Boolean,
      default: true,
    },
    farmStatus: {
      // 苗场状态
      type: Array,
      default: () => ['1', '2', '3'],
    },
    farmType: {
      // 苗场类型
      type: Array,
      default: () => ['0', '1', '2', '3', '4'],
    },
    title: {
      type: String,
      default: '请选择基地',
    },
    type: {
      // 车间类型 育苗/种虾/藻类
      type: [String, Number],
      default: undefined,
    },
  },
  components: {
    classifyTagPopup,
  },
  data() {
    return {
      show: this.value, // 父级有调用，不能改名先
      choseList: [],
      isLoading: false,
      list: [],
      keyword: undefined,
    }
  },
  watch: {
    curKey() {
      this.getCurName()
    },
    type() {
      this.getData()
    },
    value(val) {
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
    },
  },
  activated() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get(this.farmUrl, {
        status: this.farmStatus,
        type: this.farmType,
        keyword: this.keyword,
        ws_type: this.type,
      })
        .then((res) => {
          if (res.code == 10000) {
            this.list = res.data.list
            let list = res.data.list
            list.forEach((item) => {
              if (this.$store.state.basic.farmType[item.type]) {
                item.typeName = this.$store.state.basic.farmType[item.type]
              } else {
                item.typeName = '其他'
              }
            })
            
            this.choseList = classifyData(list, 'type', 'typeName', 'arr')
            function sortArray(arr) {
              arr.sort(function(a, b) {
                if (a.type == 2 && b.type != 2) {
                  return -1; // a排在b前面
                } else if (a.type != 2 && b.type == 2) {
                  return 1; // b排在a前面
                } else {
                  return 0; // 保持原顺序
                }
              });
            }
            sortArray(this.choseList)
            this.getCurName()

            this.$emit('callback')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    confirmPicker(e) {
      this.$emit('update:curKey', e.key)
      this.$emit('confirm', e)
      this.show = false
    },
    getCurName() {
      if (!this.single) {
        let tmp = []
        if (Array.isArray(this.curKey)) {
          this.curKey.forEach((item) => {
            tmp.push(getKeyValue(item, this.list, 'name', 'mc_key'))
          })
        }
        this.$emit('update:curName', tmp.join('、'))
      } else {
        if (this.curKey) {
          let tmp = getKeyValue(this.curKey, this.list, 'name', 'mc_key')
          this.$emit('update:curName', tmp)
        } else {
          this.$emit('update:curName', undefined)
        }
      }
    },
    add() {
      this.show = false
      this.$toPage(this, 'farmEdit', {
        ws_type: this.type,
        status: 'add',
        back: true,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.classify-tag-popup-bottom {
  border-top: 1px solid @lineColor;
  padding-top: 0.5rem;
  text-align: center;
}
</style>
