import dayjs from 'dayjs'

export const VanCalendarPresetMixin = {
  data() {
    return {
      minDate: dayjs(new Date()).subtract(3, 'year').$d,
      maxDate: dayjs(new Date()).add(2, 'year').$d,
    }
  },
  computed: {
    // 以前一直使用 minDate 与 maxDate
    // 现为了更好知道数据来源，新增该两个 computed
    minDateMixin() {
      return this.minDate
    },
    maxDateMixin() {
      return this.maxDate
    },
  },
}
