<template>
  <div>
    <yy-navbar tab-title="排幼体" />
    <yiyu-form :_this="this" :formOpts="formOpts" @submit="handleSubmit">
      <template #dataObjSlot>
        <data-field class="my-2" :dataObj="dataObj" />
      </template>

      <template #footer>
        <fixed-btn btnNativeType="submit" />
      </template>
    </yiyu-form>

    <van-calendar
      v-model="showCalendar"
      @confirm="handleCalendarConfirm"
      :min-date="minDateMixin"
      :max-date="maxDateMixin"
      :show-confirm="false"
    />
  </div>
</template>

<script>
import { YDialog } from 'yiyu-vant-ui';
import { createFormOptions } from '@/utils/form';
import DataField from '@/components/layout/dataField.vue';
import YiyuForm from '@/components/form/yiyuForm.vue';
import FixedBtn from '@/components/button/fixedBtn.vue';
import { VanCalendarPresetMixin } from '@/mixin/VanCalendarPreset';

export default {
  components: {
    YiyuForm,
    FixedBtn,
    DataField,
  },
  mixins: [VanCalendarPresetMixin],
  data() {
    return {
      form: {
        batch: '',
        record_date: '',
        count: '',
        date: '',
        remark: '',
      },
      fetchData: undefined,
      showCalendar: false,
    };
  },
  computed: {
    formOpts() {
      return createFormOptions([
        {
          child: [
            {
              label: '种虾批次',
              required: true,
              value: this.form?.batch,
              readonly: true,
            },
            {
              label: '幼体归属日期',
              required: true,
              value: this.form?.record_date,
              readonly: true,
            },
            {
              topSlot: 'dataObjSlot',
              show: false,
            },
            {
              label: '排日期',
              required: true,
              formType: 'select',
              value: this.form.date,
              click: () => {
                this.showCalendar = true;
              },
            },
            {
              label: '排幼体量',
              required: true,
              formType: 'number',
              value: this.form.count,
              name: 'count',
            },
            {
              colon: true,
              formType: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
            },
          ],
        },
      ]);
    },
    dataObj({ fetchData }) {
      return [
        [
          {
            data: fetchData?.real_yield,
            unitText: '万',
            descText: '实际产量',
          },
          {
            data: fetchData?.arrange,
            unitText: '万',
            descText: '已安排量',
          },
          {
            data: fetchData?.no_arrange,
            unitText: '万',
            descText: '待安排量',
          },
        ],
      ];
    },
  },
  created() {
    const { key } = this.$route.params;
    this.getData({ sr_key: key }).then((res) => {
      const { data } = res;
      const d = data.list?.[0];
      this.fetchData = d;
      if (d) {
        this.form.batch = d.batch;
        this.form.count = d.lc_count;
        this.form.record_date = d.record_date;
        this.form.date = d.date;
        this.form.remark = d.remark;
      }
    });
  },
  methods: {
    getData(params) {
      return this.$get('/settle/getSRArrange', params);
    },
    async handleSubmit() {
      try {
        const res = await this.$post('/settle/clearLarva', {
          ...this.form,
          sr_key: this.$route.params.key,
        });
        const { code } = res;
        if (code === 10000) {
          YDialog({
            type: 'list',
            title: '排幼体成功',
            data: [
              {
                key: '发布通知',
                onConfirm: () => {
                  this.$toPage('notice');
                },
              },
              {
                key: '返回入口',
                onConfirm: () => {
                  this.$router.back();
                },
              },
            ],
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleCalendarConfirm(e) {
      this.form.date = this.$dayjs(e).format('YYYY-MM-DD');
      this.showCalendar = false;
    },
  },
};
</script>
