<template>
  <div class="main">
    <yiyu-navbar tab-title="出苗"></yiyu-navbar>
    <div class="main-content">
      <yiyu-form
        :_this="thisAlias"
        :formOpts="formOpts"
        @submit="onSubmit"
        ref="form"
        v-myLoad="isLoading"
        style="position: relative"
      >
        <template #transEdit>
          <div class="transEdit" v-if="form.ha_key">
            <template
              v-if="
                IS_HANDLE('TRANSPORT_S_TRIP_EDIT') &&
                IS_HANDLE('TRANSPORT_L_TRIP_EDIT')
              "
            >
              <handle-popver :actions="transActions">
                <div class="main-c">运输信息</div>
              </handle-popver>
            </template>
            <template v-else-if="IS_HANDLE('TRANSPORT_S_TRIP_EDIT')">
              <div class="main-c" @click="toPage('transEdit', 1)">车运信息</div>
            </template>
            <template v-else-if="IS_HANDLE('TRANSPORT_L_TRIP_EDIT')">
              <div class="main-c" @click="toPage('transEdit', 2)">
                空运信息
              </div></template
            >
          </div>
        </template>
        <template #order>
          <data-field :dataObj="getDataObj('order', form)"></data-field>
        </template>
        <template #addCount>
          <van-icon
            name="add"
            :color="$mainColor"
            @click="addCount"
            class="f20"
          />
        </template>
        <template #isPool>
          <div
            class="is-pool-btn"
            @click.prevent="isPool = true"
            v-if="!isPool"
          >
            精准到池
          </div>
        </template>
        <template #cancelPool>
          <span class="main-c" @click.prevent="isPool = false">取消到池</span>
        </template>
        <template #pool>
          <data-field :dataObj="poolDataObj"> </data-field>
          <pool-choose
            :post-data.sync="form.re_pool"
            type="out"
            :date="form.h_date"
            :lm_id="form.lm_id"
            keyName="lp_id"
            :curData="form.h_info"
          ></pool-choose>
        </template>
        <template #set>
          <van-icon
            @click="toPage('set')"
            name="setting-o"
            :color="$mainColor"
            class="f14"
        /></template>
      </yiyu-form>
      <fixed-btn
        btnTxt="确认"
        :disabled="btnLoading"
        :loading="btnLoading"
        @click="toSubmit"
      ></fixed-btn>
    </div>
    <datetime-popup
      :date.sync="form['h_date']"
      :show.sync="showHarvestDatePicker"
      title="选择出苗时间"
      type="datetime"
      @confirm="showHarvestDatePicker = false"
      @cancel="showHarvestDatePicker = false"
    >
    </datetime-popup>
    <yiyu-picker
      v-if="showBagPicker"
      :curKey="form.bag_key"
      :show.sync="showBagPicker"
      :columns="bagColumns"
      value-key="name"
      keyName="bag_key"
      @confirm="confirmPicker($event, 'bag')"
      @cancel="cancelPicker('bag')"
      :loading="bagLoaindg"
    >
      <template #empty>
        <div class="bag-empty" v-handle="'MORE_SETTING_LARVA'">
          <span class="btn" @click="addBag">去添加印刷袋</span>
        </div>
      </template>
    </yiyu-picker>
    <form-popup
      :show.sync="formOrderShow"
      v-if="formOrderShow"
      :_this="thisAlias"
      :form-opts="formOrderOpts"
      @cancel="cancelPicker('formOrder')"
      @confirm="confirmPicker($event, 'formOrder')"
      right-txt="保存"
      title="订单信息记录 "
      formName="form"
    >
      <template #top>
        <data-field :dataObj="getDataObj('orderEdit', form)"></data-field>
      </template>
    </form-popup>
  </div>
</template>

<script>
import poolChoose from '@/bComponents/poolChoose';
import formPopup from '@/components/popup/formPopup';
import yiyuPicker from '@/components/popup/picker';
import dataField from '@/components/layout/dataField';
import yiyuForm from '@/components/form/yiyuForm';
import { getKeyValue, numToStr, sectionToChinese } from '@/utils/utils';
import yiyuNavbar from '@/components/navbar';
import datetimePopup from '@/components/popup/datetime.vue';
import fixedBtn from '@/components/button/fixedBtn';
import { isOverDay } from '@/utils/date';
import handlePopver from '@/components/feedback/handlePopver.vue';
import { YDialog } from 'yiyu-vant-ui';
export default {
  name: 'outEdit',
  components: {
    yiyuNavbar,
    yiyuForm,
    fixedBtn,
    dataField,
    datetimePopup,
    yiyuPicker,
    formPopup,
    poolChoose,
    handlePopver,
  },
  data() {
    return {
      status: 'add',
      btnLoading: false,
      activated: false, // mounted 和 activated 只须执行一个
      isLoading: false,
      thisAlias: this,
      form: {
        direct: '1', // 1 直接出苗， 0 非直接出苗
        lm_id: undefined,
        h_date: undefined, // "出苗时间",
        box: undefined, // "打包箱数",
        harvest_count: undefined, // "出苗量"，
        real_count: undefined, // "实结苗量"，
        lo_key: undefined, // "关联key",
        remark: undefined, // "备注",
        pack_worker: undefined, // "打包人数",
        dm_count: [], // [ 1000, 2000, 5000],    // 点苗
        re_pool: [], // [ ]    // 出苗池
        dipper_times: undefined, // 总点苗数
        re_count_front: undefined, // 已关联量
        real_density: undefined, // 实际密度
        lm_end: undefined,
      },
      showHarvestDatePicker: false,
      pool: {},
      pool_list: [],
      poolForm: {},
      settlement_percent: undefined,
      showBagPicker: false,
      formOrderShow: false,
      editForm: {
        lo_key: undefined, // "关联key",
        ha_key: undefined,
        re_count: undefined, // "关联量",
        box: undefined, // "箱数",
        density: undefined, // "10",                     // 密度
        bag_key: undefined, // "印刷袋key",
        bag_name: undefined,
        salinity: undefined, // "11",                     // 盐度
        temperature: undefined, // "21",                     // 温度
        ice: undefined, // "1",                      // 是否加冰瓶 1 需要， 2 不需要
      },
      isPool: false, // 是否精准到池
      transActions: [
        {
          text: '车运',
          fn: () => {
            this.toPage('transEdit', 1);
          },
        },
        {
          text: '空运',
          fn: () => {
            this.toPage('transEdit', 2);
          },
        },
      ],
    };
  },
  watch: {
    bagColumns: {
      deep: true,
      handler(val) {
        if (this.form.bag_key) {
          this.form.bag_name =
            (val.length &&
              getKeyValue(this.form.bag_key, val, 'name', 'bag_key')) ||
            undefined;
        }
      },
    },
    'form.dm_count'(val) {
      if (Array.isArray(val) && !this.form.lo_key && this.status == 'add') {
        let num = 0;
        val.forEach((item) => {
          if (item) num += Number(item);
        });
        this.form.dipper_times = num || undefined;
      }
    },
    'form.harvest_count'(val) {
      if (
        val &&
        this.form.density &&
        !this.form.lo_key &&
        this.status == 'add'
      ) {
        this.form.box = Math.ceil(Number(val) / Number(this.form.density));
      }
    },
    // 只监听一个，小数的监听有点难监听两个
    'editForm.box'(val) {
      if (val && this.editForm.re_count && this.status == 'add') {
        let num = Number(this.editForm.re_count) / Number(val);
        this.editForm.density = (num.toFixed(2) * 100) / 100;
      }
    },
    'form.box'() {
      if (this.form.real_density && this.form.box && this.status == 'add') {
        this.form.harvest_count =
          ((this.form.real_density * this.form.box).toFixed(2) * 100) / 100;
      }
    },
    'form.real_density'() {
      if (this.form.real_density && this.form.box && this.status == 'add') {
        this.form.harvest_count =
          ((this.form.real_density * this.form.box).toFixed(2) * 100) / 100;
      }
    },
    'form.re_pool': {
      deep: true,
      handler() {
        let num = 0;
        this.form.re_pool.forEach((item) => {
          num += Number(item.h_count || 0);
        });
        this.form.re_count_front = num;
      },
    },
  },
  computed: {
    // 待关联量
    no_re_count_front() {
      return (
        Number(this.form.harvest_count || 0) -
        Number(this.form.re_count_front || 0)
      );
    },
    formOrderOpts() {
      let _this = this;
      return [
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.re_count,
          name: 're_count',
          label: '关联量',
          placeholder: '请输入关联量',
          extra: '万尾',
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.box,
          name: 'box',
          label: '箱数',
          placeholder: '请输入箱数',
          extra: '箱',
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.salinity,
          name: 'salinity',
          label: '盐度',
          placeholder: '请输入盐度',

          // rules: [{ required: true, message: "请输入盐度" }],
          extra: '格数',
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.temperature,
          name: 'temperature',
          label: '温度',
          placeholder: '请输入温度',
          // rules: [{ required: true, message: "请输入温度" }],
          extra: '℃',
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.density,
          name: 'density',
          label: '打包密度',
          placeholder: '请输入打包密度',
          rules: [{ required: false, message: '请输入打包密度' }],
          extra: '万/箱',
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          value: this.editForm.bag_name,
          name: 'bag_name',
          label: '印刷袋',
          placeholder: '请选择印刷袋',
          click() {
            _this.showBagPicker = true;
          },
        },
        {
          required: false,
          colon: true,
          formType: 'radio',
          value: this.editForm.ice,
          name: 'ice',
          label: '冰瓶',
          opts: [
            {
              label: '不需要',
              name: 0,
            },
            {
              label: '需要',
              name: 1,
            },
          ],
        },
      ];
    },
    formOpts() {
      let _this = this;
      return [
        {
          title: '客户需求',
          topSlot: 'order',
          show: this.form.direct != undefined && this.form.direct == 0,
        },
        {
          title: '出苗信息',
          titleRight: 'transEdit',
          slot: 'isPool',
          child: [
            {
              required: true,
              colon: true,
              formType: 'radio',
              value: this.form.lm_end,
              name: 'lm_end',
              label: '完结状态',
              opts: [
                {
                  label: '已完结',
                  name: '1',
                },
                {
                  label: '未完结',
                  name: '0',
                },
              ],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.h_date && this.form.h_date.slice(0, 16),
              name: 'h_date',
              label: '出苗时间',
              placeholder: '请选择出苗时间',
              click() {
                _this.showHarvestDatePicker = true;
              },
              rules: [{ required: true, message: '请选择出苗时间' }],
            },
            {
              required: true,
              colon: true,
              type: 'number',
              formType: 'input', // 默认
              // readonly: true,
              value: this.form.box || undefined,
              name: 'box',
              extra: '箱',
              label: '箱数',
              placeholder: '请输入箱数',
              rules: [{ required: true, message: '请输入箱数' }],
            },
            {
              required: true,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.real_density,
              name: 'real_density',
              label: '实际密度',
              placeholder: '请输入实际密度',
              extra: '万/箱',
              rules: [{ required: true, message: '请输入实际密度' }],
            },
            {
              // required: false,
              required: true,
              colon: true,
              type: 'number',
              formType: 'input', // 默认
              // readonly: true,
              value: this.form.harvest_count || undefined,
              name: 'harvest_count',
              extra: '万',
              label: '出苗量',
              // placeholder: "自动生成",
              placeholder: '请输入出苗量',
              rules: [{ required: true, message: '请输入出苗量' }],
              change: () => {
                // 实结苗量
                if (this.settlement_percent && this.status == 'add') {
                  this.form.real_count = Math.floor(
                    (this.form.harvest_count * this.settlement_percent) / 100
                  );
                }
              },
            },
            {
              required: false,
              colon: true,
              type: 'number',
              formType: 'input', // 默认
              value: this.form.real_count || undefined,
              name: 'real_count',
              extra: '万',
              label: '实结苗量',
              placeholder: '请输入实结苗量',
              rules: [{ required: false, message: '请输入实结苗量' }],
              slot: 'set',
            },
            {
              required: false,
              colon: true,
              type: 'number',
              formType: 'input', // 默认
              value: this.form.pack_worker || undefined,
              name: 'pack_worker',
              extra: '人',
              label: '打包人数',
              placeholder: '请输入打包人数',
            },
            {
              required: false,
              colon: true,
              formType: 'textarea',
              value: this.form.remark || undefined,
              name: 'remark',
              label: '备注',
              placeholder: '请输入备注',
              rules: [{ required: false, message: '请输入备注' }],
            },
          ],
        },
        {
          title: '关联育苗池',
          titleRight: 'cancelPool',
          slot: 'pool',
          show: this.isPool,
        },
      ];
    },
    // 待出苗量：从出苗安排中获取
    // 已关联量：育苗池中填写的累计关联量（实时更新）
    // 待关联量：待出苗量-已关联量（实时更新）
    poolDataObj() {
      return [
        [
          {
            data: this.form.harvest_count || 0,
            unitText: '万',
            descText: '待出苗量',
          },
          {
            data: this.no_re_count_front,
            unitText: '万',
            descText: '待关联量',
          },
          {
            data: this.form.re_count_front || 0,
            unitText: '万',
            descText: '已关联量',
          },
        ],
      ];
    },
    poolFormOpts() {
      let _this = this;
      let tmp = [];
      if (Array.isArray(_this.pool_list)) {
        _this.pool_list.forEach((item) => {
          if (item.e_count == _this.pool[item.lp_id]) {
            item.p_completed = true;
          }
          tmp.push({
            label: item.ws_name + '_' + item.p_name,
            name: item.lp_id,
            type: 'number',
            placeholder: '请输入',
            extra: '万',
            value: _this.pool[item.lp_id],
            valueKey: ['pool', item.lp_id],
            default: item.e_count,
            slot: 'complete' + item.lp_id,
            p_completed: item.p_completed,
          });
        });
      }
      return tmp;
    },
    bagColumns() {
      return this.$store.state.setting.bags;
    },
  },
  activated() {
    if (!this.activated) {
      this.activated = true;
      return;
    }
    this.getBagData();
    this.getSetting();
  },
  async mounted() {
    this.activated = false;
    this.getBagData();
    if (this.$route.query) {
      let query = this.$route.query;
      this.form.lo_key = query.lo_key;
      this.form.lm_id = query.lm_id;

      await this.getSetting();
      await this.getData();
    }
  },
  methods: {
    getDataObj(type, item) {
      switch (type) {
        case 'order':
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.density,
                unitText: '万/箱',
                descText: '打包密度',
              },
              {
                data: item.bag_name || '暂无',
                descText: '印刷袋',
              },
              {
                data: item.scheduled_date && item.scheduled_date.slice(2, 10),
                descText: '预定出苗',
              },
            ],
            [
              {
                data:
                  item.ice == 0 ? '不需要' : item.ice == 1 ? '需要' : '暂无',
                descText: '冰瓶',
              },
              {
                data: item.salinity,
                unitText: '格',
                descText: '盐度',
              },
              {
                data: item.temperature,
                unitText: '℃',
                descText: '温度',
              },
              {},
            ],
          ];
        case 'orderEdit':
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: '关联量',
              },
              {
                data: item.h_count,
                unitText: '万',
                descText: '出苗量',
              },
              {
                data: item.scheduled_date && item.scheduled_date.slice(2, 10),
                descText: '预定出苗',
              },
            ],
          ];
      }
    },
    async getSetting() {
      await this.$get('/setting/getSetting', {}).then((res) => {
        if (res.code == 10000) {
          this.settlement_percent = res.data.settlement_percent;
        }
      });
    },
    async getData() {
      if (!this.form.lo_key) return;
      this.isLoading = true;
      await this.$get('/harvest/getHarvest', {
        lo_key: this.form.lo_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          let data = res.data;

          this.form.direct = data.or_id ? '0' : '1';

          if (data.h_date) {
            this.status = 'edit';
            // 避免没有值的时候变成了默认值
            if (isOverDay(data.end_date)) {
              this.form.lm_end = '1';
            } else {
              this.form.lm_end = '0';
            }
          } else {
            this.status = 'add';
          }

          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
          }

          if (data.lm_end) {
            data.lm_end = String(data.lm_end);
          }

          data.dm_count = data.dm_count || [];
          data.bag_name = getKeyValue(
            data.bag_key,
            this.bagColumns,
            'name',
            'bag_key'
          );

          data.harvest_count = data.h_count;

          if (!data.h_date && data.scheduled_date)
            data.h_Date = new Date(data.scheduled_date);

          // 要考虑先后顺序
          this.form = data;
          if (data.h_info && data.h_info.length) {
            this.isPool = true;
          }
        }
      });
    },
    onSubmit() {
      this.form.re_pool.forEach((item) => {
        item.p_completed = Number(item.p_completed);
      });

      this.btnLoading = true;

      this.$post('/harvest/addHarvest', numToStr(this.form)).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          this.form.lo_key = res.data?.lo_key;

          if (this.status == 'add') {
            this.nextPage();
          } else {
            this.$toast.success('出苗成功');
            this.$go(-1, this);
          }
        }
      });
    },
    nextPage() {
      YDialog({
        type: 'list',
        title: '新增出苗成功',
        data: this.FILTER_HANDLE([
          {
            key: '新增车运',
            onConfirm: () => {
              this.$toPage(
                this,
                'transEdit',
                {
                  lo_key: this.form.lo_key,
                  type: 1,
                },
                {
                  route_type: '$replace',
                }
              );
            },
            handle: 'TRANSPORT_S_TRIP_ADD',
          },
          {
            key: '新增空运',
            onConfirm: () => {
              this.$toPage(
                this,
                'transEdit',
                {
                  lo_key: this.form.lo_key,
                  type: 2,
                },
                {
                  route_type: '$replace',
                }
              );
            },
            handle: 'TRANSPORT_L_TRIP_ADD',
          },

          {
            key: '返回入口',
            onConfirm: () => {
              this.$go(-1, this);
            },
          },
        ]),
      });
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'bag':
          this.form.bag_key = e[0].bag_key;
          this.form.bag_name = e[0].name;
          this.showBagPicker = false;
          break;
        case 'formOrder':
          this.$post('/po/updateRelation', numToStr(this.editForm)).then(
            (res) => {
              if (res.code == 10000) {
                this.$toast.success('修改订单信息成功');
                this.formOrderShow = false;
                // 前端局部修改
                this.form.re_count = this.editForm.re_count;
                this.form.box = this.editForm.box;
                this.form.density = this.editForm.density;
                this.form.bag_key = this.editForm.bag_key;
                this.form.bag_name = this.editForm.bag_name;
                this.form.salinity = this.editForm.salinity;
                this.form.temperature = this.editForm.temperature;
                this.form.ice = this.editForm.ice;
              }
            }
          );
          break;
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'bag':
          this.showBagPicker = false;
          break;
      }
    },
    sectionToChinese(val) {
      return sectionToChinese(val);
    },
    edit(type, item) {
      switch (type) {
        case 'order':
          this.editForm.lo_key = item.lo_key;
          this.editForm.quantity = item.quantity;
          this.editForm.scheduled_date = item.scheduled_date;
          this.editForm.re_count = item.re_count;
          this.editForm.box = item.box;
          this.editForm.density = item.density;
          this.editForm.bag_key = item.bag_key;
          this.editForm.bag_name = undefined;
          if (this.editForm.bag_key) {
            this.editForm.bag_name =
              (this.bagColumns.length &&
                getKeyValue(
                  this.editForm.bag_key,
                  this.bagColumns,
                  'name',
                  'bag_key'
                )) ||
              undefined;
          }
          this.editForm.salinity = item.salinity;
          this.editForm.temperature = item.temperature;
          this.editForm.ice = item.ice;
          this.formOrderShow = true;
          break;
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'set':
          this.$toPage(this, 'settlementSet');
          break;
        case 'transEdit':
          this.$toPage(this, 'transEdit', {
            lo_key: this.form.lo_key,
            ha_key: this.form.ha_key,
            type: item,
            // cached: true,
          });
          break;
      }
    },
    getBagData() {
      if (!this.$store.state.setting.getBags) {
        this.bagLoaindg = true;
        this.$store.dispatch('getBag', {}).then((res) => {
          if (res.code == 10000) {
            this.bagLoaindg = false;
          }
        });
      }
    },
    addBag() {
      this.$push(this, { path: '/bagSet' });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 0.5rem;
}
.is-pool-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  background: @bgColor;
  border: none;
  color: @mainColor;
  font-size: 1rem;
  height: 2.4rem;
}
</style>
