<template>
  <div class="add-btn" @click="click">
    <template v-if="add">
      <img :src="src" alt="" class="img-btn" />
      <div class="badge-icon" :style="{ background: bgColor }">
        <van-icon name="plus" class="plus-icon" />
      </div>
    </template>
    <template v-else>
      <img :src="src" alt="" class="img-btn" />
    </template>
  </div>
</template>

<script>
// 这个组件应该是弃用了
export default {
  name: 'addBtn',
  props: {
    add: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
      default: require('@/assets/icons/dect.png'),
    },
    bgColor: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="less" scoped>
.add-btn {
  position: relative;
  .img-btn {
    width: 1.5rem;
    height: 1.5rem;
  }
  .badge-icon {
    position: absolute;
    top: -5%;
    right: -12%;
    background: @mainColor;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plus-icon {
    font-size: 0.625rem;
    font-weight: 600;
    color: #fff;
  }
}
</style>
