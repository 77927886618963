<template>
  <div class="matter-detail">
    <yiyu-navbar tab-title="重要事项"></yiyu-navbar>

    <yiyu-detail
      :formOpts="formOpts"
      :form="form"
      style="position: relative"
      v-myLoad="isLoading"
    ></yiyu-detail>

    <fixed-btn btnTxt="编辑" @click="edit"></fixed-btn>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuDetail from '@/components/detail'
import yiyuNavbar from '@/components/navbar'
import { getKeyValue } from '@/utils/utils'
export default {
  name: 'materDetail',
  components: {
    fixedBtn,
    yiyuDetail,
    yiyuNavbar,
  },
  data() {
    return {
      key: undefined,
      form: {
        node: undefined,
        nodeName: undefined,
        matter: undefined,
      },
      nodeOpts: [],
      isLoading: false,
    }
  },
  created() {
    this.getNode()
    if (this.$route.query) {
      let query = this.$route.query
      this.larvaKey = query.larvaKey
      this.farmKey = query.farmKey
      this.key = query.key
      if (this.key) {
        this.getData()
      }
    }
  },
  computed: {
    formOpts() {
      return [
        {
          title: '',
          child: [
            {
              label: '结点',
              val: this.form.nodeName,
            },
            {
              label: '事项',
              val: this.form.matter,
            },
          ],
        },
      ]
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/matter/getMatter', {
        ma_key: this.key,
      }).then((res) => {
        if (res.code === 10000) {
          res.data.data.forEach((item) => {
            if (item.title == '事项') {
              this.form.matter = item.value
            } else if (item.title == '结点') {
              this.form.node = item.value
              if (this.nodeOpts.length) {
                this.form.nodeName = getKeyValue(
                  this.form.node,
                  this.nodeOpts,
                  'name',
                  'n_key',
                )
              }
            }
          })
        }
      })
    },
    getNode() {
      this.$get('/node/getNodeList', {}).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.nodeOpts = res.data.list
          if (this.form.node) {
            this.form.nodeName = getKeyValue(
              this.form.node,
              this.nodeOpts,
              'name',
              'n_key',
            )
          }
        }
      })
    },
    edit() {
      this.$push(this, {
        name: 'matterEdit',
        query: {
          key: this.key,
          larvaKey: this.larvaKey,
          farmKey: this.farmKey,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped></style>
