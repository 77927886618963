<template>
  <van-popup
    class="form-popup"
    @close="clickFn('cancel')"
    @closed="clickFn('cancel')"
    v-model="showVisible"
    :round="!isPage"
    position="bottom"
    :style="{ height: isPage ? '100vh' : 'auto' }"
  >
    <div class="popup-header">
      <div class="popup-header-cancel" @click="clickFn('cancel')">
        {{ leftTxt }}
      </div>
      <div class="popup-header-title">{{ title }}</div>
      <div
        class="popup-header-confirm"
        native-type="submit"
        @click="clickFn('confirm')"
      >
        {{ rightTxt }}
      </div>
    </div>
    <div class="popup-content">
      <slot name="top"></slot>
      <van-form
        center="true"
        error-message-align="right"
        :show-error="false"
        @submit="onSubmit"
        ref="form"
      >
        <template v-for="(item, index) in formOpts">
          <div v-if="item.topSlot" :key="index + 'slot'">
            <slot :name="item.topSlot"></slot>
          </div>
          <van-field
            :key="index"
            class="textarea"
            v-if="item.formType === 'textarea' && item.show != false"
            :required="item.required"
            clearable
            autosize
            show-word-limit
            :readonly="item.readonly"
            :class="{ 'yiyu-form-readonly': item.readonly }"
            :rows="item.rows || 1"
            type="textarea"
            clear-trigger="always"
            v-model="item.value"
            :name="item.name"
            :label="item.label"
            :placeholder="
              item.placeholder
                ? item.placeholder + '(限' + (item.maxlength || 300) + '字)'
                : ''
            "
            :rules="item.rules ? item.rules : []"
            @input="onInput($event, item.name, item.valueKey)"
            :maxlength="item.maxlength || 300"
          >
            <template #extra v-if="item.extra">
              <div class="extra-txt">{{ item.extra }}</div>
            </template>
          </van-field>
          <van-field
            :key="index"
            v-else-if="item.formType === 'number' && item.show != false"
            :required="item.required"
            clearable
            autosize
            :readonly="item.readonly"
            :class="{ 'yiyu-form-readonly': item.readonly }"
            rows="1"
            type="number"
            clear-trigger="always"
            v-model="item.value"
            :name="item.name"
            :label="item.label"
            :placeholder="item.placeholder"
            :rules="item.rules ? item.rules : []"
            @input="onInput($event, item.name, item.valueKey)"
            maxlength="30"
          >
            <template #extra v-if="item.extra">
              <div class="extra-txt">{{ item.extra }}</div>
            </template>
          </van-field>
          <div
            :key="index"
            v-else-if="item.formType === 'images' && item.show != false"
          ></div>
          <van-field
            :key="index"
            class="radio"
            v-else-if="item.formType === 'radio' && item.show != false"
            name="radio"
            :label="item.label"
            :required="item.required"
            clearable
            autosize
            :readonly="Boolean(item.readonly)"
            :placeholder="item.placeholder"
            :rules="item.rules ? item.rules : []"
          >
            <template #label>
              <!-- <span> -->
              {{ item.label }}
              <!-- </span> -->
              <!--<tip-popover v-if="item.tipSlot" v-model="item.tipSlot">
              </tip-popover> -->
            </template>
            <template #left-icon> </template>
            <template #input>
              <van-radio-group v-model="item.value" direction="horizontal">
                <!-- 支持数组格式 -->
                <template v-if="Array.isArray(item.opts)">
                  <van-radio
                    :name="item1.name"
                    v-for="(item1, index1) in item.opts"
                    :key="index1"
                    @click="onInput(item1.name, item.name, item.valueKey)"
                    >{{ item1.label }}</van-radio
                  >
                </template>
                <!-- 支持对象格式 -->
                <template v-else>
                  <van-radio
                    :name="key1"
                    v-for="(item1, key1, index1) in item.opts"
                    :key="index1"
                    @click="onInput(key1, item.name, item.valueKey)"
                    >{{ item1 }}</van-radio
                  >
                </template>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            :key="index"
            v-else-if="item.formType === 'select' && item.show != false"
            :required="item.required"
            readonly
            :clickable="item.clickable != false"
            is-link
            arrow-direction="down"
            :value="item.value"
            :name="item.name"
            :label="item.label"
            :placeholder="item.placeholder"
            :rules="item.rules"
            @click="item.click"
          >
          </van-field>
          <!-- 既支持输入也支持选择 -->
          <van-field
            :key="index"
            v-else-if="item.formType === 'input-select' && item.show != false"
            :required="item.required"
            v-model="item.value"
            :name="item.name"
            :label="item.label"
            :readonly="Boolean(item.readonly)"
            :class="{ 'yiyu-form-readonly': item.readonly }"
            :placeholder="item.placeholder"
            :rules="item.rules ? item.rules : []"
            :type="item.type"
            datetimeShow
            @input="onInput($event, item.name, item.valueKey)"
            @click-right-icon="item.click"
          >
            <template #label>
              {{ item.label }}
              <tip-popover v-if="item.tipSlot" v-model="item.tipSlot">
              </tip-popover>
            </template>
            <template #right-icon>
              <van-icon name="arrow-down" />
            </template>
          </van-field>
          <van-field
            :key="index"
            v-else-if="item.show != false"
            :required="item.required"
            v-model="item.value"
            :name="item.name"
            :label="item.label"
            :readonly="item.readonly"
            :class="{ 'yiyu-form-readonly': item.readonly }"
            :placeholder="item.placeholder"
            :rules="item.rules ? item.rules : []"
            :type="item.type"
            datetimeShow
            @input="onInput($event, item.name, item.valueKey)"
          >
            <template #extra v-if="item.extra">
              <div class="extra-txt">{{ item.extra }}</div>
            </template>
            <template #extra v-else-if="item.slot">
              <slot :name="item.slot"></slot>
            </template>
          </van-field>
          <div :key="index + 'slot'" v-if="item.bottomSlot">
            <slot :name="item.bottomSlot"></slot>
          </div>
        </template>
      </van-form>
      <slot name="bottom"></slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'formPopup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formOpts: {
      type: Array,
      default: () => [],
    },
    _this: {
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    leftTxt: {
      type: String,
      default: '取消',
    },
    rightTxt: {
      type: String,
      default: '确认',
    },
    formName: {
      type: String,
      default: 'form',
    },
    isPage: {
      // 是否以页面的形式展示
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { showVisible: this.show }
  },
  methods: {
    clickFn(type) {
      switch (type) {
        case 'cancel':
          this.$emit('update:show', false)
          break
        case 'confirm':
          this.$refs.form.submit()
          break
      }
    },
    onInput(val, key) {
      this['_this'][this.formName][key] = val
    },
    onSubmit(values) {
      this.$emit('confirm', values)
    },
  },
}
</script>

<style lang="less" scoped>
.form-popup {
  .popup-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding: 0 1rem;
    height: 64px;
    line-height: 64px;
    &-cancel {
      font-size: 1.1rem;
      color: @titleColor;
    }
    &-title {
      font-size: 20px;
      color: @titleColor;
    }
    &-confirm {
      font-size: 1.1rem;
      color: @mainColor;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .extra-txt {
    margin-left: 16px;
  }
}
</style>
