<template>
  <div class="main">
    <div class="pool-choose" v-myLoad="loading || isLoading">
      <!-- <van-form @submit="onSubmit"> -->
      <van-field
        label="车间"
        :placeholder="ws_names || '请选择车间'"
        readonly
        is-link
        @click="showWsPicker = true"
        arrow-direction="down"
        :required="type == 'farm'"
        :value="ws_names"
        :rules="[{ required: true, message: '请选择车间' }]"
      ></van-field>
      <van-collapse v-model="activeName" accordion>
        <!-- 建议选择育苗池 -->
        <div class="ws" v-for="(item, index) in filterList" :key="index">
          <!-- 车间 -->
          <van-checkbox
            class="ws-checkbox"
            :name="item.ws_key"
            v-model="item.allow_ws_checked"
            v-if="item.allow_p_list && item.allow_p_list.length"
            @click="checkAll(item.allow_ws_checked, 'wsGroup' + index)"
          >
            <span class="ws-name">
              {{ item.ws_name
              }}{{
                item.allow_p_chosed && item.allow_p_chosed.length
                  ? `(已选${item.allow_p_chosed.length}池)`
                  : ``
              }}
            </span>
          </van-checkbox>
          <template v-else>
            <div class="ws-name">{{ item.ws_name }}</div>
            <van-divider>暂无建议选择的育苗池</van-divider>
          </template>

          <van-checkbox-group
            :ref="'wsGroup' + index"
            v-model="item.allow_p_chosed"
            v-if="item.allow_p_list && item.allow_p_list.length"
          >
            <div
              class="warp"
              v-for="(item1, index1) in item.allow_p_list"
              :key="index1"
            >
              <van-collapse-item
                :name="item1[keyName]"
                :value="activeName != item1[keyName] ? '查看详情' : ''"
              >
                <template #title>
                  <van-checkbox
                    :name="item1[keyName]"
                    :key="index1"
                    @click="allowPoolChoose(item1)"
                    >{{ item1.p_name }}</van-checkbox
                  >
                </template>
                <van-field
                  label="池出苗量(万)"
                  type="number"
                  v-model="item1.h_count"
                  placeholder="请输入池出苗量"
                  v-if="
                    item.allow_p_chosed.includes(item1[keyName]) &&
                    type == 'out'
                  "
                >
                </van-field>
                <van-field
                  :label="labelName"
                  type="number"
                  v-model="item1.launch"
                  placeholder="请输入"
                  @change="inputChange(item1.launch)"
                  v-if="
                    item.allow_p_chosed.includes(item1[keyName]) &&
                    input &&
                    type == 'farm'
                  "
                >
                </van-field>
                <van-field
                  :label="labelName"
                  type="number"
                  v-model="item1.count"
                  placeholder="请输入"
                  v-if="
                    item.allow_p_chosed.includes(item1[keyName]) &&
                    input &&
                    (type == 'deal' ||
                      type == 'remove' ||
                      type == 'move-out' ||
                      type == 'move-in')
                  "
                >
                </van-field>
                <van-field
                  name="radio"
                  label="完结状态"
                  v-if="
                    item.allow_p_chosed.includes(item1[keyName]) &&
                    type != 'farm' &&
                    type != 'move-in'
                  "
                >
                  <template #input>
                    <van-radio-group
                      v-model="item1.p_completed"
                      direction="horizontal"
                    >
                      <van-radio :name="true">已完结</van-radio>
                      <van-radio :name="false">未完结</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <van-field
                  readonly
                  clickable
                  name="picker"
                  :value="item1.end_date"
                  label="完结日期"
                  placeholder="请选择完结日期"
                  @click="choseOverDate(item1)"
                  v-if="
                    item.allow_p_chosed.includes(item1[keyName]) &&
                    type != 'farm' &&
                    type != 'move-in' &&
                    type != 'deal' &&
                    type != 'remove' &&
                    type != 'move-out'
                  "
                />
                <data-field
                  :dataObj="getDataObj('allow', item1)"
                  v-if="hasLastData('allow', item1)"
                ></data-field>
                <van-divider
                  v-if="
                    !item.allow_p_chosed.includes(item1[keyName]) &&
                    activeName == item1[keyName] &&
                    !hasLastData('allow', item1)
                  "
                  >暂无数据</van-divider
                >
              </van-collapse-item>
            </div>
          </van-checkbox-group>
        </div>
        <!-- <van-divider v-if="allowNum == 0">暂无建议选择的育苗池</van-divider> -->
        <yiyu-collapse
          :show.sync="show"
          v-if="list && list.length && notAllowNum"
        >
          <template #left>
            <div class="main-c">不建议选择的育苗池</div>
          </template>
          <template #content>
            <!-- 不建议选择育苗池 -->
            <div
              class="ws"
              v-for="(item, index) in filterList"
              :key="index + 'not'"
            >
              <!-- 车间 -->
              <span
                class="ws-name"
                v-if="item.not_allow_p_list && item.not_allow_p_list.length"
              >
                {{ item.ws_name }}
              </span>
              <van-checkbox-group
                v-model="item.not_allow_p_chosed"
                v-if="item.not_allow_p_list && item.not_allow_p_list.length"
              >
                <van-collapse-item
                  :name="item1[keyName]"
                  v-for="(item1, index1) in item.not_allow_p_list"
                  :key="index1"
                  :value="activeName != item1[keyName] ? '查看详情' : ''"
                >
                  <template #title>
                    <van-checkbox
                      :name="item1[keyName]"
                      :key="index1"
                      @click="chosePool(item1, item)"
                      >{{ item1.p_name }}</van-checkbox
                    >
                  </template>
                  <van-field
                    name="radio"
                    label="完结状态"
                    v-if="
                      item.not_allow_p_chosed.includes(item1[keyName]) &&
                      type != 'farm'
                    "
                  >
                    <template #input>
                      <van-radio-group
                        v-model="item1.p_completed"
                        direction="horizontal"
                      >
                        <van-radio :name="true">已完结</van-radio>
                        <van-radio :name="false">未完结</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <van-field
                    :label="labelName"
                    type="number"
                    v-model="item1.launch"
                    placeholder="请输入"
                    v-if="
                      item.not_allow_p_chosed.includes(item1[keyName]) &&
                      input &&
                      type == 'farm'
                    "
                  ></van-field>
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="item1.end_date"
                    label="完结日期"
                    placeholder="请选择完结日期"
                    @click="choseOverDate(item1)"
                    v-if="
                      item.not_allow_p_chosed.includes(item1[keyName]) &&
                      type != 'farm'
                    "
                  />
                  <data-field
                    :dataObj="getDataObj('not_allow', item1)"
                  ></data-field>
                </van-collapse-item>
              </van-checkbox-group>
            </div>
          </template>
        </yiyu-collapse>
      </van-collapse>
      <!-- </van-form> -->
      <van-divider
        v-if="(this.list.length == 0 && type == 'farm') || type == 'out'"
        >暂无育苗池</van-divider
      >
    </div>
    <van-dialog
      class="change-status-dialog"
      v-model="tipShow"
      :show-confirm-button="false"
      :show-cancel-button="false"
      @cancel="tipShow = false"
      :title="tip.title"
    >
      <div class="change-status-dialog-msg">
        {{ tip.msg }}
      </div>
      <div class="footer">
        <div class="change-status-dialog-btn" @click="cancelPoolChose">
          取消
        </div>
        <div class="change-status-dialog-btn" @click="tipShow = false">
          确认
        </div>
      </div>
    </van-dialog>
    <!-- :minDate="overData.minDate" :maxDate="overData.maxDate" -->
    <datetime-popup
      :date.sync="overData.date"
      :show.sync="overData.show"
      title="选择完结日期"
      @confirm="overData.show = false"
      @cancel="overData.show = false"
      type="date"
    >
    </datetime-popup>
    <tag-popup
      title="请选择车间"
      :show.sync="showWsPicker"
      :opts="list"
      value-key="ws_value"
      :cur-keys="ws_keys"
      key-name="ws_key"
      :canAll="true"
      @confirmObj="confirmWs($event)"
      @cancel="showWsPicker = false"
    >
    </tag-popup>
  </div>
</template>

<script>
import tagPopup from '@/components/popup/tag'
import datetimePopup from '@/components/popup/datetime.vue'
import yiyuCollapse from '@/components/seeMore/collapse.vue'
import dataField from '@/components/layout/dataField'
export default {
  name: 'poolChoose',
  props: {
    type: {
      default: 'farm', // out deal remove move-out move-in
    },
    curData: {
      // 选中的值
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
    },
    mcKey: {
      type: String,
    },
    lm_id: {
      type: [String, Number],
    },
    postData: {
      // sync 只是负责接收传给后端的值
      required: true,
    },
    keyName: {
      type: String,
      default: 'p_key',
    },
    input: {
      type: Boolean,
      default: true,
    },
    poolList: {
      // sync 负责接收可选择的育苗池
      require: false,
    },
    batch: {
      // sync 负责接收批次而已
      require: false,
    },
    moreData: {
      // sync 接收更多需要的数据
      require: false,
      type: Object,
    },
    // endType: {// sync 负责接收完结状态而已
    //   require: false
    // }
  },
  components: {
    yiyuCollapse,
    datetimePopup,
    dataField,
    tagPopup,
  },
  data() {
    return {
      tmpData: [], // 记录选中的值
      list: [],
      filterList: [],
      isLoading: false,
      tipShow: false,
      overData: {
        item: undefined,
        minDate: undefined,
        maxDate: undefined,
        show: false,
        date: undefined,
      },
      activeName: undefined,
      show: false,
      curItem: undefined, // 当前操作的item
      curPItem: undefined,
      tip: {
        title: '选择不推荐池提醒',
        msg: '',
      },
      endTypeMap: {
        0: '未知',
        1: '出苗',
        2: '排苗',
        3: '处理苗',
        4: '搬苗',
        5: '手动设置',
      },
      showWsPicker: false,
      ws_keys: [],
      ws_names: undefined,
      initLaunch: undefined,
    }
  },
  watch: {
    date() {
      if (this.type == 'farm') {
        this.getData()
      } else {
        this.list &&
          this.list.forEach((item) => {
            item.allow_p_list &&
              item.allow_p_list.forEach((item1) => {
                if (item.allow_p_chosed.includes(item1[this.keyName])) {
                  item1.end_date = item1.end_date || this.date
                }
              })
            item.not_allow_p_list &&
              item.not_allow_p_list.forEach((item1) => {
                if (item.not_allow_p_chosed.includes(item1[this.keyName])) {
                  item1.end_date = item1.end_date || this.date
                }
              })
          })
      }
    },
    mcKey() {
      if (this.type == 'farm') {
        this.getData()
      }
    },
    lm_id() {
      this.getData()
    },
    initLaunch() {
      this.listDeal()
    },
    list: {
      deep: true,
      handler() {
        this.listDeal()
      },
    },
  },
  mounted() {
    this.getData()
  },
  computed: {
    labelName() {
      switch (this.type) {
        case 'farm':
          return '池投放量(万)'
        case 'deal':
          return '池处理量(万)'
        case 'remove':
          return '池排苗量(万)'
        case 'move-out':
          return '池搬出量(万)'
        case 'move-in':
        default:
          return '池搬入量(万)'
      }
    },
    notAllowNum() {
      let num = 0
      this.filterList.forEach((item) => {
        num += item.not_allow_p_list.length
      })
      return num
    },
    allowNum() {
      let num = 0
      this.filterList.forEach((item) => {
        num += item.allow_p_list.length
      })
      return num
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      if (this.type == 'farm') {
        if (!this.date || !this.mcKey) return
        this.$get('/larva/getPLaunchDetailsV2', {
          date: this.date,
          mc_key: [this.mcKey],
        }).then((res) => {
          if (res.code == 10000) {
            this.isLoading = false
            this.dataDeal(res.data)
          }
        })
      } else {
        if (!this.lm_id) return
        let data = {
          lm_id: this.lm_id,
        }
        if (this.type == 'move-in') {
          data.mc_key = this.mcKey
        }
        this.$get('/larva/getLPLaunchDetails', data).then((res) => {
          if (res.code == 10000) {
            this.$emit('update:batch', res.data.batch)
            this.isLoading = false
            this.dataDeal(res.data)

            let data = res.data
            this.$emit('update:moreData', {
              mc_name: data.mc_name, // "基地名",
              launch: data.launch, // "投放量",
              p_count: data.p_count, // 育苗池数,
              e_date: data.e_date, // "上次估苗时间",
              e_count: data.e_count, // "上次估苗量",
            })
          }
        })
      }
    },
    dataDeal(data) {
      let tmp = {}

      this.ws_keys = []
      let ws_names = []
      if (this.type == 'farm') {
        this.curData &&
          this.curData.forEach((item) => {
            let keys = []
            item['list'] &&
              item['list'].forEach((item1) => {
                keys.push(item1[this.keyName])
              })
            tmp[item['ws_key']] = keys
            this.ws_keys.push(item.ws_key)
            ws_names.push(item.ws_name)
          })

        data.list.forEach((item) => {
          item.allow_p_chosed = [] // 选中的育苗池
          item.not_allow_p_chosed = tmp[item.ws_key] || [] // 选中的育苗池
          item.allow_p_list = item.allow_p_list || []
          item.not_allow_p_list = item.not_allow_p_list || []

          item.allow_p_list.forEach((item1) => {
            item1.end_date_back = item1.end_date
            item1.launch = undefined
          })

          item.not_allow_p_list.forEach((item1) => {
            item1.end_date_back = item1.end_date
            item1.launch = undefined
          })

          item.ws_value = item.ws_name + '(' + item.allow_p_list.length + ')'
        })
      } else if (
        this.type == 'out' ||
        this.type == 'deal' ||
        this.type == 'remove' ||
        this.type == 'move-out' ||
        this.type == 'move-in'
      ) {
        let keys = []

        this.curData &&
          this.curData.forEach((item) => {
            keys.push(item[this.keyName])
            tmp[item[this.keyName]] = item
          })

        data.list.forEach((item) => {
          item.allow_p_chosed = [] // 选中的育苗池
          item.not_allow_p_chosed = tmp[item.ws_key] || [] // 选中的育苗池
          item.allow_p_list = item.allow_p_list || []
          item.not_allow_p_list = item.not_allow_p_list || []
          item.allow_p_list.forEach((item1) => {
            let key = item1[this.keyName]
            if (keys.includes(key)) {
              item.allow_p_chosed.push(key)
              item1.h_count = tmp[key]['h_count'] // 出苗量
              item1.launch = tmp[key]['launch']
              item1.count = tmp[key]['count'] // 处理量
              item1.p_completed = Boolean(tmp[key]['p_completed']) // 育苗池已完结
              item1.end_date_back = tmp[key]['end_date']
              item1.end_date =
                tmp[key]['end_date'] && tmp[key]['end_date'].slice(0, 10)
            } else {
              item1.h_count = undefined // 出苗量
              item1.count = undefined // 处理量
              item1.launch = undefined
              item1.p_completed = true // 育苗池已完结
              item1.end_date_back = item1.end_date
              item1.end_date = this.date && this.date.slice(0, 10)
            }
          })

          item.not_allow_p_list.forEach((item1) => {
            let key = item1[this.keyName]
            if (keys.includes(key)) {
              item.not_allow_p_chosed.push(key)
              item1.h_count = tmp[key]['h_count'] // 出苗量
              item1.launch = tmp[key]['launch']
              item1.count = tmp[key]['count'] // 处理量
              item1.p_completed = Boolean(tmp[key]['p_completed']) // 育苗池已完结
              item1.end_date_back = tmp[key]['end_date']
              item1.end_date =
                tmp[key]['end_date'] && tmp[key]['end_date'].slice(0, 10)
            } else {
              item1.h_count = undefined // 出苗量
              item1.count = undefined // 处理量
              item1.launch = undefined
              item1.p_completed = true // 育苗池已完结
              item1.end_date_back = item1.end_date
              item1.end_date = this.date && this.date.slice(0, 10)
            }
          })

          item.es_launch = item.launch

          item.ws_value =
            item.ws_name +
            '(' +
            (item.allow_p_list.length + item.not_allow_p_list.length) +
            ')'

          this.ws_keys.push(item.ws_key)
          ws_names.push(item.ws_name)
        })
      }
      this.list = data.list
      this.$emit('update:poolList', this.list)
      this.filterList = this.list.filter((item) =>
        this.ws_keys.includes(item.ws_key),
      )
      this.ws_names = ws_names.join('、')
      this.judgeWsAll()
    },
    getDataObj(type, item) {
      switch (type) {
        case 'allow':
          if (this.type == 'farm' || this.type == 'move-in') {
            return [
              [
                {
                  data: item.batch,
                  descText: '上次批次',
                },
                {
                  data: item.es_launch,
                  unitText: '万',
                  descText: '上次投放',
                },
                {
                  data: item.end_date_back && item.end_date_back.slice(2, 10),
                  descText: '上次完结',
                },
              ],
            ]
          } else if (
            this.type == 'move-out' ||
            this.type == 'remove' ||
            this.type == 'deal'
          ) {
            return [
              [
                {
                  data: item.launch,
                  descText: '投放',
                },
                {
                  data: item.e_count,
                  unitText: '万',
                  descText: '估苗',
                },
                {
                  data: item.t_date && item.t_date.slice(2, 10),
                  descText: '最新质检',
                },
              ],
            ]
          }
          break
        case 'not_allow': {
          // if (this.type == 'farm') {
          if (item.end_date_back) {
            return [
              [
                {
                  data: item.batch,
                  descText: '批次',
                },
                {
                  data: item.launch,
                  unitText: '万',
                  descText: '投放',
                },
                {
                  data: item.end_date_back && item.end_date_back.slice(2, 10),
                  descText: '完结日期',
                },
              ],
            ]
          } else {
            return [
              [
                {
                  data: item.batch,
                  descText: '批次',
                },
                {
                  data: item.launch,
                  unitText: '万',
                  descText: '投放',
                },

                {
                  data: item.h_date && item.h_date.slice(2, 10),
                  descText: '预估出苗',
                  show: this.type == 'farm' || this.type == 'move-in',
                },
                {
                  data: item.e_h_date && item.e_h_date.slice(2, 10),
                  descText: '预估出苗',
                  show: this.type != 'farm' && this.type != 'move-in',
                },
              ],
            ]
          }
        }
      }
    },
    hasLastData(type, item) {
      switch (type) {
        case 'allow':
          switch (this.type) {
            case 'farm':
            case 'move-in':
              return item.batch || item.es_launch || item.end_date
            case 'move-out':
            case 'deal':
            case 'remove':
              return item.es_launch || item.e_count || item.t_date
          }
      }
    },
    choseOverDate(item) {
      this.overData.item = item
      this.overData.date = item.end_date
      this.overData.show = true
    },
    checkAll(val, refName) {
      this.$refs[refName][0].toggleAll(val)
    },
    chosePool(item, pItem) {
      this.activeName = item[this.keyName]
      if (pItem.not_allow_p_chosed.includes(item[this.keyName])) {
        this.curPItem = pItem
        this.curItem = item
        if (this.type == 'farm') {
          this.tip.msg = `当前育苗池已与批次
          ${this.curItem && this.curItem.batch}
        进行关联，是否确认选择该池？`
        } else {
          if (item.end_date_back) {
            this.tip.msg = `${this.curItem.ws_name}_${
              this.curItem.p_name
            }育苗批次${this.curItem.batch}已在${item.end_date_back.slice(
              0,
              10,
            )}时${this.endTypeMap[item.end_type]}操作完结，是否确定选择该池？`
          } else {
            this.tip.msg = `当前育苗池已与批次
          ${this.curItem && this.curItem.batch}
        进行关联，是否确认选择该池？`
          }
        }
        this.tipShow = true
      }
      this.judgeWsAll()
    },
    cancelPoolChose() {
      let index = this.curPItem.not_allow_p_chosed.indexOf(
        this.curItem[this.keyName],
      )
      this.curPItem.not_allow_p_chosed.splice(index, 1)
      this.tipShow = false
      this.judgeWsAll()
    },
    judgeWsAll() {
      this.list &&
        this.list.forEach((item) => {
          if (
            item.allow_p_chosed &&
            item.allow_p_list &&
            item.allow_p_chosed.length == item.allow_p_list.length
          ) {
            item.allow_ws_checked = true
          } else {
            item.allow_ws_checked = false
          }
          if (
            item.not_allow_p_chosed &&
            item.not_allow_p_list &&
            item.not_allow_p_chosed.length == item.not_allow_p_list.length
          ) {
            item.not_allow_ws_checked = true
          } else {
            item.not_allow_ws_checked = false
          }
        })
    },
    allowPoolChoose(item) {
      this.activeName = item[this.keyName]
      this.judgeWsAll()
    },
    confirmWs(e) {
      let key = []
      let value = []
      e.forEach((item) => {
        key.push(item.ws_key)
        value.push(item.ws_name)
      })

      this.ws_keys = key
      this.ws_names = value.join('、')
      this.filterList = this.list.filter((item) =>
        this.ws_keys.includes(item.ws_key),
      )
      this.showWsPicker = false
    },
    inputChange(val) {
      if (!this.initLaunch) {
        this.initLaunch = val
      }
    },
    listDeal() {
      let tmp = []
      this.list &&
        this.list.forEach((item) => {
          item.allow_p_list &&
            item.allow_p_list.forEach((item1) => {
              if (item.allow_p_chosed.includes(item1[this.keyName])) {
                item1.end_date = item1.end_date || this.date
                if (this.type == 'farm') {
                  if (item1.launch == undefined) {
                    item1.launch = this.initLaunch
                  }
                }
                tmp.push(item1)
              }
            })
          item.not_allow_p_list &&
            item.not_allow_p_list.forEach((item1) => {
              if (item.not_allow_p_chosed.includes(item1[this.keyName])) {
                item1.end_date = item1.end_date || this.date
                tmp.push(item1)
              }
            })
        })

      this.$emit('update:postData', tmp)
      this.$emit('change')
    },
  },
}
</script>

<style lang="less" scoped>
.pool-choose {
  position: relative;

  .ws {
    .ws-name {
      margin: 0.5rem;
      color: @textColor;
      font-weight: 600;
      font-size: 1rem;
    }
    .ws-checkbox {
      margin: 0.5rem;

      .ws-name {
        margin: 0;
      }
    }
  }

  /deep/.yiyu-collapse-top {
    padding: 1rem;
  }
}

.change-status-dialog.van-dialog {
  padding: 1rem;

  /deep/.van-dialog__header {
    padding-top: 0;
  }

  .change-status-dialog-msg {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .change-status-dialog-btn {
    padding: 0.5rem 1rem;
    border: 1px solid @mainColor;
    text-align: center;
  }
}
</style>
