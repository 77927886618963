wz
<template>
  <div>
    <yy-navbar tab-title="藻类需求" />
    <YFilter
      :data="filterData"
      @search="handleFilterSearch"
      @reset="handleFilterReset"
    >
      <YFilterItemCalendar
        slot="时间"
        type="range"
        :default-date="[$dayjs().add(1, 'day').$d, $dayjs().add(10, 'day').$d]"
      />
    </YFilter>
    <empty v-if="requirementList.length == 0" />
    <van-list v-else v-model="loading" @load="loadMore" :finished="finished">
      <div class="yiyu-list">
        <div
          v-for="(requirement, idx) in requirementList"
          :key="idx"
          class="list-item"
        >
          <div class="list-item-top">
            <div class="left">
              <div class="name">
                {{ requirement.reque_day | checkEmpty }}({{
                  requirement.tag == 1 ? '上午' : '下午'
                }})
              </div>
            </div>
            <div class="right">
              <van-icon
                name="edit"
                :color="$mainColor"
                size="1.5rem"
                @click="eidtRequirement(requirement, idx)"
              />
            </div>
          </div>
          <grid-box :value="gridBoxData(requirement)" />
        </div>
      </div>
    </van-list>

    <drag-btn @add="$push({}, { path: 'larvaRequirementEdit' })" />

    <van-dialog
      v-model="dialog.show"
      title="修改需求量"
      show-cancel-button
      cancel-button-text="删除"
      @confirm="handleDialogConfirm"
      @cancel="handleDialogCancel"
      :closeOnClickOverlay="true"
    >
      <div class="py-2">
        <van-field
          v-model="dialog.distributeEdit"
          label="需求量"
          placeholder="请填写需求量"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import DragBtn from '@/components/button/dragBtn.vue'
import Empty from '@/components/layout/empty.vue'
import checkEmpty from '@/filters/checkEmpty'
import GridBox from '@/components/layout/gridBox.vue'
import { algaListItemWordMap } from '@/views/alga/algaMixin'
import { List, Toast } from 'vant'
import { YFilter, YFilterItemCalendar } from 'yiyu-vant-ui'

export default {
  components: {
    DragBtn,
    Empty,
    GridBox,
    [List.name]: List,
    YFilter,
    YFilterItemCalendar,
  },
  filters: {
    checkEmpty,
  },
  data() {
    return {
      filterData: [
        {
          label: '时间',
          keyName: ['start_date', 'end_date'],
        },
      ],
      filterParams: {},
      requirementList: [],
      wordMap: algaListItemWordMap.distribute,
      dialog: {
        show: false,
        rr_key: '',
        distributeEdit: '',
      },
      distributionCard: [
        {
          field: 'quantity',
          unit: 'L',
        },
        {
          field: 'expecting_distribute',
          unit: 'L',
        },
        {
          field: 'total_distribute',
          unit: 'L',
        },
        {
          field: 'mc_name',
        },
      ],
      loading: false,
      finished: false,
      page: 1,
      pageSize: 10,
      start_day: this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      end_day: this.$dayjs().add(10, 'day').format('YYYY-MM-DD'),
    }
  },
  methods: {
    handleFilterSearch(e) {
      this.filterParams = e
      this.page = 1
      this.getData(e).then(({ data }) => {
        this.requirementList = data.list
      })
    },
    handleFilterReset() {
      // 解除筛选
      this.start_day = ''
      this.end_day = ''
      this.getData().then(({ data }) => {
        this.requirementList = data.list
        this.total = data.total
        this.finished = false
      })
    },
    loadMore() {
      this.getData({
        page: ++this.page,
        count: this.pageSize,
        ...this.filterParams,
      })
        .then(({ data }) => {
          this.requirementList.push(...data.list)
          if (this.requirementList.length >= data.total) this.finished = true
          return data
        })
        .finally(() => {
          this.loading = false
        })
    },
    getData(params) {
      return this.$get('/request/getRequestList', {
        start_day: this.start_day,
        end_day: this.end_day,
        page: this.page,
        count: this.pageSize,
        ...params,
      })
    },
    eidtRequirement(requirement, idx) {
      this.dialog = {
        show: true,
        distributeEdit: requirement.quantity,
        rr_key: requirement.rr_key,
        idx,
      }
    },
    handleDialogConfirm() {
      this.$post('/request/editRequestOne', {
        rr_key: this.dialog.rr_key,
        quantity: this.dialog.distributeEdit,
      })
        .then(({ data }) =>
          this.$get('/request/getRequestOne', { rr_key: data.rr_key }),
        )
        .then(({ data }) => {
          if (typeof this.dialog.idx !== 'undefined')
            this.requirementList.splice(this.dialog.idx, 1, data)
        })
    },
    handleDialogCancel() {
      const { rr_key, idx } = this.dialog
      this.$post('/request/delRequest', {
        rr_key,
      }).then(({ code }) => {
        if (code == 10000) {
          Toast('删除成功')
          this.requirementList.splice(idx, 1)
        } else {
          Toast('请稍后再试')
        }
      })
    },
  },
  computed: {
    gridBoxData({ wordMap, distributionCard }) {
      return (requirement) =>
        distributionCard.map(({ field, unit }) => {
          let obj = {
            label: wordMap[field],
            value: requirement[field],
            unit,
          }
          if (field == 'expecting_distribute') {
            obj.value =
              Number(requirement['quantity']) -
              Number(requirement['total_distribute'])
          }
          return obj
        })
    },
  },
  created() {
    this.getData().then(({ data }) => {
      this.total = data.total
      this.requirementList = data?.list ?? []
    })
  },
}
</script>
