<template>
  <classify-tag-popup
    :show.sync="show"
    :title="title"
    :list="opts"
    pLabel="roleName"
    pChild="children"
    cValue="nickname"
    :cKey="keyName"
    @confirm="confirmPicker($event)"
    :single="single"
    :pCurKey.sync="curKey"
    :loading="isLoading"
    @closed="$emit('closed')"
  >
    <template #bottom>
      <div class="classify-tag-popup-bottom" v-if="IS_PAGE('memberInvite')">
        <span class="btn main-c" @click="toInvite">去邀请</span>
      </div>
    </template>
  </classify-tag-popup>
</template>

<script>
import classifyTagPopup from '@/components/popup/classifyTag.vue'
import {
  classifyData,
  compareArr,
  deepCopy,
  getKeyArr,
  getKeyObj,
} from '@/utils/utils'
export default {
  name: 'personPicker',
  props: {
    // 外界有多处调用 ref.show, 无法对 show 进行更改
    // isShow: {
    //   type: Boolean,
    // },
    curKey: {
      // 取的keyName
      // sync
      // required: true,
      type: [String, Number, Array], // 多选的时候才是数组
    },
    curName: {
      // sync
      type: String,
    },
    title: {
      type: String,
      default: '选择人员',
    },
    role: {
      type: Array,
    },
    single: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: 'uid', // user_key
    },
    removeKeys: {
      // 要移除的人，比如通知的时候不能通知自己
      type: Array,
      default: () => [],
    },
    defaultChosedRole: {
      // 默认全选中的角色
      type: Array,
      default: () => [],
    },
  },
  components: {
    classifyTagPopup,
  },
  data() {
    return {
      show: false,
      isLoading: false,
      list: [],
      firstFlag: true,
      initcurKey: undefined,
      initCurName: undefined,
    }
  },
  activated() {
    this.getData()
  },
  watch: {
    curKey() {
      if (this.firstFlag) {
        this.firstFlag = false
        this.initCurName = this.curName
        if (this.curKey) {
          this.initcurKey = JSON.parse(JSON.stringify(this.curKey))
        }
      }

      this.getCurName()
    },
  },
  computed: {
    opts() {
      this.list.forEach((item) => {
        item.roleName = this.$store.state.basic.roleObj[item.role]
      })
      return classifyData(this.list, 'role', 'roleName', 'arr')
    },
    allKeys() {
      return getKeyArr(this.list, this.keyName)
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/member/getMember', {
        role: this.role,
        inv_situation: 'complete',
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data.list
          this.removeKeysDeal()
          this.defaultChosedRoleDeal()
          this.getCurName()
        }
      })
    },
    defaultChosedRoleDeal() {
      if (Array.isArray(this.curKey)) {
        let curKey = deepCopy(this.curKey)

        this.list.forEach((item) => {
          if (
            this.defaultChosedRole.includes(String(item.role)) ||
            this.defaultChosedRole.includes(Number(item.role))
          ) {
            curKey.push(item[this.keyName])
          }
        })

        curKey = [...new Set(curKey)]
        curKey = curKey.filter((item) => !this.removeKeys.includes(item))
        this.$emit('update:curKey', curKey)
      }
    },
    removeKeysDeal() {
      if (Array.isArray(this.curKey)) {
        this.list = this.list.filter(
          (item) => !this.removeKeys.includes(item[this.keyName]),
        )
        let curKey = deepCopy(this.curKey)
        curKey = curKey.filter((item) => !this.removeKeys.includes(item))
        this.$emit('update:curKey', curKey)
      }
    },
    confirmPicker(e) {
      if (Array.isArray(e.key)) {
        e.key = e.key.filter((item) => this.allKeys.includes(item))
      } else {
        if (!this.allKeys.includes(e.key)) {
          e.key = undefined
        }
      }
      this.$emit('update:curKey', e.key)
      this.$emit('confirm', e)
      this.show = false
    },
    toInvite() {
      this.show = false
      this.$push(this, { path: '/memberInvite' })
    },
    getCurName() {
      let curName = undefined
      if (!this.single) {
        var tmp = []
        if (Array.isArray(this.curKey)) {
          this.curKey.forEach((item) => {
            tmp.push(getKeyObj(item, this.list, this.keyName)['nickname'])
          })
        }
        curName = tmp.join('、')
      } else {
        if (this.curKey) {
          curName = getKeyObj(this.curKey, this.list, this.keyName)['nickname']
        } else {
          curName = undefined
        }
      }

      if (Array.isArray(this.curKey)) {
        if (compareArr(this.curKey, this.initcurKey)) {
          curName = this.initCurName || curName
        }
      } else if (this.curKey == this.initcurKey) {
        curName = this.initCurName || curName
      }
      this.$emit('update:curName', curName)
    },
  },
}
</script>

<style></style>
