<template>
  <div class="main">
    <yiyu-navbar tab-title="出苗数据记录"></yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <div class="row-between">
        <div class="title">出苗数据记录</div>
        <div class="main-c" @click="toPage('edit', form)">编辑</div>
      </div>
      <yiyu-detail
        :formOpts="formOpts"
        labelWidth="90px"
        v-myLoad="isLoading"
        style="position: relative"
      >
        <template #order>
          <data-field :dataObj="getDataObj('order', form)"></data-field>
        </template>
        <template #outData>
          <data-field :dataObj="getDataObj('out', form)"></data-field>
          <div class="single-ele-table">
            <el-table
              :header-cell-class-name="headerCellClassName"
              :cell-class-name="cellClassName"
              :row-class-name="rowClassName"
              :cell-style="{ color: '#666666' }"
              :data="form.h_info"
              size="small"
              header-row-class-name="tableHead"
            >
              <el-table-column
                v-for="(value, index) in orderHeader"
                :key="index"
                :prop="value.prop"
                :label="value.label"
                :min-width="columnWidth(value.prop)"
                align="center"
              >
                <template slot-scope="scope">
                  <template v-if="value.prop == 'p_name'">
                    {{ scope.row['ws_name'] }}_{{ scope.row[value.prop] }}
                  </template>
                  <template v-else-if="value.prop == 'p_completed'">
                    {{ scope.row['p_completed'] ? '完结' : '未完结' }}
                  </template>
                  <template v-else>{{ scope.row[value.prop] }}</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <template #count>
          <div class="single-ele-table">
            <el-table
              :header-cell-class-name="headerCellClassName"
              :cell-class-name="cellClassName"
              :row-class-name="rowClassName"
              :cell-style="{ color: '#666666' }"
              :data="countData"
              size="small"
              header-row-class-name="tableHead"
            >
              <el-table-column
                v-for="(value, index) in countHeader"
                :key="index"
                :prop="value.prop"
                :label="value.label"
                :min-width="columnWidth(value.prop)"
                align="center"
              >
                <template slot-scope="scope">
                  <template>{{ scope.row[value.prop] }}</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </yiyu-detail>
    </div>
  </div>
</template>

<script>
import dataField from '@/components/layout/dataField'
import yiyuNavbar from '@/components/navbar'
import yiyuDetail from '@/components/detail'
export default {
  name: 'outDetail',
  components: {
    yiyuNavbar,
    dataField,
    yiyuDetail,
  },
  data() {
    return {
      lo_key: undefined,
      form: {},
      isLoading: false,
      orderHeader: [
        {
          label: '育苗池',
          prop: 'p_name',
        },
        {
          label: '出苗量(万)',
          prop: 'h_count',
        },
        {
          label: '完结状态',
          prop: 'p_completed',
        },
      ],
      countHeader: [
        {
          label: '次数',
          prop: 'order',
        },
        {
          label: '数量',
          prop: 'count',
        },
      ],
    }
  },
  computed: {
    formOpts() {
      return [
        {
          title: '订单信息',
          topSlot: 'order',
        },
        {
          title: '出苗信息',
          topSlot: 'outData',
          child: [
            {
              label: '备注',
              val: this.form.remark,
            },
          ],
        },
        {
          title: '点数信息',
          topSlot: 'count',
        },
      ]
    },
    countData() {
      if (Array.isArray(this.form.dm_count)) {
        let num = 0
        let list = []
        this.form.dm_count.forEach((item, index) => {
          num += Number(item)
          list.push({
            order: index + 1,
            count: item,
          })
        })
        list.push({
          order: '合计',
          count: num,
        })
        return list
      } else {
        return []
      }
    },
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.form.lo_key = query.lo_key
      this.form.lm_id = query.lm_id
      this.getData()
    }
  },
  methods: {
    getDataObj(type, item) {
      switch (type) {
        case 'order':
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: '关联量',
              },
              {
                data: item.box,
                unitText: '箱',
                descText: '箱数',
              },
              {
                data: item.density,
                unitText: '万/箱',
                descText: '打包密度',
              },
            ],
            [
              {
                data:
                  item.ice == 0 ? '不需要' : item.ice == 1 ? '需要' : '暂无',
                descText: '冰瓶',
              },
              {
                data: item.salinity,
                unitText: '格',
                descText: '盐度',
              },
              {
                data: item.temperature,
                unitText: '℃',
                descText: '温度',
              },
              {
                data: item.bag_name || '暂无',
                descText: '印刷袋',
              },
            ],
          ]
        case 'out':
          return [
            [
              {
                data: item.h_count,
                unitText: '万',
                descText: '出苗量',
              },
              {
                data: item.box,
                unitText: '箱',
                descText: '箱数',
              },
              {
                data: item.real_count,
                unitText: '万',
                descText: '实结苗量',
              },
            ],
            [
              {
                data: item.pack_worker,
                unitText: '人',
                descText: '打包人数',
              },
              {
                data: item.h_date && item.h_date.slice(2, 16),
                descText: '出苗时间',
              },
            ],
          ]
      }
    },
    getData() {
      this.isLoading = true
      this.$get('/harvest/getHarvest', {
        lo_key: this.form.lo_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          this.form = data
        }
      })
    },
    toPage(type, item) {
      switch (type) {
        case 'edit':
          this.$push(this, {
            name: 'outEdit',
            query: {
              lo_key: item.lo_key,
              lm_id: item.lm_id,
            },
          })
          break
      }
    },
    columnWidth(val) {
      switch (val) {
        case 'breeding':
        case 'or_count':
        case 'p_count':
        case 'd_count':
          return 80

        case 'c_count':
          return 100
        case 'type_name':
        case 's_trip_cost':
          return 120
        case 'es_l_count':
        case 'first_cell_name':
        case 'sup_name':
        case 're_count':
          return 140
        case 'es_count':
        case 'h_count':
          return 160
        case 'launch_date':
        case 'e_date':
          return 240
      }
      return undefined
    },
    headerCellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return 'all-table-header-bgc first-header-cell'
      } else {
        return 'all-table-header-bgc'
      }
    },

    cellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return `first-cell`
      } else {
        return 'last-cell'
      }
    },

    rowClassName({ rowIndex }) {
      if (rowIndex % 2) {
        return 'table-utils even-row'
      } else {
        return 'table-utils'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  width: 100vw;
  overflow-x: hidden;
  .main-content {
    .title {
      font-weight: bold;
      font-size: 16px;
      color: @titleColor;
    }
    .row-between {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .single-ele-table {
      max-width: 100vw;
      padding: 0.5rem;
      box-sizing: border-box;

      /deep/.table-utils {
        height: 40px;
      }
    }
  }
}
</style>
