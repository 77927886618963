<template>
  <div class="important-event">
    <yiyu-navbar tab-title="重要事项"></yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
      v-myLoad="isLoading"
      style="position: relative"
    >
      <template #footer>
        <fixed-btn btnTxt="保存" @click="toSubmit"></fixed-btn>
      </template>
    </yiyu-form>

    <yiyu-picker
      :columns="nodeOpts"
      valueKey="name"
      keyName="n_key"
      v-if="showNodePicker"
      :show="showNodePicker"
      :curKeys="form.node"
      @confirm="confirmPicker($event, 'node')"
      @cancel="cancelPicker('node')"
      :loading="nodeLoading"
    >
      <template #empty>
        <div class="picker-empty">
          <span class="btn" @click="toAdd">去新增结点</span>
        </div>
      </template>
    </yiyu-picker>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn'
import yiyuPicker from '@/components/popup/picker'
import { getKeyValue } from '@/utils/utils'
import yiyuNavbar from '@/components/navbar'
import yiyuForm from '@/components/form.vue'
export default {
  name: 'larvaOut',
  components: {
    yiyuNavbar,
    yiyuForm,
    yiyuPicker,
    fixedBtn,
  },
  data() {
    return {
      nodeLoading: true,
      isLoading: false,
      thisAlias: this,
      showNodePicker: false,
      nodeOpts: [],
      form: {
        node: undefined,
        nodeName: undefined,
        matter: undefined,
      },
      key: undefined,
      larvaKey: undefined,
      farmKey: undefined,
    }
  },
  computed: {
    formOpts() {
      let _this = this
      return [
        {
          title: '',
          child: [
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.nodeName,
              name: 'nodeName',
              label: '结点',
              placeholder: '请选择结点',
              click() {
                _this.showNodePicker = true
              },
              rules: [{ required: false, message: '请选择结点' }],
            },
            {
              required: false,
              colon: true,
              formType: 'textarea', // 默认
              value: this.form.matter || undefined,
              name: 'matter',
              label: '事项',
              placeholder: '请输入事项',
              rules: [{ required: false, message: '请输入事项' }],
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getNode()
    if (this.$route.query) {
      let query = this.$route.query
      this.larvaKey = query.larvaKey
      this.farmKey = query.farmKey
      this.key = query.key
      if (this.key) {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/matter/getMatter', {
        ma_key: this.key,
      }).then((res) => {
        if (res.code === 10000) {
          this.isLoading = false
          res.data.data.forEach((item) => {
            if (item.title == '事项') {
              this.form.matter = item.value
            } else if (item.title == '结点') {
              this.form.node = item.value
              if (this.nodeOpts.length) {
                this.form.nodeName = getKeyValue(
                  this.form.node,
                  this.nodeOpts,
                  'name',
                  'n_key',
                )
              }
            }
          })
        }
      })
    },
    getNode() {
      this.nodeLoading = true
      this.$get('/node/getNodeList', {}).then((res) => {
        if (res.code == 10000) {
          this.nodeLoading = false
          this.nodeOpts = res.data.list
          if (this.form.node) {
            this.form.nodeName = getKeyValue(
              this.form.node,
              this.nodeOpts,
              'name',
              'n_key',
            )
          }
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    onSubmit() {
      this.$post('/matter/addMatter', {
        data: [
          {
            value: this.form.node,
            title: '结点',
          },
          {
            value: this.form.matter,
            title: '事项',
          },
        ],
        type: '1',
        ma_key: this.key,
        l_key: this.larvaKey,
        mc_key: this.farmKey,
      }).then((res) => {
        if (res.code == 10000) {
          if (this.key) {
            this.$toast.success('修改重要事项成功！')
          } else {
            this.$toast.success('新增重要事项成功！')
          }
          this.$go(-1, this)
        }
      })
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'node':
          this.form.node = e[0]['n_key']
          this.form.nodeName = e[0]['name']
          this.showNodePicker = false
          break
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'node':
          this.showNodePicker = false
          break
      }
    },
    toAdd() {
      this.$push(this, { path: '/nodeSet' })
    },
  },
}
</script>

<style lang="less" scoped></style>
