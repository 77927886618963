import { isNull, omit, omitBy } from 'lodash'
/**
 * 当有需要过滤的参数时, 可以使用该方法
 * @param {object} formObject 整个form对象
 * @param {string|Array<string>} exclude
 * @returns {object}参数对象
 */
export const genParameters = (formObject, exclude) => {
  if (typeof exclude === 'string') exclude = [exclude]

  let keys = Object.keys(formObject)

  keys.forEach((key) => {
    exclude.forEach((str) => {
      if (key.includes(str) && exclude.indexOf(key) === -1) exclude.push(key)
    })
  })

  return omitBy(omit(formObject, exclude), (item) => isNull(item))
}

/**
 * @typedef { import("./form").formOption } formOption
 * @param { formOption[] } options
 */
export const createFormOptions = (options) => options
