<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <div
      class="main-content"
      v-myLoad="isLoading"
      v-if="form.lm_id || form.ld_key"
    >
      <section class="info">
        <div class="title">{{ form.batch }}-{{ moreData.mc_name }}</div>
        <data-field :dataObj="getDataObj(moreData)"></data-field>
      </section>
      <section class="form">
        <van-checkbox v-model="isOnlyAll" shape="square"
          >默认只填总处理量</van-checkbox
        >
        <yiyu-form
          :_this="thisAlias"
          :formOpts="formOpts"
          @submit="onSubmit"
          ref="form"
        >
        </yiyu-form>
      </section>

      <section class="pool-chose" v-show="pool_list.length">
        <van-checkbox v-model="isPool" shape="square">精准到池</van-checkbox>
        <div class="title" v-if="isPool != false">
          <span>处理池选择</span>
          <tip-popover
            >选择需要处理的育苗池，育苗池处理后，育苗池将置为已完结</tip-popover
          >
        </div>
        <pool-choose
          v-show="isPool != false"
          :post-data.sync="form.d_info"
          type="deal"
          :date="form.d_date"
          :lm_id="form.lm_id"
          keyName="lp_id"
          :curData="form.d_info"
          :input="!isOnlyAll"
          :poolList.sync="pool_list"
          :batch.sync="form.batch"
          :moreData.sync="moreData"
        ></pool-choose>
      </section>

      <fixed-btn
        :btnTxt="status == 'add' ? '确认新增' : '保存'"
        @click="toSubmit"
        :loading="btnLoading"
        :disabled="btnLoading"
      ></fixed-btn>
    </div>

    <!-- <yiyu-empty v-if="pool_list.length == 0"
      >暂无可操作的育苗池，
      <span class="main-c" @click="addBatch">去添加</span>
    </yiyu-empty> -->

    <datetime-popup
      :date.sync="form.d_date"
      :show.sync="showRemoveDatePicker"
      title="选择处理日期"
      @confirm="showRemoveDatePicker = false"
      @cancel="showRemoveDatePicker = false"
      type="date"
    >
    </datetime-popup>

    <farm-batch-picker
      ref="farmBatchPicker"
      v-if="farmKey"
      :farm-key="farmKey"
      @confirm="confirmPicker($event, 'batch')"
    ></farm-batch-picker>

    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="farmKey"
        :single="true"
        @confirm="choseBatch"
        :cancel-show="false"
      ></farm-picker>
    </keep-alive>

    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            @click="toPage('notice')"
          >
            发布通知
          </van-button>
          <van-button
            class="success-popup-handle-btn"
            type="primary"
            @click="toPage('last')"
          >
            返回
          </van-button>
        </div>
      </template>
    </success-popup>
  </div>
</template>

<script>
import successPopup from '@/components/popup/success'

import poolChoose from '@/bComponents/poolChoose.vue'
import farmPicker from '@/bComponents/farmPicker.vue'
import farmBatchPicker from '@/bComponents/farmBatchPicker.vue'
import fixedBtn from '@/components/button/fixedBtn'
import tipPopover from '@/components/feedback/tipPopover.vue'
import { numToStr, getRate } from '@/utils/utils'
import yiyuNavbar from '@/components/navbar'
import yiyuForm from '@/components/form.vue'
import datetimePopup from '@/components/popup/datetime.vue'
import dataField from '@/components/layout/dataField'
export default {
  name: 'estomateEdit',
  components: {
    yiyuNavbar,
    yiyuForm,
    datetimePopup,
    tipPopover,
    fixedBtn,
    farmBatchPicker,
    farmPicker,
    poolChoose,
    successPopup,
    dataField,
  },
  data() {
    return {
      status: 'add',
      isLoading: false,
      thisAlias: this,
      form: {
        lm_end: '1',
        batch: undefined,
        ld_key: undefined,
        lm_id: undefined,
        remark: undefined, // "备注",
        count: undefined, // "处理苗总量",
        d_date: undefined, // "处理苗时间",
        unit_price: undefined, // "单价",
        sum_price: undefined, // "金额",
        type: '2', // 1 分池估苗，2 不分池估苗
        d_info: [], // {lp_id, count, p_completed}
      },
      moreData: {
        mc_name: undefined, // "基地名",
        launch: undefined, // "投放量",
        p_count: undefined, // 育苗池数,
        e_date: undefined, // "上次估苗时间",
        e_count: undefined, // "上次估苗量",
      },
      showRemoveDatePicker: false,
      btnLoading: false,
      h_chosed: [],
      pool_list: [],
      isOnlyAll: true, // 默认只填总排苗量
      allData: {
        launch: undefined,
        e_count: undefined,
        h_count: undefined,
        d_count: undefined,
        c_count: undefined,
      },
      poolForm: {},
      pool: {},
      curKeys: [], // 不分池情况下默认选择完结的池
      tipShow: false,
      farmKey: undefined,
      isPool: false,
      addSuccessShow: false,
    }
  },
  computed: {
    tabTitle() {
      if (this.status == 'edit') return '编辑处理'
      else return '新增处理'
    },
    addHeader() {
      return {
        title: this.status == 'add' ? '新建成功' : '修改成功',
        left: {
          txt: '',
          fn: 'cancel',
        },
        right: {
          txt: '',
          fn: 'confirm',
        },
      }
    },
    addContent() {
      return {
        title: this.status == 'add' ? '处理成功' : '修改处理成功',
        subTitle: '处理量' + this.form.count + '万',
      }
    },
    formOpts() {
      let _this = this
      return [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'radio',
              value: this.form.lm_end,
              name: 'lm_end',
              label: '批次完结',
              opts: [
                {
                  label: '已完结',
                  name: '1',
                },
                {
                  label: '未完结',
                  name: '0',
                },
              ],
            },
            {
              required: this.isOnlyAll ? true : false,
              readonly: this.isOnlyAll ? false : true,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.count,
              name: 'count',
              label: '处理总量',
              extra: '万',
              placeholder: this.isOnlyAll ? '请输入处理总量' : '自动输入',
              topSlot: 'diy',
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.d_date,
              name: 'd_date',
              label: '处理日期',
              placeholder: '请选择处理日期',
              click() {
                _this.showRemoveDatePicker = true
              },
              rules: [{ required: true, message: '请选择处理日期' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.unit_price,
              name: 'unit_price',
              extra: '元/万',
              label: '处理单价',
              placeholder: '请输入处理单价',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.sum_price,
              name: 'sum_price',
              extra: '元',
              label: '金额',
              placeholder: '请输入金额',
            },
            {
              required: false,
              colon: true,
              formType: 'textarea',
              type: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '可填写处理情况等内容',
            },
          ],
        },
      ]
    },
  },
  watch: {
    // 默认只填总量 和 精准到池 二选一
    isOnlyAll() {
      if (!this.isOnlyAll) {
        let num = 0
        this.form.d_info &&
          this.form.d_info.forEach((item) => {
            num += Number(item.count || 0)
          })
        this.form.count = num
      }

      if (this.isOnlyAll) {
        this.isPool = false
      } else {
        this.isPool = true
      }
    },
    isPool() {
      if (this.isPool) {
        this.isOnlyAll = false
      } else {
        this.isOnlyAll = true
      }
    },
    'form.count'(val) {
      // this.allData.c_count = val;

      if (this.status == 'add') {
        if (this.form.unit_price && val) {
          this.form.sum_price = this.form.unit_price * val
        }
      }
    },
    'form.d_info': {
      deep: true,
      handler() {
        if (!this.isOnlyAll) {
          let num = 0
          this.form.d_info &&
            this.form.d_info.forEach((item) => {
              num += Number(item.count || 0)
            })
          this.form.count = num
        }
      },
    },
    'form.unit_price'(val) {
      if (this.status == 'add') {
        if (this.form.count && val) {
          this.form.sum_price = this.form.count * this.form.unit_price
        }
      }
    },
  },
  async mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.form.lm_id = query.lm_id
      this.form.ld_key = query.ld_key
      this.status = query.status
      this.farmKey = query.farmKey
      if (this.form.ld_key && this.status == 'edit') {
        await this.getData()
      }

      if (this.farmKey && this.status == 'farm') {
        this.choseBatch()
      } else if (this.status == 'nofarm') {
        this.choseFarm()
      }
    }
  },
  methods: {
    // 选择批次
    addBatch() {
      if (this.farmKey) {
        this.choseBatch()
      } else {
        this.choseFarm()
      }
    },
    // 获取批次
    choseBatch(e) {
      if (this.farmKey || (e && e.key)) {
        this.$nextTick(() => {
          if (this.$refs['farmBatchPicker']) {
            this.$refs['farmBatchPicker'].show = true
            this.$refs['farmBatchPicker'].getLarvaList()
          }
        })
      }
    },
    // 获取基地
    choseFarm() {
      this.$nextTick(() => {
        if (this.$refs['farmPicker']) {
          this.$refs['farmPicker'].show = true
        }
      })
    },
    async getData() {
      this.isLoading = true
      this.$get('/larva/getDisposeLarva', {
        ld_key: this.form.ld_key,
        lm_id: this.form.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false

          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          data['count'] = data['c_count']

          this.h_chosed = []
          data.d_info.forEach((item) => {
            this.h_chosed.push(Number(item.lp_id))
          })

          if (data['type'] == 1) {
            this.isOnlyAll = false
          } else {
            this.isOnlyAll = true
          }

          if (data['d_date']) {
            data['d_Date'] = new Date(data['d_date'].replace(/-/g, '/'))
          } else {
            data['d_Date'] = new Date()
          }

          data['type'] = String(data['type'])

          this.form = data
        }
      })
    },
    onSubmit() {
      let form = JSON.parse(JSON.stringify(this.form))
      if (!form.count) {
        this.$toast.fail('处理量不能为空哦')
        return
      }

      form.d_info.forEach((item) => {
        item.p_completed = Number(item.p_completed)
      })

      if (this.isOnlyAll) {
        // 只填总处理量
        form.type = '2'
        let count = form.count / form.d_info.length
        form.d_info.forEach((item) => {
          item.count = count
        })
      } else {
        // 不填总处理量
        form.type = '1'
      }

      this.btnLoading = true
      this.$post('/larva/disposeLarva', numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          // this.$toast.success(this.tabTitle + '成功');
          this.form.ld_key = res.data.ld_key
          this.addSuccessShow = true
        }
      })
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'complete':
          this.curKeys = e
          this.tipShow = false
          this.onSubmit(false)
          break
        case 'batch':
          if (e) {
            this.form.lm_id = e
            this.status = 'add'
          }
          break
      }
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          this.$go(-1, this, {
            name: 'deal',
            query: {
              lm_id: this.form.lm_id,
            },
          })
          break
        case 'confirm':
          break
      }
    },
    toPage(type) {
      switch (type) {
        case 'notice':
          {
            let p = '1'
            let title = '处理'
            let remark = [
              {
                label: '批次',
                value: this.form.batch,
              },
              {
                label: '处理量',
                value: this.form.count,
                extra: '万',
              },
              {
                label: '单价',
                value: this.form.unit_price,
                extra: '元/万 ',
              },
              {
                label: '金额',
                value: this.form.sum_price,
                extra: '元',
              },
              {
                label: '处理日期',
                value: this.$dayjs(this.form.d_date).format('YYYY/MM/DD'),
              },
            ]

            this.toNotice({
              p,
              title,
              remark,
              status: this.status,
              re: [
                {
                  type: '28',
                  re_inf: {
                    ld_key: this.form.ld_key,
                  },
                },
              ],
            })
          }

          break
        case 'last':
          this.$go(-1, this, {
            name: 'deal',
            query: {
              lm_id: this.form.lm_id,
            },
          })
          break
      }
    },
    getDataObj(item) {
      return [
        [
          {
            data: item.launch,
            unitText: '万',
            descText: `投放量`,
          },
          {
            data: item.p_count,
            unitText: '个',
            descText: `育苗池数`,
          },
          {
            data: (item.e_date && item.e_date.slice(2, 10)) || '暂无',
            descText: '上次估苗',
          },
          {
            data:
              (item.e_count || 0) +
              '(' +
              (getRate(item.e_count, item.launch) || 0) +
              '%)',
            unitText: '',
            descText: `上次估苗量(%)`,
          },
        ],
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  .main-content {
    .title {
      padding: 0.5rem;
      font-size: 1.1rem;
      font-weight: 600;
    }

    .allow-p-list {
      &-top {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;

        .left {
          .allow-p-list-ws {
            font-size: 1rem;
            font-weight: bold;
            color: @titleColor;
          }
          .allow-p-list-num {
            font-size: 0.8rem;
            font-weight: 400;
            color: @textColor;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .form {
      padding: 1rem;
    }
  }

  .pool-chose {
    padding: 1rem;
    padding-top: 0;
    .title {
      display: flex;
      align-items: center;
      margin: 0.5rem;

      span {
        margin-right: 0.5rem;
        color: @titleColor;
        font-weight: bold;
      }
    }
  }
}
</style>
