<template>
  <div class="main">
    <yiyu-navbar tab-title="处理"> </yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <div class="header">
        <div class="title">处理数据记录</div>
        <div class="main-c" @click="toPage('add')">+新增处理</div>
      </div>
      <data-field :dataObj="getDataObj('index', form)"></data-field>
      <div class="data-wrap">
        <yiyu-empty v-if="list && list.length == 0">暂无处理数据</yiyu-empty>
        <div class="data-item" v-for="(item, index) in list" :key="index">
          <div class="data-item-top">
            <div class="date">
              {{ item.d_date && item.d_date.slice(0, 10) }}
            </div>
            <div class="handles">
              <span
                class="main-c handles-item"
                @click="del(item)"
                v-handle="'LARVA_BATCH_DEAL_DEL'"
                >删除</span
              >
              <!-- <span class="main-c handles-item" @click="toPage('edit', item)"
                >编辑</span
              > -->
            </div>
          </div>
          <data-field :dataObj="getDataObj('deal', item)"></data-field>
          <yiyu-collapse
            v-for="(item1, index1) in item['d_info']"
            :key="index1 + 'deal'"
            :show.sync="item1['show']"
          >
            <template #left>
              <div class="row-between">
                <div class="name">处理育苗池</div>
                <div></div>
              </div>
            </template>
            <template #content>
              <data-field
                :dataObj="getDataObj('deal-pool', item1['list'])"
              ></data-field>
            </template>
          </yiyu-collapse>
          <div class="row-between">
            <div class="item-title title-c">备注</div>
            <div class="item-value text-c7">
              {{ item.remark || '暂无' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import dataField from '@/components/layout/dataField'
import yiyuCollapse from '@/components/seeMore/collapse.vue'
export default {
  name: 'deal',
  components: {
    dataField,
    yiyuNavbar,
    yiyuCollapse,
    yiyuEmpty,
  },
  data() {
    return {
      postLoading: false,
      lm_id: undefined,
      isLoading: false,
      larvaKey: undefined, // 跳回去要用到
      list: [],
      form: {},
      breed: [], // 在养殖中的部分池
      curItem: {}, // 当前操作的item
    }
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.lm_id = query.lm_id
      if (this.lm_id) {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/larva/getDisposeList', {
        lm_id: this.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.form = res.data
          this.list = res.data.list
        }
      })
    },
    getDataObj(type, item) {
      let tmp
      switch (type) {
        case 'index':
          return [
            [
              {
                data: item.sum_d_count,
                unitText: '万',
                descText: '总处理量',
              },
              {
                data: item.sum_price,
                unitText: '元',
                descText: '金额',
              },
            ],
          ]
        case 'deal':
          return [
            [
              {
                data: item.d_count,
                descText: '处理量',
              },
              {
                data: item.unit_price,
                unitText: '元/万',
                descText: '单价',
              },
              {
                data: item.sum_price,
                unitText: '元',
                descText: '金额',
              },
            ],
          ]
        case 'deal-pool':
          tmp = [[]]
          item.forEach((item1) => {
            tmp[0].push({
              data: item1.count,
              unitText: '万',
              descText: item1.ws_name + '_' + item1.p_name,
            })
          })
          return tmp
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'add':
          this.$push(this, {
            name: 'dealEdit',
            query: {
              lm_id: this.lm_id,
              status: 'add',
            },
          })
          break
        case 'edit':
          this.$push(this, {
            name: 'dealEdit',
            query: {
              ld_key: item.ld_key,
              lm_id: this.lm_id,
              status: 'edit',
            },
          })
          break
      }
    },
    del(item) {
      this.curItem = item
      Dialog.confirm({
        title: '删除提醒',
        message: '删除后，育苗池的排苗记录将被删除，排苗的育苗池将恢复原状',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/larva/delDisposeRecord', {
            ld_key: item.ld_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除排苗成功')
              this.getData()
            } else if (res.code == 50043) {
              if (res.data.breed && res.data.breed.length) {
                // 从后端的返回判断是否已关联
                this.breed = res.data.breed
                this.canceRelation()
              }
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    canceRelation() {
      let arr = []
      let lp_id = []
      this.breed.forEach((item) => {
        arr.push(item['ws_name'] + '_' + item['p_name'])
        lp_id.push(item['lp_id'])
      })
      let str = arr.join('\n')
      let message = '以下池需进行取消关联后才能删除该记录\n' + str
      Dialog.confirm({
        title: '无法删除',
        message,
        cancelButtonText: '取消关联',
      })
        .then(() => {})
        .catch(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/larva/delLaunch', {
            lp_id,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('取消关联成功')
              this.del(this.curItem)
            }
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  padding: 1rem;

  .row-between {
    display: flex;
    justify-content: space-between;
  }

  .main-content {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: @titleColor;
      }
    }

    .data-wrap {
      .data-item {
        margin-bottom: 1rem;
        .data-item-top {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          border-radius: 8px;
          background: rgba(@mainRgb, 0.1);
        }
      }
    }

    .handles {
      .handles-item {
        margin: 0 0.5rem;
      }
    }
  }
}
</style>
