<template>
  <div class="farm-add">
    <yiyu-navbar tabTitle="外调苗"></yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
    >
      <template v-slot:footer>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
    </yiyu-form>
    <yiyu-picker
      v-if="showTypePicker"
      :curKey="form.type"
      value-key="title"
      key-name="lt_key"
      :show.sync="showTypePicker"
      :columns="$store.state.setting.userConf.larvaType.list"
      @confirm="confirmPicker($event, 'type')"
      @cancel="cancelPicker('type')"
    ></yiyu-picker>
    <datetime-popup
      :date.sync="form.import_date"
      :show.sync="showLaunchDatePicker"
      title="选择调苗时间"
      type="datetime"
      @confirm="showLaunchDatePicker = false"
      @cancel="showLaunchDatePicker = false"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="form.harvest_date"
      :show.sync="showHarvestDatePicker"
      title="选择预定出苗时间"
      type="datetime"
      @confirm="showHarvestDatePicker = false"
      @cancel="showHarvestDatePicker = false"
    >
    </datetime-popup>
    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.mc_key"
        :curName.sync="form.mc_keyName"
        :single="true"
        :farmType="['3']"
      ></farm-picker>
    </keep-alive>
    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            type="primary"
            @click="toPage('last')"
          >
            返回
          </van-button>
        </div>
      </template>
    </success-popup>
    <tag-popup
      v-if="showSupplierPicker"
      :curKeys="[form.supplier]"
      value-key="name"
      keyName="s_key"
      :show.sync="showSupplierPicker"
      :opts="supplierOpts"
      @confirmObj="confirmPicker($event, 'supplier')"
      @cancel="cancelPicker('supplier')"
      :loading="supplierLoading"
      :single="true"
    >
      <template #empty>
        <div class="supplier-empty">
          <span class="btn" @click="addSupplier">去添加来源</span>
        </div>
      </template>
    </tag-popup>
  </div>
</template>

<script>
import tagPopup from '@/components/popup/tag'
import successPopup from '@/components/popup/success'
import farmPicker from '@/bComponents/farmPicker'
import datetimePopup from '@/components/popup/datetime.vue'
import fixedBtn from '@/components/button/fixedBtn'
import { getKeyValue, numToStr } from '@/utils/utils'
import { formatTime } from '@/utils/date'
import yiyuForm from '@/components/form.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
export default {
  name: 'larvaImport',
  components: {
    yiyuForm,
    yiyuPicker,
    fixedBtn,
    datetimePopup,
    yiyuNavbar,
    farmPicker,
    successPopup,
    tagPopup,
  },
  data() {
    return {
      activated: false,
      btnLoading: false,
      status: 'add',
      thisAlias: this,
      form: {
        l_key: undefined,
        batch: undefined, // 批次
        type: undefined, // 类型
        typeName: undefined,
        harvest_date: undefined, // 出苗日期
        harvest_Date: new Date(),
        origin: undefined, // 育苗来源
        unit_price: undefined, // 单价
        settlement: undefined, // 实结金额
        book: undefined, // 育苗量
        remark: undefined,
        mc_key: undefined,
        mc_keyName: undefined,
        lm_id: undefined,
        supplier: undefined,
        supplier_name: undefined,
      },
      showLaunchDatePicker: false,
      showHarvestDatePicker: false,
      showSupplierPicker: false,
      showTypePicker: false,
      addSuccessShow: false,
      supplierLoading: false,
    }
  },
  mounted() {
    this.activated = false
    if (this.$route.query) {
      let query = this.$route.query
      this.form.l_key = query.larvaKey
      this.status = query.status || 'add'

      if (this.status == 'edit') {
        this.getData()
      }
    }

    this.getSupplier()
    this.getLarvaType()
  },
  activated() {
    if (!this.activated) {
      this.activated = true
      return
    }

    if (this.$route.query) {
      let query = this.$route.query
      this.form.l_key = query.larvaKey
      this.status = query.status || 'add'

      if (this.status == 'edit') {
        this.getData()
      }
    }

    this.getSupplier()
  },
  computed: {
    addHeader() {
      return {
        title: this.status == 'add' ? '新建外调苗成功' : '修改外调苗成功',
        left: {
          txt: '',
          fn: 'cancel',
        },
        right: {
          txt: '继续新增',
          fn: 'confirm',
        },
      }
    },
    addContent() {
      return {
        title: this.status == 'add' ? '新增成功' : '修改成功',
      }
    },
    formOpts() {
      let _this = this
      return [
        {
          title: this.form.batch || '苗批次',
          child: [
            // {
            //   required: true,
            //   colon: true,
            //   formType: "select",
            //   type: "text", // 默认，可以不写
            //   value: this.form.import_date,
            //   name: "import_date",
            //   label: "调苗时间",
            //   placeholder: "请选择调苗时间",
            //   click() {
            //     _this.showLaunchDatePicker = true;
            //   },
            //   rules: [{ required: true, message: "请选择调苗时间" }],
            // },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.mc_keyName,
              name: 'mc_keyName',
              label: '苗生产地',
              placeholder: '请选择苗生产地',
              click() {
                if (_this.$refs['farmPicker']) {
                  _this.$refs['farmPicker'].show = true
                }
              },
              rules: [{ required: true, message: '请选择苗生产地' }],
            },
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.book,
              name: 'book',
              label: '预定量',
              type: 'number',
              placeholder: '请输入预定量',
              rules: [{ required: true, message: '请输入预定量' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.harvest_date,
              name: 'harvest_date',
              label: '预定出苗时间',
              placeholder: '请选择预定出苗时间',
              click() {
                _this.showHarvestDatePicker = true
              },
              rules: [{ required: true, message: '请选择预定出苗时间' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input-select',
              type: 'text', // 默认，可以不写
              value: this.form.supplier_name,
              name: 'supplier_name',
              label: '育苗来源',
              placeholder: '请选择育苗来源',
              click() {
                _this.showSupplierPicker = true
              },
            },
            {
              required: false,
              colon: true,
              value: this.form.typeName,
              formType: 'select',
              name: 'typeName',
              label: '苗类别',
              placeholder: '请选择苗类别',
              click() {
                _this.showTypePicker = true
              },
              rules: [{ required: false, message: '请选择苗类别' }],
            },

            // {
            //   required: false,
            //   colon: true,
            //   formType: "input", // 默认
            //   value: this.form.launch,
            //   name: "launch",
            //   label: "苗数量",
            //   type: "number",
            //   placeholder: "请输入苗数量",
            //   extra: "万",
            //   rules: [{ required: false, message: "请输入苗数量" }],
            // },
            {
              required: false,
              colon: true,
              formType: 'input', // 默认
              value: this.form.unit_price,
              name: 'unit_price',
              label: '单价',
              type: 'number',
              placeholder: '请输入单价',
              extra: '元/万',
              rules: [{ required: false, message: '请输入单价' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input', // 默认
              value: this.form.settlement,
              name: 'settlement',
              label: '实结金额',
              type: 'number',
              placeholder: '请输入实结金额',
              extra: '元',
              rules: [{ required: false, message: '请输入实结金额' }],
            },
            // {
            //   required: false,
            //   colon: true,
            //   formType: "textarea",
            //   type: "textarea",
            //   value: this.form.remark,
            //   name: "remark",
            //   label: "备注",
            //   placeholder: "请输入备注",
            //   rules: [{ required: false, message: "请输入备注" }],
            // },
          ],
        },
        // {
        //   title: "苗成本",
        //   child: [

        //   ],
        // },
      ]
    },
    supplierOpts() {
      return this.$store.state.setting.suppliers.filter((item) =>
        [1, 5].includes(Number(item.type)),
      )
    },
  },
  watch: {
    supplierOpts: {
      deep: true,
      handler(val) {
        if (this.form.supplier && !this.form.supplier_name) {
          this.form.supplier_name = getKeyValue(
            this.form.supplier,
            val,
            'name',
            's_key',
          )
        }
      },
    },
    'form.book'() {
      if (this.form.book && this.form.unit_price && this.status == 'add') {
        this.form.settlement = this.form.book * this.form.unit_price
      }
    },
    'form.unit_price'() {
      if (this.form.book && this.form.unit_price && this.status == 'add') {
        this.form.settlement = this.form.book * this.form.unit_price
      }
    },
    'form.supplier_name'() {
      if (this.form.harvest_date) {
        let tmp = formatTime(new Date(this.form.harvest_date), '-')
        this.form.batch =
          this.form.supplier_name + tmp.replace(/-/g, '').slice(2, 8)
      }

      if (!this.showSupplierPicker) {
        if (this.form.supplier_name) {
          let key = getKeyValue(
            this.form.supplier_name,
            this.supplierOpts,
            's_key',
            'name',
          )

          if (key != '未知') {
            this.form.supplier = key
          } else {
            this.form.supplier = undefined
          }
        }
      }
    },
    'form.harvest_date'() {
      if (this.form.harvest_date) {
        let tmp = formatTime(new Date(this.form.harvest_date), '-')
        this.form.batch =
          (this.form.supplier_name || '') + tmp.replace(/-/g, '').slice(2, 8)
      }
    },
  },
  methods: {
    getLarvaType() {
      if (!this.$store.state.setting.userConf.larvaType.list) {
        this.$store.dispatch('getSeedConfList', { yy_type: 'larvaType' })
      }
    },
    getSupplier() {
      if (!this.$store.state.setting.getSuppliers) {
        this.supplierLoading = true
        this.$store.dispatch('getSupplier', {}).then((res) => {
          if (res.code == 10000) {
            this.supplierLoading = false
          }
        })
      }
    },
    getData() {
      this.isLoading = true
      this.$get('/import/getImport', {
        l_key: this.form.l_key,
      }).then((res) => {
        if (res.code == 10000) {
          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          data.typeName =
            data.type &&
            getKeyValue(
              data.type,
              this.$store.state.setting.userConf.larvaType.list,
            )

          if (data.harvest_date == '0000-00-00 00:00:00')
            data.harvest_date = undefined
          data.harvest_date =
            data.harvest_date && data.harvest_date.slice(0, 16)
          data.launch_Date = new Date(data.launch_date)

          this.form = data
        }
      })
    },
    onSubmit() {
      this.btnLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      // if(!form.supplier) {
      form.supplier = form.supplierName
      // }
      this.$post('/import/addImportLarva', numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code === 10000) {
          // this.$toast.success("保存成功");
          this.addSuccessShow = true
          this.form.lm_id = res.data.lm_id
          this.form.l_key = res.data.l_key
          if (this.form.supplier_name && !this.form.supplier) {
            this.$store.dispatch('getSupplier', {})
          }
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'type':
          this.form.type = e[0].lt_key
          this.form.typeName = e[0].title
          this.showTypePicker = false
          break
        case 'supplier':
          this.form.supplier = (e[0] && e[0].s_key) || undefined
          this.form.supplier_name = (e[0] && e[0].name) || undefined
          this.showSupplierPicker = false
          break
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'type':
          this.showTypePicker = false
          break
        case 'supplier':
          this.showSupplierPicker = false
          break
      }
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          this.$go(-1, this)
          break
        case 'confirm':
          this.addSuccessShow = false
          this.form = this.$options.data.call(this).form
          break
      }
    },
    toPage(type) {
      switch (type) {
        case 'last':
          this.$go(-1, this)
          break
      }
    },
    addSupplier() {
      this.$push(this, {
        path: '/supplierSet',
        query: { type: 1 },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.supplier-empty {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: @mainColor;
}
</style>
