<template>
  <div class="yiyu-collapse">
    <div class="yiyu-collapse-top" @click="showItem">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="right main-c">
        <van-icon name="arrow-up" v-if="!show" />
        <van-icon name="arrow-down" v-if="show" />
      </div>
    </div>
    <div class="yiyu-collapse-content" v-if="show">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'collapse',
  props: {
    show: {
      // sync
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showItem() {
      this.$emit('update:show', !this.show)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-collapse {
  padding-bottom: 0.5rem;
  &-top {
    display: flex;
    align-items: center;
    .left {
      flex-basis: 100%;
    }
    .right {
      flex-basis: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-content {
    padding: 0.5rem 0;
  }
}
</style>
