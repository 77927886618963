<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <div class="main-content relative" v-myLoad="isLoading">
      <template v-if="pool_list.length && (form.lm_id || form.lm_key)">
        <section class="info">
          <div class="title">{{ form.batch }}-{{ mc_name }}</div>
          <data-field :dataObj="getDataObj('info', moreData)"></data-field>
        </section>
        <section class="form">
          <yiyu-form
            :_this="thisAlias"
            :formOpts="formOpts"
            @submit="onSubmit"
            ref="form"
          >
            <template #oriPool>
              <van-checkbox v-model="isOnlyAll" shape="square"
                >默认只填总搬苗量</van-checkbox
              >
            </template>
            <template #chosePool>
              <pool-choose
                :post-data.sync="form.original"
                type="move-out"
                :date="form.m_date"
                :lm_id="form.lm_id"
                keyName="lp_id"
                :curData="form.mo_info"
                :input="!isOnlyAll"
                :poolList.sync="pool_list"
                :batch.sync="form.batch"
                :moreData.sync="moreData"
              ></pool-choose>
            </template>
            <template #tarPool>
              <data-field :dataObj="getDataObj('total', form)"></data-field>

              <van-checkbox v-model="tarObj.isAverage" shape="square"
                >将搬入量平均到各个目标育苗池（{{
                  tarObj.average
                }}万）</van-checkbox
              >
              <pool-choose
                :post-data.sync="form.target"
                type="move-in"
                :date="form.m_date"
                :lm_id="form.lm_id"
                keyName="p_key"
                :curData="form.mi_info"
                :input="!tarObj.isAverage"
                :mc-key="farmKey"
              ></pool-choose>
            </template>
          </yiyu-form>
        </section>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
      <yiyu-empty v-else
        >暂无可操作的育苗池，
        <span class="main-c" @click="addBatch">去添加</span>
      </yiyu-empty>
    </div>

    <datetime-popup
      :date.sync="form.m_date"
      :show.sync="showRemoveDatePicker"
      title="选择时间"
      @confirm="showRemoveDatePicker = false"
      @cancel="showRemoveDatePicker = false"
      type="date"
    >
    </datetime-popup>
    <!-- 封装成业务组件(先不封，需求又改了) -->
    <van-dialog
      class="change-status-dialog"
      v-model="changeStatusShow"
      :show-confirm-button="false"
      :show-cancel-button="true"
      @cancel="changeStatusShow = false"
    >
      <div class="change-status-dialog-msg">
        当前育苗池已与批次进行关联！您可进行取消关联，取消关联后该育苗池与原批次所产生的数据将会被删除
      </div>
      <div class="change-status-dialog-btn" @click="changeStatus('cancel')">
        取消关联
      </div>
      <div class="change-status-dialog-msg">
        您可将育苗池与该批次的育苗结束，结束应保持育苗池已空。
      </div>
      <div class="change-status-dialog-btn" @click="changeStatus('over')">
        结束育苗
      </div>
    </van-dialog>

    <farm-batch-picker
      v-if="farmKey"
      ref="farmBatchPicker"
      :farmKey="farmKey"
      @confirm="confirmPicker($event, 'batch')"
    ></farm-batch-picker>

    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="farmKey"
        :single="true"
        @confirm="choseBatch"
        :cancel-show="false"
      ></farm-picker>
    </keep-alive>

    <van-dialog
      class="change-status-dialog"
      v-model="tipShow"
      :show-confirm-button="true"
      :show-cancel-button="true"
      @cancel="tipShow = false"
      @confirm="postData"
      title="提示"
    >
      <div class="change-status-dialog-msg">
        搬出量与搬入量不一致，是否按当前数据进行保存？
      </div>
    </van-dialog>

    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            @click="toPage('notice')"
          >
            发布通知
          </van-button>
          <van-button
            class="success-popup-handle-btn"
            type="primary"
            @click="toPage('last')"
          >
            返回
          </van-button>
        </div>
      </template>
    </success-popup>
  </div>
</template>

<script>
import successPopup from '@/components/popup/success'

import poolChoose from '@/bComponents/poolChoose.vue'
import farmPicker from '@/bComponents/farmPicker'
import farmBatchPicker from '@/bComponents/farmBatchPicker.vue'
import yiyuEmpty from '@/components/layout/empty.vue'
import fixedBtn from '@/components/button/fixedBtn'
import { numToStr, getRate } from '@/utils/utils'
import yiyuNavbar from '@/components/navbar'
import dataField from '@/components/layout/dataField'
import datetimePopup from '@/components/popup/datetime.vue'
import yiyuForm from '@/components/form.vue'
export default {
  name: 'moveEdit',
  components: {
    dataField,
    yiyuNavbar,
    yiyuForm,
    datetimePopup,
    fixedBtn,
    yiyuEmpty,
    farmBatchPicker,
    farmPicker,
    poolChoose,
    successPopup,
  },
  data() {
    return {
      status: 'add',
      isLoading: false,
      thisAlias: this,
      farmKey: undefined,
      form: {
        lm_id: undefined, // "",
        lm_key: undefined, // "搬苗key",  // 修改时候传
        remark: undefined, // "备注",
        type: undefined, // "1",  // 搬苗原因 1 质量原因， 2 销售原因
        mo_count: undefined, // "搬苗总量",
        m_date: undefined, // "搬苗日期",
        original: [], // {lp_id,count}
        target: [], // {p_key, count}
        m_type: '2', // 2不分池 1分池
        mi_count: undefined, // 已搬入量
        batch: undefined,
      },
      moreData: {
        mc_name: undefined, // "基地名",
        launch: undefined, // "投放量",
        p_count: undefined, // 育苗池数,
        e_date: undefined, // "上次估苗时间",
        e_count: undefined, // "上次估苗量",
      },
      mc_name: undefined,
      showRemoveDatePicker: false,
      changeStatusShow: false,
      btnLoading: false,
      h_chosed: [],
      pool_list: [],
      isOnlyAll: true, // 默认只填总搬苗量
      poolForm: {},
      pool: {},
      // 目标育苗池相关数据
      tarObj: {
        isAverage: true, // 默认平均
        allow_p_list: [],
        pool: {}, // 不默认平均下的输入量
        not_allow_p_list: [],
        not_allow_p_show: false,
        average: 0, // 平均搬入量
      },
      tipShow: false,
      addSuccessShow: false,
    }
  },
  computed: {
    tabTitle() {
      if (this.status == 'edit') return '编辑搬苗'
      else return '新增搬苗'
    },
    addHeader() {
      return {
        title: this.status == 'add' ? '新建成功' : '修改成功',
        left: {
          txt: '',
          fn: 'cancel',
        },
        right: {
          txt: '',
          fn: 'confirm',
        },
      }
    },
    addContent() {
      return {
        title: this.status == 'add' ? '搬苗成功' : '修改搬苗成功',
        subTitle:
          '总搬出量' +
          this.form.mo_count +
          '万,' +
          '搬入量' +
          this.form.mi_count +
          '万',
      }
    },
    formOpts() {
      let _this = this
      return [
        {
          title: '原育苗池',
          slot: 'chosePool',
          child: [
            {
              required: this.isOnlyAll ? true : false,
              readonly: this.isOnlyAll ? false : true,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.mo_count,
              name: 'mo_count',
              label: '搬苗总量',
              extra: '万',
              placeholder: this.isOnlyAll ? '请输入搬苗总量' : '自动输入',
              topSlot: 'oriPool',
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.m_date,
              name: 'm_date',
              label: '搬苗日期',
              placeholder: '请选择搬苗日期',
              click() {
                _this.showRemoveDatePicker = true
              },
              rules: [{ required: true, message: '请选择搬苗日期' }],
            },
          ],
        },
        {
          title: '目标育苗池',
          slot: 'tarPool',
        },
        {
          title: '其他',
          child: [
            {
              required: false,
              colon: true,
              formType: 'textarea',
              type: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '可填写搬苗情况等内容',
            },
          ],
        },
      ]
    },
    // 原育苗池选择的池
    poolFormOpts() {
      let tmp = []
      if (Array.isArray(this.pool_list)) {
        this.pool_list.forEach((ws) => {
          ws.list &&
            ws.list.forEach((item) => {
              if (ws.chosed.includes(item.lp_id)) {
                tmp.push({
                  label: item.ws_name + '_' + item.p_name,
                  name: item.lp_id,
                  launch: item.launch,
                  e_count: item.e_count,
                  h_count: item.h_count,
                  d_count: item.d_count,
                  c_count: item.mo_count,
                })
              }
            })
        })
      }
      return tmp
    },
  },
  watch: {
    'form.mo_count'() {
      this.getMiCount()
    },
    'form.original': {
      handler() {
        this.getMoCount()
      },
      deep: true,
    },
    'form.target': {
      handler() {
        this.getMiCount()
      },
      deep: true,
    },
    isOnlyAll() {
      this.getMoCount()
    },
    tarObj: {
      handler() {
        if (this.status == 'add') {
          this.getMiCount()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.form.lm_id = query.lm_id
      this.form.lm_key = query.lm_key
      this.farmKey = query.farmKey
      this.status = query.status
      if (this.form.lm_key && this.status == 'edit') {
        await this.getData()
      }
      if (this.form.lm_id) {
        this.getPool()
      }

      if (this.farmKey && this.status == 'farm') {
        this.choseBatch()
      } else if (this.status == 'nofarm') {
        this.choseFarm()
      }
    }
  },
  methods: {
    // 选择批次
    addBatch() {
      if (this.farmKey) {
        this.choseBatch()
      } else {
        this.choseFarm()
      }
    },
    // 获取批次
    choseBatch(e) {
      if (this.farmKey || (e && e.key)) {
        this.$nextTick(() => {
          if (this.$refs['farmBatchPicker']) {
            this.$refs['farmBatchPicker'].show = true
            this.$refs['farmBatchPicker'].getLarvaList()
          }
        })
      }
    },
    // 获取基地
    choseFarm() {
      this.$nextTick(() => {
        if (this.$refs['farmPicker']) {
          this.$refs['farmPicker'].show = true
        }
      })
    },
    async getData() {
      this.isLoading = true
      this.$get('/larva/getCleanLarva', {
        lm_key: this.form.lm_key,
        lm_id: this.form.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false

          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          data['mo_count'] = data['c_count']

          this.h_chosed = []
          data.c_info.forEach((item) => {
            this.h_chosed.push(Number(item.lp_id))
          })

          if (data['m_type'] == 1) {
            this.isOnlyAll = false
          } else {
            this.isOnlyAll = true
          }

          data['type'] = String(data['type'])

          if (data['m_date']) {
            data['m_Date'] = new Date(data['m_date'].replace(/-/g, '/'))
          } else {
            data['m_Date'] = new Date()
          }

          this.form = data
        }
      })
    },
    // 获取育苗池
    async getPool() {
      await this.$get('/larva/getLaunchDetails', {
        lm_id: this.form.lm_id,
        by_type: 'ws',
      }).then((res) => {
        if (res.code == 10000) {
          this.pool_list = res.data.p.filter(
            (item) =>
              !item['end_date'] || this.h_chosed.includes(item['lp_id']),
          )
          if (Array.isArray(this.pool_list)) {
            this.pool_list.forEach((item) => {
              let chosed = []

              item.list.forEach((item1) => {
                this.$set(this.pool, item1.lp_id, {
                  value: item1.mo_count || undefined,
                  show: false,
                })
                if (this.h_chosed.includes(item1.lp_id)) {
                  chosed.push(item1.lp_id)
                }
              })
              this.$set(item, 'chosed', chosed)
            })
          }
          this.mc_name = res.data.mc?.mc_name
        }
      })
    },
    getDataObj(type, item) {
      switch (type) {
        case 'info':
          return [
            [
              {
                data: item.launch,
                unitText: '万',
                descText: `投放量`,
              },
              {
                data: item.p_count,
                unitText: '个',
                descText: `育苗池数`,
              },
              {
                data: (item.e_date && item.e_date.slice(2, 10)) || '暂无',
                descText: '上次估苗',
              },
              {
                data:
                  (item.e_count || 0) +
                  '(' +
                  (getRate(item.e_count, item.launch) || 0) +
                  '%)',
                unitText: '',
                descText: `上次估苗量(%)`,
              },
            ],
          ]
        case 'total':
          if (this.tarObj.isAverage) {
            this.form.mi_count = this.form.mo_count
          } else {
            this.getMiCount()
          }

          item = this.form

          return [
            [
              {
                data: item.mo_count || 0,
                unitText: '万',
                descText: '总搬苗量',
              },
              {
                data: item.mi_count || 0,
                unitText: '万',
                descText: '已搬入量',
              },
              {
                data: (item.mo_count || 0) - (item.mi_count || 0) || 0,
                unitText: '万',
                descText: '待搬入量',
              },
            ],
          ]
        case 'pool':
          return [
            [
              {
                data: item.launch || 0,
                unitText: '万',
                descText: '投放量',
              },
              {
                data: item.e_count || 0,
                unitText: '万',
                descText: '估苗量',
              },
              {
                data: item.h_count || 0,
                unitText: '万',
                descText: '出苗量',
              },
            ],
            [
              {
                data: item.d_count || 0,
                unitText: '万',
                descText: '处理量',
              },
              {
                data: item.c_count || 0,
                unitText: '万',
                descText: '排苗量',
              },
              {},
            ],
          ]
      }
    },
    getMiCount() {
      if (!this.tarObj.isAverage) {
        let num = 0
        this.form.target &&
          this.form.target.forEach((item) => {
            num += Number(item.count || 0)
          })
        this.form.mi_count = num
      }

      if (this.form.target.length) {
        this.tarObj.average =
          (this.form.mo_count || 0) / this.form.target.length
      } else {
        this.tarObj.average = 0
      }
    },
    getMoCount() {
      if (!this.isOnlyAll) {
        let num = 0
        this.form.original &&
          this.form.original.forEach((item) => {
            num += Number(item.count || 0)
          })
        this.form.mo_count = num
      }
    },
    onSubmit() {
      if (!this.form.mo_count) {
        this.$toast.fail('搬苗量不能为空哦')
        return
      }
      if (this.form.mi_count != this.form.mo_count) {
        this.tipShow = true
        return
      }

      this.postData()
    },
    postData() {
      let form = JSON.parse(JSON.stringify(this.form))
      this.tipShow = false
      if (this.isOnlyAll) {
        form.m_type = '2'
        let count = form.mo_count / form.original.length
        form.original.forEach((item) => {
          item.count = count
        })
      } else {
        form.m_type = '1'
      }

      if (this.tarObj.isAverage) {
        let count = this.tarObj.average
        form.target.forEach((item) => {
          item.count = count
        })
      }

      this.btnLoading = true
      this.$post('/larva/moveLarva', numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.form.lm_key = res.data.lm_key
          this.addSuccessShow = true
        }
      })
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'batch':
          if (e) {
            this.form.lm_id = e
            this.status = 'add'
            this.getPool()
          }
          break
      }
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          this.$go(-1, this, {
            name: 'move',
            query: {
              lm_id: this.form.lm_id,
              farmKey: this.farmKey,
            },
          })
          break
        case 'confirm':
          break
      }
    },
    toPage(type) {
      switch (type) {
        case 'notice':
          {
            let p = '1'
            let title = '搬苗'
            let ori = []
            this.form.original.forEach((item) => {
              ori.push(item.ws_name + '_' + item.p_name)
            })
            let tar = []
            this.form.target.forEach((item) => {
              tar.push(item.ws_name + '_' + item.p_name)
            })
            let remark = [
              {
                label: '批次',
                value: this.form.batch,
              },
              {
                label: '原育苗池',
                value: ori.join('、'),
              },
              {
                label: '目标育苗池',
                value: tar.join('、'),
              },
              {
                label: '搬出量',
                value: this.form.mo_count,
                extra: '万',
              },
              {
                label: '搬入量',
                value: this.form.mi_count,
                extra: '万',
              },
              {
                label: '搬苗日期',
                value: this.form.m_date,
              },
            ]
            this.toNotice({
              p,
              title,
              remark,
              status: this.status,
              re: [
                {
                  type: '26',
                  re_info: {
                    lm_key: this.form.lm_key,
                  },
                },
              ],
            })
          }

          break
        case 'last':
          this.$go(-1, this, {
            name: 'move',
            query: {
              lm_id: this.form.lm_id,
              farmKey: this.farmKey,
            },
          })
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  .main-content {
    .title {
      padding: 0.5rem;
      font-size: 1.1rem;
      font-weight: 600;
    }

    .pool-chose {
      .title {
        display: flex;
        align-items: center;
        margin: 0.5rem;

        span {
          margin-right: 0.5rem;
          color: @titleColor;
          font-weight: bold;
        }
      }
    }

    .allow-p-list {
      &-top {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;

        .left {
          .allow-p-list-ws {
            font-size: 1rem;
            font-weight: bold;
            color: @titleColor;
          }
          .allow-p-list-num {
            font-size: 0.8rem;
            font-weight: 400;
            color: @textColor;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .form {
      padding: 1rem;
    }
  }
}

.yiyu-collapse {
  margin-bottom: 1rem;
}

/deep/.yiyu-collapse-top .left {
  flex-basis: auto;
}

/deep/.yiyu-collapse-content {
  .not-allow-p-list {
    padding: 0 1rem;
    border-bottom: 1px solid @lineColor;

    .row {
      display: flex;
      justify-content: space-between;
    }

    &-ws {
      font-size: 1rem;
      font-weight: bold;
      color: @titleColor;
    }

    .not-allow-pool-item {
      padding: 0 0.5rem;
      border-bottom: 1px solid @lineColor;

      &-name {
        color: @textColor;
      }
    }
  }
}

.change-status-dialog.van-dialog {
  padding: 1rem;

  .change-status-dialog-msg {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
  }
  .change-status-dialog-btn {
    padding: 0.5rem 1rem;
    border: 1px solid @mainColor;
    text-align: center;
  }
}
</style>
