<template>
  <div class="main">
    <yiyu-navbar tab-title="排苗"> </yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <div class="header">
        <div class="title">排苗数据记录</div>
        <div class="main-c" @click="toPage('add')">+新增排苗</div>
      </div>
      <data-field :dataObj="getDataObj('index', form)"></data-field>
      <div class="data-wrap">
        <yiyu-empty v-if="list && list.length == 0">暂无排苗数据</yiyu-empty>
        <div class="data-item" v-for="(item, index) in list" :key="index">
          <div class="data-item-top">
            <div class="date">排苗({{ getRwmoveType(item) }})</div>
            <div class="handles">
              <span
                class="main-c handles-item"
                @click="del(item)"
                v-handle="'LARVA_BATCH_REMOVE_DEL'"
                >删除</span
              >
              <!-- <span class="main-c handles-item" @click="toPage('edit', item)"
                >编辑</span
              > -->
            </div>
          </div>
          <data-field :dataObj="getDataObj('remove', item)"></data-field>
          <yiyu-collapse
            v-for="(item1, index1) in item['c_info']"
            :key="index1 + 'clean'"
            :show.sync="item1['show']"
          >
            <template #left>
              <div class="row-between">
                <div class="name">
                  {{ item1['ws_name'] }}({{
                    item1['list'] && item1['list'].length
                  }}池)
                </div>
                <div class="num">{{ item1['sum'] }}万</div>
              </div>
            </template>
            <template #content>
              <data-field
                :dataObj="getDataObj('clean-pool', item1['list'])"
              ></data-field>
            </template>
          </yiyu-collapse>
          <div class="row-between">
            <div class="item-title title-c">备注</div>
            <div class="item-value text-c7">
              {{ item.remark || '暂无' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import dataField from '@/components/layout/dataField'
import yiyuCollapse from '@/components/seeMore/collapse.vue'
export default {
  name: 'remove',
  components: {
    dataField,
    yiyuNavbar,
    yiyuCollapse,
    yiyuEmpty,
  },
  data() {
    return {
      lm_id: undefined,
      isLoading: false,
      larvaKey: undefined, // 跳回去要用到
      list: [],
      form: {},
      breed: [], // 在养殖中的部分池
      curItem: {}, // 当前操作的item
    }
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.lm_id = query.lm_id
      if (this.lm_id) {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/larva/getCleanList', {
        lm_id: this.lm_id,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.form = res.data
          this.list = res.data.list
        }
      })
    },
    getDataObj(type, item) {
      let tmp
      switch (type) {
        case 'index':
          return [
            [
              {
                data: item.sum || 0,
                unitText: '万',
                descText: '总排苗量',
              },
              {
                data: item.quality || 0,
                unitText: '万',
                descText: '质量问题',
              },
              {
                data: item.sale || 0,
                unitText: '万',
                descText: '销售问题',
              },
            ],
          ]
        case 'remove':
          return [
            [
              {
                data: item.c_count || 0,
                unitText: '万',
                descText: '排苗量',
              },
              {
                data: item.p_count,
                unitText: '个',
                descText: '池数',
              },
              {
                data: item.c_date && item.c_date.slice(2, 10),
                descText: '排苗日期',
              },
            ],
          ]
        case 'clean-pool':
          tmp = [[]]
          item.forEach((item1) => {
            tmp[0].push({
              data: item1.count,
              unitText: '万',
              descText: item1.p_name,
            })
          })
          return tmp
      }
    },
    getRwmoveType(item) {
      if (item.type == 1) {
        return '质量原因'
      } else if (item.type == 2) {
        return '销售原因'
      } else {
        return '未知原因'
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'add':
          this.$push(this, {
            name: 'removeEdit',
            query: {
              lm_id: this.lm_id,
              status: 'add',
            },
          })
          break
        case 'edit':
          this.$push(this, {
            name: 'removeEdit',
            query: {
              lc_key: item.lc_key,
              lm_id: this.lm_id,
              status: 'edit',
            },
          })
          break
      }
    },
    del(item) {
      this.curItem = item
      Dialog.confirm({
        title: '删除提醒',
        message: '删除后，育苗池的排苗记录将被删除，排苗的育苗池将恢复原状',
      })
        .then(() => {
          this.$post('/larva/delCleanRecord', {
            lc_key: item.lc_key,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('删除排苗成功')
              this.getData()
            } else if (res.code == 50043) {
              if (res.data.breed && res.data.breed.length) {
                // 从后端的返回判断是否已关联
                this.breed = res.data.breed
                this.canceRelation()
              }
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    canceRelation() {
      let arr = []
      let lp_id = []
      this.breed.forEach((item) => {
        arr.push(item['ws_name'] + '_' + item['p_name'])
        lp_id.push(item['lp_id'])
      })
      let str = arr.join('\n')
      let message = '以下池需进行取消关联后才能删除该记录\n' + str
      Dialog.confirm({
        title: '无法删除',
        message,
        cancelButtonText: '取消关联',
      })
        .then(() => {})
        .catch(() => {
          this.$post('/larva/delLaunch', {
            lp_id,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('取消关联成功')
              this.del(this.curItem)
            }
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  padding: 1rem;

  .row-between {
    display: flex;
    justify-content: space-between;
  }

  .main-content {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: @titleColor;
      }
    }

    .data-wrap {
      .data-item {
        margin-bottom: 1rem;
        .data-item-top {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          border-radius: 8px;
          background: rgba(@mainRgb, 0.1);
        }
      }
    }

    .handles {
      .handles-item {
        margin: 0 0.5rem;
      }
    }
  }
}
</style>
