import { Dialog } from 'vant'

export default {
  methods: {
    //  取消关联
    cancelAlloct(item) {
      Dialog.confirm({
        title: '提醒',
        message: '是否确认取消订单与批次的关联',
      })
        .then(() => {
          this.$post('/po/delRelation', {
            lo_key: item.lo_key,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('取消关联成功')
              this.getData()
            }
          })
        })
        .catch(() => {})
    },
  },
}
