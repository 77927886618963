<template>
  <div>
    <yy-navbar tab-title="新增藻类需求" />
    <yiyu-form :_this="this" :formOpts="formOpts" @submit="handleFormSubmit">
      <template #list>
        <div class="flex flex-col items-center px-4">
          <div
            v-for="(requrement, idx) in form.request_list"
            :key="idx"
            class="m-2 w-full"
          >
            <span>{{ requrement.date }}</span>
            <van-field
              v-for="type in requrement.types"
              :key="type.label"
              :label="type.label"
              :placeholder="type.placeholder"
              v-model="type.value"
              type="digit"
            >
              <template #right-icon>{{ type.rightContent }}</template>
            </van-field>
          </div>
        </div>
      </template>

      <template #footer>
        <fixed-btn btnTxt="确定" btnNativeType="submit" />
      </template>
    </yiyu-form>

    <van-calendar
      v-model="calendarShow"
      :max-date="maxDate"
      :min-date="minDate"
      :default-date="defaultDate"
      @confirm="handleCalendarConfirm"
      type="range"
    />
  </div>
</template>

<script>
import { VanCalendarPresetMixin } from '@/mixin/VanCalendarPreset'
import { mapState } from 'vuex'
import FixedBtn from '@/components/button/fixedBtn.vue'
import { genParameters } from '@/utils/form'
import YiyuForm from '@/components/form/yiyuForm.vue'
import bPicker from '@/bComponents/bPicker'
import { YDialog } from 'yiyu-vant-ui'

export default {
  components: { FixedBtn, YiyuForm },
  mixins: [VanCalendarPresetMixin],
  data() {
    return {
      form: {
        mc_label: undefined,
        mc_key: undefined,
        date_range: undefined,
        request_list: [],
      },
      calendarShow: false,
      defaultDate: [
        this.$dayjs(Date()).add(1, 'day').$d,
        this.$dayjs(Date()).add(10, 'day').$d,
      ],
    }
  },
  computed: {
    ...mapState({
      user_key: (state) => state.basic.user_key,
    }),
    formOpts() {
      return [
        {
          child: [
            {
              label: '基地',
              required: true,
              value: this.form.mc_label,
              formType: 'select',
              click: () => {
                bPicker(this, {
                  type: 'farm',
                  props: {
                    type: 1,
                    curKey: this.form.mc_key,
                    single: true,
                  },
                }).then(({ key, obj }) => {
                  this.form.mc_key = key
                  this.form.mc_label = obj.name
                })
              },
            },
            {
              label: '日期选择',
              required: true,
              value: this.form.date_range,
              formType: 'select',
              click: () => {
                this.calendarShow = true
              },
            },
          ],
          slot: 'list',
        },
      ]
    },
  },
  methods: {
    handleCalendarConfirm(e) {
      this.form.date_range = `${this.$dayjs(e[0]).format(
        'YYYY-MM-DD',
      )} 至 ${this.$dayjs(e[1]).format('YYYY-MM-DD')}`
      let genCardNum = this.$dayjs(e[1]).diff(this.$dayjs(e[0]), 'day')
      let _request_list = []
      let i = 0
      while (i <= genCardNum) {
        _request_list.push({
          date: this.$dayjs(e[0]).add(i, 'day').format('YYYY-MM-DD'),
          types: [
            {
              tag: 1,
              label: '上午需求量',
              value: undefined,
              rightContent: 'L',
              placeholder: '请输入数量',
            },
            {
              tag: 2,
              label: '下午需求量',
              value: undefined,
              rightContent: 'L',
              placeholder: '请输入数量',
            },
          ],
        })
        i++
      }
      this.form.request_list = _request_list
      this.calendarShow = false
    },
    handlePickerConfirm(e) {
      this.form.mc_label = e[0].text
      this.form.mc_key = e[0]?.key
    },
    handleFormSubmit() {
      let params = genParameters(this.form, ['_range', '_label'])
      params.request_list = this.form.request_list
        .map((requirement) =>
          requirement.types.map((type) => ({
            reque_day: requirement.date,
            quantity: type.value,
            tag: type.tag,
          })),
        )
        .flat()
      this.$post('/request/addRequest', params).then((res) => {
        if (res.code === 10000) {
          YDialog({
            type: 'list',
            title: '需求新增成功',
            data: [
              {
                name: '返回上一页',
                key: 'back',
                onConfirm: () => {
                  this.$router.back()
                },
              },
              {
                name: '发送通知',
                key: 'notice',
                onConfirm: () => {
                  this.$router.replace('notice')
                },
              },
            ],
          })
        } else {
          this.$toast('请稍后再试' + res.code)
        }
      })
    },
  },
  mounted() {
    this.handleCalendarConfirm(this.defaultDate)
  },
}
</script>
