<template>
  <van-popup
    class="yiyu-success-popup"
    v-model="showVisible"
    round
    position="bottom"
    @close="clickFn(header.left.fn)"
    :close-on-click-overlay="false"
  >
    <div class="popup-header">
      <div class="popup-header-cancel" @click="clickFn(header.left.fn)">
        {{ header.left.txt }}
      </div>
      <div class="popup-header-title">{{ header.title }}</div>
      <div class="popup-header-confirm" @click="clickFn(header.right.fn)">
        {{ header.right.txt }}
      </div>
    </div>
    <div class="popup-content">
      <img src="@/assets/icons/tick.png" alt="" />
      <div class="popup-content-title" v-if="content.title">
        {{ content.title }}
      </div>
      <div class="popup-content-subtitle" v-if="content.subTitle">
        {{ content.subTitle }}
      </div>
      <slot name="content"></slot>
      <slot name="bottom">
        <!-- 模板 -->
        <!-- <template #bottom>
          <div class="success-popup-handle">
            <van-button
              class="success-popup-handle-btn"
              @click="toPage('addLarva')"
            >
              继续安排
            </van-button>
            <van-button
              class="success-popup-handle-btn"
              type="primary"
              @click="toPage('assignFarm')"
            >
              指定基地
            </van-button>
          </div>
        </template> -->
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'successPopup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Object,
      default: () => {
        return {
          title: '订单审核',
          left: {
            txt: '关闭',
            fn: 'cancel',
          },
          right: {
            txt: '继续审核',
            fn: 'confirm',
          },
        }
      },
    },
    content: {
      type: Object,
      default: () => {
        return {
          title: '订单审核通过',
          subTitle: '还有订单需要审核，是否审核下一条订单？',
        }
      },
    },
  },
  data() {
    return {
      showVisible: this.show,
    }
  },
  watch: {
    show(val) {
      this.showVisible = val
    },
  },
  methods: {
    clickFn(val) {
      this.$emit(val)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-success-popup {
  .popup-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding: 0 16px;
    height: 64px;
    line-height: 64px;
    &-cancel {
      font-size: 1.1rem;
      color: @titleColor;
    }
    &-title {
      font-size: 20px;
      color: @titleColor;
    }
    &-confirm {
      font-size: 1.1rem;
      color: @mainColor;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    padding-bottom: 36px;

    &-title {
      font-size: 1.1rem;
      font-weight: 500;
      color: @titleColor;
      margin: 20px auto 8px;
    }
    &-subtiltle {
      font-weight: 400;
      font-size: 1rem;
      color: @textColor;
    }

    img {
      height: 63.5px;
    }
  }
}
</style>
<style lang="less">
.success-popup-handle {
  display: flex;
  justify-content: center;

  &-btn {
    margin: 1rem 1rem 0;
  }
}
</style>
