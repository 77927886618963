import { getKeyArr } from '@/utils/utils';

export default {
  methods: {
    getMcNames(arr = [], keyName = 'mc_name') {
      return getKeyArr(arr, keyName).join('、');
    },
    getMcKeys(arr = [], keyName = 'mc_key') {
      return getKeyArr(arr, keyName);
    },
    getWsPNames(arr = [], wsName = 'ws_name', type = 'num') {
      if (Array.isArray(arr)) {
        let tmp = [];
        arr.forEach((item) => {
          if (item.pool_list && item.pool_list.length) {
            if (type == 'num') {
              tmp.push(item[wsName] + '(' + item.pool_list.length + '池)');
            } else if (type == 'detail') {
              let pool = getKeyArr(item.pool_list, 'p_name').join('、');
              tmp.push(item[wsName] + '(' + pool + '池)');
            }
          } else {
            tmp.push(item[wsName]);
          }
        });
        return tmp.join('、');
      }
    },
    getWsNames(mc) {
      let wsArr = [];
      mc.forEach((e) => {
        wsArr.push(...e.ws);
      });
      return wsArr.map((e) => e.ws_name).join('、');
    },
  },
};
