import Vue from 'vue'
import { has } from 'lodash'

const components = {
  person: () => import(/* webpackChunkName: "produce" */ './bPersonPicker.vue'),
  farm: () => import(/* webpackChunkName: "produce" */ './farmPicker.vue'),
}
let instance = null

export default async (context, options) => {
  if (instance != null && options.cache) {
    instance.show = true
  } else {
    if (!has(components, options.type)) {
      console.warn('没有该 picker')
      return
    }
    let sfc = await components[options.type]()
    const instanceConstructor = Vue.extend(sfc.default)
    instance = new instanceConstructor({
      el: document.createElement('div'),
      parent: context,
      propsData: options.props ? options.props : { ...options },
    })
    instance.show = true
    instance.getData()
    document.getElementById('app').appendChild(instance.$el)

    // 不缓存, 在 closed 时, 摧毁实例
    if (typeof options.cache != 'boolean' || !options.cache) {
      instance.$on('closed', () => {
        instance.$el.remove()
        instance.$destroy()
        instance = null
      })
    }
  }

  return new Promise((res) => {
    instance.$on('confirm', res)
  })
}
