<template>
  <div class="farm-launch grey-bg">
    <yiyu-navbar :tabTitle="tabTitle"></yiyu-navbar>
    <div class="main" v-myLoad="isLoading">
      <section class="header">
        <div class="row-between">
          <div class="title">{{ form.batch || '育苗批次' }}</div>
          <div
            class="main-c"
            v-if="queryStatus == 'launch' && status == 'launch'"
          >
            <span
              @click="changeStatus('add')"
              v-if="IS_HANDLE('LARVA_BATCH_ADD')"
              class="mr-4"
              >编辑安排</span
            >
            <span @click="del" v-if="IS_HANDLE('LARVA_BATCH_DEL')">删除</span>
          </div>
          <div
            class="main-c"
            v-if="queryStatus == 'launch' && status == 'add'"
            @click="changeStatus('launch')"
          >
            取消编辑
          </div>
        </div>
        <data-field
          :data-obj="getDataObj('launch', form)"
          type="row"
          v-if="status == 'launch'"
        ></data-field>
      </section>
      <yiyu-form
        :_this="thisAlias"
        :form="form"
        :formOpts="formOpts"
        @submit="onSubmit"
        ref="form"
      >
        <template #priceDetail>
          <div class="main-c" @click="editData('transport')">+运输详细</div>
        </template>
        <template #editMore>
          <span
            class="main-c"
            @click="del"
            v-if="status == 'launch' && IS_HANDLE('LARVA_BATCH_DEL')"
            >删除</span
          >
          <span
            class="main-c"
            v-if="status == 'launch'"
            @click="editMore = !editMore"
          >
            {{ editMore ? '收起' : '编辑更多' }}
          </span>
        </template>
        <template #defalutData v-if="editMore">
          <div class="row">
            <div class="title">默认数据</div>
            <div class="main-c" @click="editData('defaultData')">编辑</div>
          </div>
          <data-field :dataObj="getDataObj('default', form)"></data-field>
        </template>
        <template v-for="(item, index) in farm">
          <template :slot="'isPool' + index">
            <van-checkbox
              v-model="farm[index]['isPool']"
              shape="square"
              class="pool-handle"
              :key="index + 'ispool'"
              >精准到池</van-checkbox
            >
          </template>
          <template :slot="'delFarm' + index">
            <pool-choose
              v-if="farm[index]['isPool']"
              :post-data.sync="farm[index]['postData']"
              type="farm"
              :date="form.launch_date"
              :key="index + 'pool'"
              :mc-key="farm[index]['mc_key']"
              :curData="farm[index]['p_list']"
              @change="farmChange(index)"
            ></pool-choose>
          </template>
        </template>
      </yiyu-form>
      <fixed-btn
        btnTxt="确认"
        @click="toSubmit"
        :loading="btnLoading"
        :disabled="btnLoading"
      >
      </fixed-btn>
    </div>
    <!-- 精确到时即可 -->
    <datetime-popup
      :date.sync="form.launch_date"
      :show.sync="showLaunchDatePicker"
      title="选择投放时间"
      @confirm="showLaunchDatePicker = false"
      @cancel="showLaunchDatePicker = false"
      :minDate="minDate"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="form['harvest_date']"
      :show.sync="showHarvestDatePicker"
      title="选择预估出苗"
      @confirm="showHarvestDatePicker = false"
      @cancel="showHarvestDatePicker = false"
    >
    </datetime-popup>
    <!-- 精确到分 -->
    <datetime-popup
      :date.sync="form['s_trip_start']"
      :show.sync="showSTripTimePicker"
      type="datetime"
      title="选择发车时间"
      @confirm="showSTripTimePicker = false"
      @cancel="showSTripTimePicker = false"
    >
    </datetime-popup>
    <yiyu-picker
      v-if="showTripPicker"
      :curKey="form.s_trip_key"
      :show.sync="showTripPicker"
      :columns="tripFilterColumns"
      value-key="title"
      keyName="t_key"
      @confirm="confirmPicker($event, 'trip')"
      @cancel="cancelPicker('trip')"
      :loading="tripLoading"
    >
      <template #empty>
        <div class="bag-empty">
          <span class="btn" @click="toPage('trip')">去添加运输行程</span>
        </div>
      </template>
    </yiyu-picker>
    <yiyu-picker
      v-if="showContactPicker"
      :curKey="form.contacts"
      :show.sync="showContactPicker"
      :columns="contactColumns"
      value-key="name"
      keyName="c_key"
      @confirm="confirmPicker($event, 'contact')"
      @cancel="cancelPicker('contact')"
      :loading="contactLoading"
    >
      <template #empty>
        <div class="contact-empty">
          <span class="btn" @click="toPage('contact')">去添加联系人</span>
        </div>
      </template>
    </yiyu-picker>

    <form-popup
      :show.sync="formPopupShow"
      v-if="formPopupShow"
      :_this="thisAlias"
      :form-opts="formPopupOpts"
      @cancel="cancelPicker('formPopup')"
      @confirm="confirmPicker($event, 'formPopup')"
      right-txt="保存"
      :title="formPopupTitle"
      formName="editForm"
    ></form-popup>

    <van-dialog
      class="confirm-dialog"
      v-model="confirmDialogShow"
      title="育苗投放量不一致提醒"
      show-cancel-button
      confirm-button-text="确定"
      cancel-button-text="返回修改"
      @confirm="launchSubmit(false)"
      @cancel="confirmDialogShow = false"
    >
      <div class="confirm-dialog-message">
        当前育苗总投放量与育苗安排量不一致，育苗投放量将覆盖育苗计划量
      </div>
      <div class="confirm-dialog-data-item">
        育苗计划量： {{ form.es_l_count }}
      </div>
      <div class="confirm-dialog-data-item">育苗投放量： {{ sumLaunch }}</div>
      <div
        class="confirm-dialog-data-item"
        v-for="(item, index) in farm"
        :key="index"
      >
        {{ item.mc_name }} ：{{ item.launch }}
      </div>
    </van-dialog>

    <tag-popup
      class="batch-chose-popup"
      :curKeys="[batchOrigin.curSsKey]"
      :show.sync="showOptPicker"
      :opts="batchOrigin.ssKeyOpts"
      @confirmObj="confirmPicker($event, 'ss_key')"
      @cancel="showOptPicker = false"
      @change="ssKeyChange"
      :loading="optLoading"
      :single="true"
      title="请选择育苗来源"
      value-key="ss_key_value"
      keyName="ss_key"
    >
      <template #header>
        <div class="row row-align-center">
          <van-search
            v-model="batchOrigin.ssKeyWord"
            placeholder="可搜索种虾来源"
          ></van-search>
          <span class="main-c" @click="toPage('addBatch')">+种虾</span>
        </div>
      </template>
      <template #bottom>
        <section
          class="batch-origin-bottom relative"
          v-myLoad="batchOrigin.recordLoading"
        >
          <van-radio-group
            v-model="batchOrigin.curSrKey"
            ref="checkboxGroup"
            v-if="batchOrigin.recordOpts.length"
          >
            <van-radio
              v-for="(item, index) in batchOrigin.recordOpts"
              :key="index"
              :name="item.sr_key"
            >
              <div class="row">
                <div class="bold">
                  {{ $dayjs(item['record_date']).format('MM-DD') }}
                </div>
                <data-field :data-obj="getDataObj('record', item)"></data-field>
              </div>
            </van-radio>
          </van-radio-group>
          <van-divider v-else-if="batchOrigin.curSsKey"
            >暂无孵化信息</van-divider
          >
        </section>
      </template>
    </tag-popup>

    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.mc_keys"
        :curName.sync="form.mc_keys_value"
        :single="false"
        title="请选择基地"
        type="1"
      ></farm-picker>
    </keep-alive>

    <keep-alive>
      <b-person-picker
        ref="personPicker"
        :cur-key.sync="form.l_manager"
        :cur-name.sync="form.l_manager_name"
        single
        :role="['5']"
        keyName="user_key"
      />
    </keep-alive>
  </div>
</template>

<script>
import farmPicker from '@/bComponents/farmPicker.vue';
import poolChoose from '@/bComponents/poolChoose.vue';
import formPopup from '@/components/popup/formPopup';
import { getKeyObj } from '@/utils/utils';
import datetimePopup from '@/components/popup/datetime.vue';
import { getKeyValue, numToStr } from '@/utils/utils';
import { formatTime, getFuture, getDiffDay } from '@/utils/date';
import yiyuForm from '@/components/form/yiyuForm.vue';
import yiyuPicker from '@/components/popup/picker';
import yiyuNavbar from '@/components/navbar';
import dataField from '@/components/layout/dataField';
import fixedBtn from '@/components/button/fixedBtn';
import tagPopup from '@/components/popup/tag.vue';
import { Dialog } from 'vant';
import { YDialog } from 'yiyu-vant-ui/lib/dialog';
import mcMixin from '@/mixin/mcMixin';
import bPersonPicker from '@/bComponents/bPersonPicker.vue';

export default {
  name: 'larvaLaunch',
  mixins: [mcMixin],
  components: {
    yiyuForm,
    yiyuPicker,
    datetimePopup,
    yiyuNavbar,
    dataField,
    tagPopup,
    fixedBtn,
    formPopup,
    poolChoose,
    farmPicker,
    bPersonPicker,
  },
  data() {
    return {
      farmKey: undefined, // 指定基地时，默认选中基地
      postLoading: false,
      isLoading: false,
      optLoading: false,
      activated: false, // mounted 和 activated 只须执行一个
      btnLoading: false,
      status: 'launch',
      thisAlias: this,
      minDate: new Date(2020, 0, 1),
      form: {
        alias: undefined, // 品系
        launch_date: undefined, // 投放时间",
        harvest_date: undefined, // 预计出苗时间",
        batch: undefined, // 批次",
        predict: undefined, // 预估出苗量",
        status: undefined, // 1", // 1 新幼苗，2, 已预订 3 已关联， 4 养殖中， 5 出苗
        survival_rate: undefined, // 成活率",
        breeding: undefined, // '育苗天数
        sum_launch: undefined, // 总育苗投放量
        type: undefined, // "育苗类型", 1 高亢、2 快大、3 其他
        remark: undefined,
        s_trip_key: undefined, // "短途行程key",
        s_trip_cost: undefined, // "短途费用",
        s_trip_name: undefined,
        contacts: undefined, // "联系人key",
        contactsName: undefined,
        s_trip_start: undefined, // 短途出发时间
        l_key: '',
        es_l_count: undefined, // '育苗计划量',
        es_count: undefined, // '估苗量',
        ss_key: undefined, // '种虾key',
        sr_key: undefined,
        mc_keys: [],
        mc_keys_value: undefined,
        l_manager: '',
        l_manager_name: '',
        ss_name: ''
      },
      launch_list: [], // 投放列表
      showLaunchDatePicker: false,
      showHarvestDatePicker: false,
      showTypePicker: false,
      showOptPicker: false,
      showSTripTimePicker: false,
      showTripPicker: false,
      showContactPicker: false,
      farm: [],
      farmKeys: [], // 已经选择的基地key，禁止再选
      tmpFarmKeys: [], // 已经选择的基地key，禁止再选,除去当前正在选择的基地key
      tripColumns: [],
      farmIndex: undefined, // 当前操作基地的下标
      curFarmKey: undefined, // 用来判断有没有切换基地
      poolIndex: undefined, // 当前操作池的下标
      tripLoading: true,
      contactLoading: false,
      formPopupShow: false,
      curHandle: undefined, // 用来标识当前的操作 transport defaultData
      editForm: {
        ss_key: undefined,
        ss_key_value: undefined,
        launch_date: undefined, // 投放时间",
        harvest_date: undefined, // 预计出苗时间",
        batch: undefined, // 批次",
        predict: undefined, // 预估出苗量",
        status: undefined, // 1", // 1 新幼苗，2, 已预订 3 已关联， 4 养殖中， 5 出苗
        survival_rate: undefined, // 成活率",
        breeding: undefined, // '育苗天数
        sum_launch: undefined, // 总育苗投放量
        type: undefined, // "育苗类型", 1 高亢、2 快大、3 其他
        remark: undefined,
        s_trip_key: undefined, // "短途行程key",
        s_trip_cost: undefined, // "短途费用",
        s_trip_name: undefined,
        contacts: undefined, // "联系人key",
        contactsName: undefined,
        s_trip_start: undefined, // 短途出发时间
        l_key: '',
        es_l_count: undefined, // '育苗计划量',
        es_count: undefined, // '估苗量'
      }, // 编辑表单，取消的时候不保存
      editMore: true,
      confirmDialogShow: false,
      changeStatusShow: false,
      sumLaunch: 0, // 总投放育苗量
      cur_lp_id: undefined, // 当前操作的lp_id
      cur_pItem: undefined, // 可以改变值
      cur_p_key: undefined,

      // 获取幼体来源相关数据
      batchOrigin: {
        ssKeyOpts: undefined,
        ssKeyWord: undefined,
        curSsKey: undefined,
        curSrKey: undefined,
        recordLoading: false,
        recordOpts: [],
      },
      isSSName: false
    };
  },
  computed: {
    // 来源 种虾别名+品系
    sOrigin() {
      if (this.isSSName) {
        return this.form.ss_name
      } else {
        if (this.form.strain) {
          return `${this.form.alias}(${this.form.strain})`;
        } else {
          return this.form.alias;
        }
      }
    },
    queryStatus() {
      if (this.form.l_key) {
        return 'launch';
      } else {
        return 'add';
      }
    },
    contactColumns() {
      return this.$store.state.setting.contacts;
    },
    tabTitle() {
      switch (this.status) {
        case 'launch':
          return '育苗投放';
        case 'add':
        default:
          return '育苗安排';
      }
    },
    formOpts() {
      let _this = this;
      let base = [
        {
          title: '',
          show: this.status == 'add',
          slot: 'defalutData',
          child: [
            {
              colon: true,
              formType: 'input-select',
              type: 'text', // 默认，可以不写
              value: this.sOrigin,
              name: 'ss_name',
              label: '来源',
              placeholder: '请选择来源',
              click: () => {
                this.batchOrigin.curSsKey = this.form.ss_key;
                this.batchOrigin.curSrKey = this.form.sr_key;
                if (this.batchOrigin.curSsKey) {
                  this.getSRArrange();
                }
                this.showOptPicker = true;
              },
              change: (e) => {
                this.form.ss_name = e
                this.form.ss_key = undefined;
                this.form.alias = undefined;
                this.form.strain = undefined;
                this.form.sr_key = undefined;
                this.isSSName = true
              },
              show: this.editMore,
            },
            {
              required: true,
              colon: true,
              formType: Number(_this.form.c_status) > 2 ? 'input' : 'select',
              readonly: Number(_this.form.c_status) > 2 ? true : false,
              type: 'text', // 默认，可以不写
              value: this.form.launch_date,
              name: 'launch_date',
              label: '投放时间',
              placeholder: '请选择投放时间',
              click() {
                if (Number(_this.form.c_status) > 2) {
                  _this.$toast.fail('估苗后不可编辑哦');
                  return;
                }
                _this.showLaunchDatePicker = true;
              },
              rules: [{ required: true, message: '请选择投放时间' }],
            },
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.es_l_count,
              name: 'es_l_count',
              label: '育苗计划量(万)',
              type: 'number',
              placeholder: '请输入育苗计划量',
              rules: [{ required: true, message: '请输入育苗计划量' }],
            },
            // {
            //   required: false,
            //   colon: true,
            //   value: this.form.strain,
            //   name: 'strain',
            //   label: '品系',
            //   readonly: true,
            //   show: this.editMore,
            // },
            {
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.l_manager_name,
              name: 'l_manager_name',
              label: '批次负责人',
              placeholder: '请选择成员',
              click() {
                _this.$refs.personPicker.show = true;
              },
              // rules: [{ required: true, message: '请选择成员' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.s_trip_cost,
              name: 's_trip_cost',
              label: '运费(元)',
              slot: 'priceDetail',
              placeholder: '请输入运费(元)',
              rules: [{ required: false, message: '请输入运费' }],
              show: this.editMore,
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.mc_keys_value,
              name: 'mc_keys_value',
              label: '养殖地点',
              placeholder: '请选择育苗投放地点，可多选',
              click() {
                _this.$refs['farmPicker'].show = true;
              },
              show: this.status == 'add',
            },
          ],
        },
        {
          title: '',
          show: this.status == 'launch',
          child: [
            {
              required: true,
              colon: true,
              formType: Number(_this.form.c_status) > 2 ? 'input' : 'select',
              readonly: Number(_this.form.c_status) > 2 ? true : false,
              type: 'text', // 默认，可以不写
              value: this.$dayjs(this.form.launch_date).format(
                'YY/MM/DD HH:mm'
              ),
              name: 'launch_date',
              label: '实际投放',
              click() {
                if (Number(_this.form.c_status) > 2) {
                  _this.$toast.fail('估苗后不可编辑哦');
                  return;
                }
                _this.showLaunchDatePicker = true;
              },
              rules: [{ required: true, message: '请选择投放时间' }],
            },
          ],
        },
      ];

      if (this.status == 'launch') {
        base.push(...this.farmOpts);
      }

      return base;
    },
    formPopupOpts() {
      switch (this.curHandle) {
        case 'transport':
          return this.transportOpts;
        case 'defaultData':
        default:
          return this.defaultFromOpts;
      }
    },
    farmOpts() {
      let tmp = [];
      this.farm.forEach((item, index) => {
        tmp.push({
          title: this.farm[index]['mc_name'],
          topSlot: 'isPool' + index,
          slot: 'delFarm' + index,
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              type: 'number',
              value: this.farm[index]['launch'],
              valueKey: ['farm', index, 'launch'],
              name: 'launch',
              label: '投放育苗量(万)',
              placeholder: '请输入投放育苗量',
              show: item.dataShow,
              readonly: this.farm[index]['isPool'],
            },
            {
              required: false,
              colon: true,
              formType: 'input', // 默认
              type: 'number',
              value: this.farm[index]['lp_count'] || undefined,
              valueKey: ['farm', index, 'lp_count'],
              name: 'lp_count',
              label: '投放池数',
              placeholder: this.farm[index]['isPool']
                ? '自动输入'
                : '请输入投放池数',
              readonly: this.farm[index]['isPool'],
              show: item.dataShow,
            },
          ],
          show: this.status == 'launch',
        });
      });

      return tmp;
    },
    defaultFromOpts() {
      let _this = this;
      return [
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.survival_rate,
          name: 'survival_rate',
          label: '成活率',
          extra: '%',
          placeholder: '请选择成活率',
          rules: [{ required: false, message: '请选择成活率' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input', // 默认
          value: this.editForm.es_count,
          name: 'es_count',
          label: '估苗量',
          extra: '万',
          type: 'number',
          placeholder: '请输入估苗量',
          rules: [{ required: false, message: '请输入估苗量' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input', // 默认
          value: this.editForm.breeding,
          name: 'breeding',
          label: '育苗天数',
          extra: '天',
          type: 'number',
          placeholder: '请输入育苗天数',
          rules: [{ required: false, message: '请输入育苗天数' }],
        },
        {
          required: false,
          colon: true,
          value: this.editForm.harvest_date,
          formType: 'select',
          name: 'harvest_date',
          label: '预估出苗',
          placeholder: '预估出苗',
          click() {
            _this.showHarvestDatePicker = true;
          },
          rules: [{ required: false, message: '请选择预估出苗' }],
        },
      ];
    },
    transportOpts() {
      let _this = this;
      return [
        {
          topSlot: 'short',
          required: false,
          colon: true,
          formType: 'select',
          type: 'text', // 默认，可以不写
          value:
            this.editForm.s_trip_start &&
            this.editForm.s_trip_start.slice(0, 16),
          name: 's_trip_start',
          label: '发车时间',
          placeholder: '请选择发车时间',
          click() {
            _this.showSTripTimePicker = true;
          },
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          value: this.editForm.s_trip_name,
          name: 's_trip_name',
          label: '运输行程',
          placeholder: '请选择运输行程',
          click() {
            _this.showTripPicker = true;
          },
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          type: 'number',
          value: this.editForm.s_trip_cost,
          name: 's_trip_cost',
          label: '运费',
          placeholder: '请输入运费',
          rules: [{ required: false, message: '请输入运费' }],
          extra: '元',
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          value: this.editForm.contactsName,
          name: 'contactsName',
          label: '联系人',
          placeholder: '请选择联系人',
          click() {
            _this.showContactPicker = true;
          },
        },
      ];
    },
    tripFilterColumns() {
      // 只有短途
      return this.tripColumns.filter((item) => item.type == 1);
    },
    formPopupTitle() {
      switch (this.curHandle) {
        case 'transport':
          return '运输信息';
        case 'defaultData':
        default:
          return '编辑默认数据';
      }
    },
  },
  watch: {
    contactColumns: {
      deep: true,
      handler(val) {
        this.form.contactsName = getKeyValue(
          this.form.contacts,
          val,
          'name',
          'c_key'
        );
      },
    },
    'form.es_l_count'(val) {
      if (val && this.form.survival_rate) {
        this.form.es_count = (this.form.survival_rate * val) / 100;
      }
    },
    'form.launch_date'() {
      this.getHDate();
    },
    'form.breeding'() {
      this.getHDate();
    },
    // 修改育苗天数后，预估出苗时间随之修改
    'editForm.breeding'() {
      if (this.form.launch_date && this.editForm.breeding) {
        this.editForm.harvest_date = formatTime(
          new Date(getFuture(this.form.launch_date, this.editForm.breeding))
        ).slice(0, 16);
      }
    },
    'form.launch'(val) {
      if (
        this.form.survival_rate != undefined &&
        this.form.launch != undefined
      ) {
        this.form.predict = Math.floor(
          (this.form.launch * this.form.survival_rate) / 100
        );
      }

      if (this.farm[0] && this.farm[0]['sum_launch'] == undefined) {
        this.farm[0]['sum_launch'] = val;
      }
    },
    // 修改默认成活率后，估苗量随之变化
    'form.survival_rate'() {
      if (
        this.form.survival_rate != undefined &&
        this.form.launch != undefined
      ) {
        this.form.predict = Math.floor(
          (this.form.launch * this.form.survival_rate) / 100
        );
      }
    },
  },
  activated() {
    if (!this.activated) {
      this.activated = true;
      return;
    }
    this.activatedFn();
    if (sessionStorage['yy_back_ss_key']) {
      let ss_key = [sessionStorage['yy_back_ss_key']];
      this.ssKeyChange(ss_key);
      sessionStorage.removeItem('yy_back_ss_key');
    }
  },
  mounted() {
    this.activated = false;
    this.activatedFn();
  },
  methods: {
    activatedFn() {
      if (this.$route.query) {
        let query = this.$route.query;

        this.form.l_key = query.larvaKey;
        // this.form.alias = query.alias
        this.form.ss_key = query.ss_key;
        this.form.sr_key = query.sr_key;
        if (this.form.ss_key) {
          this.$get('/settle/getShrimpOne', {
            ss_key: this.form.ss_key,
          }).then((res) => {
            if (res.code == 10000) {
              let data = res.data;
              this.form.alias = data.alias;
              this.form.strain = data.strain;
            }
          });
        }
        if (this.form.sr_key) {
          this.$get('/settle/getSRArrange', {
            sr_key: this.form.sr_key,
          }).then((res) => {
            if (res.code == 10000) {
              let data = res.data.list[0] || {};
              this.form.launch_date =
                this.$dayjs(data.record_date || new Date()).format(
                  'YYYY/MM/DD'
                ) + ' 11:00';
            }
          });
        }

        if (this.form.l_key) {
          this.status = 'launch';
        } else {
          this.status = 'add';
        }

        this.farmKey = query.farmKey;
      }
      if (this.status == 'launch') {
        this.getData();
      } else {
        this.getAddData();
      }
    },
    getAddData() {
      this.getTripData();
      this.getSsKeyOpts();
      this.getSetting();
      this.getContactData();
    },
    getData() {
      this.isLoading = true;
      this.$get('/larva/getLarvaLaunch', {
        l_key: this.form.l_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          let data = res.data['larva_info'];

          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
          }

          data.es_launch_date = data.launch_date;

          if (data.launch_date && data.harvest_date) {
            data.breeding = getDiffDay(data.harvest_date, data.launch_date);
          }

          data.mc_keys = this.getMcKeys(data.mc);
          data.mc_keys_value = this.getMcNames(data.mc);

          this.form = data;
          this.isSSName = !this.form.ss_key
          
          if (this.form.s_trip_key && this.tripColumns.length) {
            let s = getKeyObj(this.form.s_trip_key, this.tripColumns, 't_key');
            this.form.s_trip_name = s['title'];
          }

          if (this.form.contacts && this.contactColumns.length) {
            this.form.contactsName = getKeyValue(
              this.form.contacts,
              this.contactColumns,
              'name',
              'c_key'
            );
          }

          let mc = [];
          if (Array.isArray(this.form.mc)) {
            this.form.mc.forEach((item) => {
              let tmp = getKeyObj(item.mc_key, this.farm, 'mc_key');
              if (JSON.stringify(tmp) == '{}') {
                tmp = getKeyObj(item.mc_key, res.data.lmc_list, 'mc_key');
              }
              tmp.mc_key = item.mc_key;
              tmp.mc_name = item.mc_name;
              tmp.launch = tmp.launch || undefined;
              tmp.lp_count = tmp.lp_count || undefined;

              if (tmp.p_list && tmp.p_list.length) {
                tmp['isPool'] = true;
              } else {
                tmp['isPool'] = false;
              }

              mc.push(tmp);
            });
          }

          this.farm = mc;
        }
      });
    },
    getSetting() {
      this.isLoading = true;
      this.$get('/setting/getSetting', {}).then((res) => {
        this.form.survival_rate = res.data.survival_rate;
        this.form.breeding = res.data.breeding;
        this.isLoading = false;
      });
    },
    onSubmit() {
      if (this.status == 'add') {
        this.btnLoading = true;
        let form = JSON.parse(JSON.stringify(this.form));
        this.$post('/larva/addLarva', numToStr(form)).then((res) => {
          this.btnLoading = false;
          if (res.code === 10000) {
            this.addLarvaSuccess();
            let data = res.data;
            // 数据响应
            for (let key in this.form) {
              data[key] = data[key] || this.form[key];
            }

            this.form.l_key = data.l_key;
            this.form.batch = data.batch;
          }
        });
      } else {
        if (this.status == 'launch') {
          this.launchSubmit(true);
        }
      }
    },
    addLarvaSuccess() {
      if (this.queryStatus == 'launch') {
        this.status = 'launch';
        this.getData();
        return;
      }
      YDialog({
        type: 'list',
        title: '育苗安排成功',
        subtitle: '请选择下一步',
        data: this.FILTER_HANDLE([
          {
            name: '继续育苗安排',
            key: 'continueAdd',
            onConfirm: () => {
              this.toPage('addLarva');
            },
            handle: 'LARVA_BATCH_ADD',
          },
          {
            name: '育苗投放填写',
            key: 'launch',
            onConfirm: () => {
              this.changeStatus('launch');
            },
            handle: 'LARVA_BATCH_LAUNCH_ADD',
          },
          {
            name: '发送通知',
            key: 'notice',
            onConfirm: () => {
              this.toPage('notice');
            },
          },
          {
            name: '返回入口',
            key: 'back',
            onConfirm: () => {
              this.$go(-1, this);
            },
          },
        ]),
      });
    },
    farmChange(index) {
      if (this.farm[index].isPool) {
        let launch = 0;
        this.farm[index].postData.forEach((item) => {
          launch += Number(item.launch || 0);
        });
        this.farm[index]['launch'] = launch;
        this.farm[index]['lp_count'] = this.farm[index]['postData'].length;
      }
    },
    launchSubmit(check = true) {
      let form = JSON.parse(JSON.stringify(this.form));
      form.launch_list = [];
      this.sumLaunch = 0;
      this.farm.forEach((item) => {
        let tmp = {
          mc_key: item.mc_key,
          launch: item.launch,
          lp_count: item.lp_count,
          p_launch: [],
        };

        this.sumLaunch += Number(item.launch);

        if (item['isPool']) {
          // 如果有关联到池才需要显示
          // let pool_launch = item.launch / item.lp_count
          item.postData &&
            item.postData.forEach((item1) => {
              tmp['p_launch'].push({
                p_key: item1.p_key,
                launch: item1.launch,
              });
            });
        }
        form.launch_list.push(tmp);
      });

      // 检查育苗计划量与总投放量是否一致
      if (check && this.sumLaunch != this.form.es_l_count) {
        this.confirmDialogShow = true;
        return;
      }

      this.btnLoading = true;

      this.$post('/larva/launch', numToStr(form)).then((res) => {
        this.btnLoading = false;
        if (res.code === 10000) {
          YDialog({
            type: 'list',
            title: '幼体投放成功',
            subtitle: '请选择下一步',
            data: this.FILTER_HANDLE([
              {
                name: '发送通知',
                key: 'notice',
                onConfirm: () => {
                  this.toPage('notice');
                },
              },
              {
                name: '安排幼体',
                key: 'continueAdd',
                onConfirm: () => {
                  this.toPage('addLarva');
                },
                handle: 'LARVA_BATCH_ADD',
              },
              {
                name: '查看批次详情',
                key: 'batch',
                onConfirm: () => {
                  this.$toPage('larvaProduct', {
                    l_key: this.form.l_key,
                  });
                },
                handle: 'LARVA_BATCH_SEE',
              },
              {
                name: '返回入口',
                key: 'back',
                onConfirm: () => {
                  this.$go(-1, this);
                },
              },
            ]),
          });
        }
      });
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    confirmPicker(e, type) {
      let val = e[0];
      switch (type) {
        case 'ss_key':
          {
            let obj = e[0] || {};
            this.form.ss_key = obj.ss_key;
            this.form.alias = obj.alias;
            this.form.strain = obj.strain;
            this.form.sr_key = this.batchOrigin.curSrKey;
            this.form.launch_date = getKeyObj(
              this.form.sr_key,
              this.batchOrigin.recordOpts,
              'sr_key'
            )['record_date'];

            this.showOptPicker = false;
            this.form.ss_name = undefined
            this.isSSName = false
          }
          break;
        case 's_trip_start':
          this.showSTripTimePicker = false;
          break;
        case 'trip':
          this.editForm.s_trip_key = val['t_key'];
          this.editForm.s_trip_name = val['title'];
          if (!this.form.s_trip_cost) {
            this.editForm.s_trip_cost = val['freight'];
          }
          this.editForm.contacts = val['contacts'];
          this.editForm.contactsName = getKeyValue(
            val['contacts'],
            this.contactColumns,
            'name',
            'c_key'
          );
          this.editForm.s_start = val['starting_point'];
          this.editForm.s_end = val['ending_point'];
          this.editForm.s_duration = val['duration'];

          this.showTripPicker = false;
          break;
        case 'contact':
          this.editForm.contacts = e[0].c_key;
          this.editForm.contactsName = e[0].name;
          this.showContactPicker = false;
          break;
        case 'formPopup':
          val = e;
          if (this.curHandle == 'transport') {
            this.form.s_trip_start = val['s_trip_start'];
            this.form.s_trip_name = val['s_trip_name'];
            this.form.s_trip_cost = val['s_trip_cost'];
            this.form.contactsName = val['contactsName'];
          } else if (this.curHandle == 'defaultData') {
            this.form.survival_rate = val['survival_rate'];
            this.form.es_count = val['es_count'];
            this.form.breeding = val['breeding'];
            this.form.harvest_date = val['harvest_date'];
          }
          this.formPopupShow = false;
          break;
      }
      // this.editForm = JSON.parse(JSON.stringify(this.editForm))
    },
    cancelPicker(type) {
      switch (type) {
        case 'launchDate':
          this.showLaunchDatePicker = false;
          break;
        case 's_trip_start':
          this.showSTripTimePicker = false;
          break;
        case 'trip':
          this.showTripPicker = false;
          break;
        case 'contact':
          this.showContactPicker = false;
          break;
        case 'formPopup':
          this.formPopupShow = false;
          break;
      }
    },
    getHDate() {
      if (this.form.breeding && this.form.launch_date) {
        this.form.harvest_date = formatTime(
          new Date(getFuture(this.form.launch_date, this.form.breeding))
        ).slice(0, 16);
      }
    },
    // 删除基地
    delFarm(index) {
      let delFarmKey = this.farm[index]['mc_key'];

      if (delFarmKey) {
        this.farmKeys = this.farmKeys.filter((item) => item != delFarmKey);
      }

      this.farm.splice(index, 1);
    },
    del() {
      let msg =
        '删除批次后，批次的关联订单、出苗、估苗、质检、育苗、排苗、搬苗、处理数据都将会删除，是否确认删除？';
      Dialog.confirm({
        title: '删除提醒',
        message: msg,
      })
        .then(() => {
          this.$post('/larva/delLarva', {
            l_key: this.form.l_key,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('删除批次成功');
              this.$go(-1, this, {
                name: 'FF_produce',
                params: {
                  tab: 'larva',
                },
              });
            }
          });
        })
        .catch(() => {});
    },
    getDataObj(type, item) {
      switch (type) {
        case 'launch':
          return [
            [
              {
                data: this.$dayjs(item.es_launch_date).format('YY/MM/DD HH:mm'),
                descText: '投放时间',
              },
              {
                data: item.es_l_count,
                unitText: '万',
                descText: '计划投放',
              },
              {
                data: this.getMcNames(item.mc),
                descText: '养殖基地',
              },
            ],
          ];
        case 'default':
          return [
            [
              {
                data: item.survival_rate || '暂无',
                unitText: item.survival_rate ? '%' : '',
                descText: '默认成活率',
              },
              {
                data: item.es_count || '暂无',
                unitText: item.es_count ? '万' : '',
                descText: '估苗量',
              },
              {
                data:
                  (item.harvest_date && item.harvest_date.slice(2, 16)) ||
                  '暂无',
                unitText: '',
                descText: '预估出苗',
              },
              {
                data: item.breeding || '暂无',
                unitText: item.breeding ? '天' : '',
                descText: '育苗天数',
              },
            ],
          ];
        case 'record':
          return [
            [
              {
                data: item.real_yield,
                descText: '实际产量',
                unitText: '万',
              },
              {
                data: item.arrange,
                descText: '已安排量',
                unitText: '万',
              },
              {
                data: item.no_arrange,
                descText: '待安排量',
                unitText: '万',
              },
            ],
          ];
      }
    },
    // 新增池
    addPool(index) {
      this.farm[index]['pool_list'].push({
        name: '',
      });
      this.farm[index]['pool_num'] = Number(this.farm[index]['pool_num']) + 1;
    },
    // 删除池
    delPool(pIndex, index) {
      this.farm[pIndex]['pool_list'].splice(index, 1);
      this.farm[pIndex]['pool_num'] = Number(this.farm[pIndex]['pool_num']) - 1;
    },
    validator(val) {
      if (val['launch_info'].length == 0) {
        return true;
      }
      if (val['pool_num'] != val['launch_info'].length) {
        return false;
      }
    },
    toPage(type) {
      switch (type) {
        case 'trip':
          this.$push(this, { path: '/transportSet' });
          break;
        case 'contact':
          this.$toPage(this, 'contactSet');
          break;
        // 继续育苗安排
        case 'addLarva':
          this.editMore = true;
          this.status = 'add';
          this.form = this.$options.data.call(this).form;
          this.getAddData();
          break;
        // 批次详情
        case 'larvaProduct':
          this.$replace(this, {
            path: '/larvaProduct',
            query: { larvaKey: this.form.l_key },
          });
          break;
        case 'notice':
          {
            let p = '1';
            let title = this.tabTitle + '完成';

            let farm = [];

            let launch = this.form.es_l_count;

            if (this.status == 'launch') {
              launch = 0;
              this.farm.forEach((item) => {
                farm.push(item.mc_name);
                launch += Number(item.launch || 0);
              });
            }

            let remark = [
              {
                label: '批次',
                value: this.form.batch,
              },
              {
                label: '基地',
                value: farm.join('、'),
              },
              {
                label: '投放时间',
                value: this.form.launch_date,
              },
              {
                label: '投放量',
                value: launch,
                extra: '万',
              },
            ];

            let re = [
              {
                type: '1',
                re_info: {
                  l_key: this.form.l_key,
                },
              },
            ];
            this.toNotice({ p, title, remark, re });
          }
          break;
        case 'addBatch':
          this.$toPage('sBatchEdit', {
            cached: true,
            back: true,
          });
          break;
      }
    },
    getTripData() {
      this.tripLoading = true;
      this.$get('/transport/getTransportList', {}).then((res) => {
        if (res.code == 10000) {
          this.tripLoading = false;
          this.tripColumns = res.data.list;
          if (this.form.s_trip_key) {
            let s = getKeyObj(this.form.s_trip_key, this.tripColumns, 't_key');
            this.form.s_trip_name = s['title'];
          }
        }
      });
    },
    getContactData() {
      if (!this.$store.state.setting.getContacts) {
        this.contactLoading = true;
        this.$store.dispatch('getContacts', {}).then((res) => {
          if (res.code == 10000) {
            this.contactLoading = false;
          }
        });
      }
    },
    editData(type) {
      switch (type) {
        case 'transport':
          this.curHandle = type;
          this.editForm.s_trip_start = this.form.s_trip_start;
          this.editForm.s_trip_name = this.form.s_trip_name;
          this.editForm.s_trip_cost = this.form.s_trip_cost;
          this.editForm.contactsName = this.form.contactsName;
          this.formPopupShow = true;
          break;
        case 'defaultData':
          this.curHandle = type;
          this.editForm.survival_rate = this.form.survival_rate;
          this.editForm.es_count = this.form.es_count;
          this.editForm.breeding = this.form.breeding;
          this.editForm.harvest_date = this.form.harvest_date;
          this.formPopupShow = true;
          break;
      }
    },
    changeStatus(type) {
      switch (type) {
        case 'add':
          this.getAddData();
          this.status = 'add';
          this.getData();
          break;
        case 'launch':
          this.status = 'launch';
          this.getData();
          break;
        // 取消关联
        case 'cancel':
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/larva/delLaunch', {
            lp_id: [this.cur_lp_id],
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.cur_pItem['chosed'].push(this.cur_p_key);
              this.changeStatusShow = false;
            }
          });
          break;
        // 结束育苗
        case 'over':
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/larva/endLaunch', {
            lp_id: [this.cur_lp_id],
            end_date: this.overData.date,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.cur_pItem['chosed'].push(this.cur_p_key);
              this.changeStatusShow = false;
            }
          });
          break;
      }
    },
    // 获取种虾-来源用
    getSsKeyOpts() {
      this.optLoading = true;
      this.$get('/conf/getSelectConf', {
        select_items: ['Shrimp'],
        params: {
          keyword: this.ssKeyWord,
        },
      }).then((res) => {
        if (res.code == 10000) {
          this.optLoading = false;
          this.batchOrigin.ssKeyOpts = res.data['Shrimp']?.value || [];
          this.batchOrigin.ssKeyOpts.forEach((item) => {
            item.ss_key_value = `${item.batch}`;
          });
        }
      });
    },
    // 获取种虾每日产量安排情况
    getSRArrange() {
      this.recordLoading = true;
      this.$get('/settle/getSRArrange', {
        ss_key: this.batchOrigin.curSsKey,
        limit: 3,
      }).then((res) => {
        this.recordLoading = false;
        if (res.code == 10000) {
          this.batchOrigin.recordOpts = res.data?.list || [];
        }
      });
    },
    ssKeyChange(keys) {
      this.batchOrigin.curSsKey = keys[0];
      this.batchOrigin.curSrKey = undefined;
      if (this.batchOrigin.curSsKey) {
        this.getSRArrange();
      } else {
        this.batchOrigin.recordOpts = [];
      }
      return keys;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 0.5rem;
  background: #fff;

  .title {
    font-weight: 700;
    font-size: 1.2rem;
  }
}
.farm-launch {
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}
.row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  box-sizing: border-box;
}
.del-farm {
  .FlexCenter;
  background: rgba(@mainRgb, 0.1);
  height: 48px;
}

.add-farm {
  .FlexCenter;
  padding: 16px;
  box-sizing: border-box;

  &-btn {
    padding: 0rem 1rem;
    height: 3rem;
    background: rgba(@mainRgb, 0.08);
    border-radius: 8px;
    line-height: 3rem;
    color: @mainColor;
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
  }
}

/deep/.yiyu-box-section {
  padding: 0.5rem !important;
}

/deep/.yiyu-collapse-top {
  .left {
    padding-left: 1rem;
  }
}

/deep/.yiyu-collapse-content {
  .not-allow-p-list {
    padding: 0 1rem;
    border-bottom: 1px solid @lineColor;

    .row {
      display: flex;
      justify-content: space-between;
    }

    &-ws {
      font-size: 1rem;
      font-weight: bold;
      color: @titleColor;
    }

    .not-allow-pool-item {
      padding: 0 0.5rem;
      border-bottom: 1px solid @lineColor;

      &-name {
        color: @textColor;
      }
    }
  }
}

.classify-tag-popup-bottom {
  border-top: 1px solid @lineColor;
  padding: 0.5rem;
  text-align: center;
}

.batch-chose-popup {
  /deep/&.popup {
    height: 80vh;

    .content {
      padding-top: 0;
    }

    .batch-origin-bottom {
      padding: 1rem;

      .bold {
        font-weight: 700;
        white-space: nowrap;
      }
    }
  }
}
</style>
